//import { useEffect, useState } from 'react';
import {  useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import axios from 'axios';
import { ActiveOrder, Connections, Delivery, Shipments } from '../photoComponents/IsCompanyNodes';
import { useNavigate } from 'react-router-dom';

function IsCompanyNode({hub_id}) {
    const navigate = useNavigate();

    const { data: hubData, isLoading, error } = useQuery(['hubData'], () =>
    axios.get(`http://195.251.111.90/api/hub/${hub_id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        }
    }).then(res => res.data)
);

    if (isLoading) return <div className=' w-[70vh] m-auto' ><SpinnerComponent/> </div>;

    // You can also check if there was an error
    if (error) return 'An error has occurred: ' + error.message;

    return ( 
    <div className="justify-center flex items-center flex-col h-[60vh] ">
    <div className='border-2 border-[#0063BE] rounded-md w-3/6 m-1 '>
        <h1 className="text-2xl font-normal text-gray-800 p-2">
            Λεπτομέρειες για το κέντρο διανομών <span className='font-semibold'>{hubData[0].Hub_Name}</span>
        </h1>
    </div>

    <div className='flex justify-between items-center m-8 mx-[20%] '>
    <div className="w-1/2  bg-white rounded-xl shadow-lg p-4 py-10  border-2 border-green-800 text-center ">
        <h1 className="text-xl font-semibold text-gray-800 border rounded-3xl border-[#FC4C02] mx-[8%] py-[4%] shadow-lg">Κινητικότητα από και προς στο κέντρο διανομών</h1>

        <div className=' items-center flex justify-around bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
            hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
            onClick={() => navigate(`/graph/hub_details/${encodeURIComponent(JSON.stringify(hub_id))}/hub_connections`)}>
                <Connections />
            <h1 className='text-white flex-shrink mr-4 font-medium'>
            Διαθέσιμες συνδέσεις του κέντρου
            </h1>
            
        </div>

        <div className=' items-center flex-row flex bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
        hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
        onClick={() => navigate(`/graph/hub_details/${encodeURIComponent(JSON.stringify(hub_id))}/hub_shipments`)}>
            <Shipments/>
            <h1 className='text-white flex-shrink p-3  font-medium ml-4'>
            Τρέχουσες μετακινήσεις
            </h1>
            
        </div>
        </div>
        
        <div className="w-1/2  bg-white rounded-xl shadow-lg p-4 py-10  border-2 border-green-800 text-center mx-[3%]">
        <h1 className="text-xl font-semibold text-gray-800 border rounded-3xl border-[#FC4C02] mx-[8%] py-[4%] shadow-lg">Ανάλυση διαθέσιμων παραγγελιών</h1>

        <div className=' items-center flex bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
            hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
            onClick={() => navigate(`/graph/hub_details/${encodeURIComponent(JSON.stringify(hub_id))}/items_in_hub`)}>
                <ActiveOrder />
            <h1 className='text-white flex-shrink mr-5 font-medium'>
            Δέματα στο κέντρο διανομών
            </h1>
            
        </div>

        <div className=' items-center flex bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
        hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
        onClick={() => navigate(`/graph/hub_details/${encodeURIComponent(JSON.stringify(hub_id))}/request_last_mile`)}>
            <Delivery />
            <h1 className='text-white flex-shrink mr-5 font-medium'>
            Παραγγελίες προς παράδοση 
            </h1>
            
        </div>
        </div>
        </div>
        
        
    </div>
    );
}

export default IsCompanyNode;
