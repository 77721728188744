export  function Connections(){
    return <img
    src={require('../../assets/connections.png')}
    alt="fragile"
    className=" m-2 " // Adjust these values to resize the image
    />
}

export  function Shipments(){
    return <img
    src={require('../../assets/shipments.png')}
    alt="fragile"
    className=" ml-2 my-2 " // Adjust these values to resize the image
    />
}

export  function ActiveOrder(){
    return <img
    src={require('../../assets/active-order.png')}
    alt="fragile"
    className=" m-2 " // Adjust these values to resize the image
    />
}

export  function Delivery(){
    return <img
    src={require('../../assets/delivery.png')}
    alt="fragile"
    className=" m-2 " // Adjust these values to resize the image
    />
}

export  function Arrivals(){
    return <img
    src={require('../../assets/express-delivery.png')}
    alt="fragile"
    className=" m-2 " // Adjust these values to resize the image
    />
}

export  function Departures(){
    return <img
    src={require('../../assets/departure.png')}
    alt="fragile"
    className=" m-2 " // Adjust these values to resize the image
    />
}
export  function RequestReady(){
    return <img
    src={require('../../assets/request-ready.png')}
    alt="fragile"
    className=" ml-3 my-1 w-14 h-14 " // Adjust these values to resize the image
    />
}
export  function Parcel(){
    return <img
    src={require('../../assets/parcel.png')}
    alt="fragile"
    className=" ml-3 my-1 w-10 h-10 " // Adjust these values to resize the image
    />
}
export  function Pallete(){
    return <img
    src={require('../../assets/pallete.png')}
    alt="fragile"
    className=" ml-3 my-1 w-10 h-10 " // Adjust these values to resize the image
    />
}

