import { useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import { useState, useEffect } from 'react';
import IsNotCompanyNode from '../../components/graphPageComponents/isNotCompanyNode';
import IsCompanyNode from '../../components/graphPageComponents/isCompanyNode';

function GraphHub() {
    const location = useLocation();
    const { nodes } = useAuth()
    const entryParam = location.pathname.split('/').pop();
    const hub_id = JSON.parse(decodeURIComponent(entryParam));
    const [isCompanyNode, setIsCompanyNode] = useState(false);

    useEffect(() => {
        setIsCompanyNode(nodes.some(node => node.Hub_ID === hub_id));
    }, [nodes, hub_id]);
console.log(nodes)
    return (
      <div className="min-h-screen flex items-center justify-center">
        {isCompanyNode ?      
        <div className=" w-full  bg-white rounded-lg shadow-lg p-4 border-2 border-green-800  text-center my-[9%]">

        <IsCompanyNode hub_id={hub_id}/>
        
      </div>
        : 
        <div className=" min-h-screen  bg-white rounded-lg shadow-lg p-4 my-[9%] border-2 border-green-800  text-center">
          <IsNotCompanyNode hub_id={hub_id} />
          
        </div>
        } 
      </div>
    );
}
  
export default GraphHub;
