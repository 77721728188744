import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import HubName from './../HubNameComponent';

function ShipmentData() {
  const fetchRequests = async () => {
    const response = await axios.get('http://195.251.111.90/api/shipment', 
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
    });
    return response.data;
  };




  const { isLoading, error, data } = useQuery(['requests'], fetchRequests);

 //console.log(data[0])
  if (error) return 'An error has occurred: ' + error.message;
  return (
    <div className=" flex flex-col items-center justify-center border-2 border-[#0063BE] rounded-lg bg-white min-w-full min-h-full   ">
        <div className=' p-5'>
        <h1 className=' text-2xl font-semibold text-slate-800'> Μεταφορές</h1>
        </div>
     {isLoading ? <SpinnerComponent/> :
       <>
       <div className=' overflow-auto h-[100vh]   '>
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 ">ID Μεταφοράς</th>
                    <th className="p-3 ">ID Οδηγού</th>
                    <th className="p-3 ">Τοποθεσία αναχώρησης</th>
                    <th className="p-3 ">Τοποθεσία άφιξης</th>
                    <th className="p-3 ">Ποσότητα</th>
                    <th className="p-3 ">Κόστος</th>
                    <th className="p-3 ">Συνολικό ογκομ. βάρος</th>
                    
                    <th className="p-3 ">Ημερομηνία έκδοσης</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                   
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Shipment_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.User_ID}</td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Location_Start_ID}/></td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Location_End_ID}/></td>
                    <td className="border border-[#0063BE] p-1">{entry.Quantity}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Cost}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Total_weight}</td>
                    
                    <td className="border border-[#0063BE] p-1">{entry.Date_Made}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            
        </>
    }
    </div>
  );
}

export default ShipmentData;
