import React, { useState } from 'react';
import { GoogleMap, LoadScriptNext, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const containerStyle = {
    width: "700px",
    height: "700px",
    borderRadius:'15px',
    position:'relative'
};

const defaultCenter = {
    lat: 38.6389,
    lng: 22.4181,
};

const fetchNode = async (hub_id) => {
    const response = await axios.get(`http://195.251.111.90/api/hub/${hub_id}`, {
    headers: {
        "Content-Type": "application/json",
        Authorization: "velos123",
    },
    });
    return response.data;
};

const ConnectionMap = ({ start_id, end_id }) => {
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(defaultCenter);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const { data: startData, isLoading: startLoading, error: startError } = useQuery(['startNode', start_id], () => fetchNode(start_id));
    const { data: endData, isLoading: endLoading, error: endError } = useQuery(['endNode', end_id], () => fetchNode(end_id));

    const handleDrag = () => {
        if (map) {
            setSelectedMarker(startData[0])
            setCenter({
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng(),
            });
        }
    };

    if (startLoading || endLoading) return 'Loading...';
    if (startError || endError) return 'An error has occurred.';

    const start = startData && startData[0] ? {
        lat: startData[0].Latitude,
        lng: startData[0].Longitude
    } : null;

    const end = endData && endData[0] ? {
        lat: endData[0].Latitude,
        lng: endData[0].Longitude
    } : null;

    return (
        <LoadScriptNext
            googleMapsApiKey="AIzaSyCuUvJVCyGmUQXLGrksPRqdRVVF8-aQW7E"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={7}
                onLoad={(map) => setMap(map)}
                onUnmount={() => setMap(null)}
                onDragEnd={handleDrag}
            >
                <Marker
                    key={start_id}
                    position={start}
                    onClick={() => setSelectedMarker(startData && startData[0] ? startData[0] : null)}
                    icon={{
                        url: require('../../assets/location-green.png'),
                        scaledSize: window.google && window.google.maps ? new window.google.maps.Size(32, 32) : undefined,
                    }}
                />
                <Marker
                    key={end_id}
                    position={end}
                    onClick={() => setSelectedMarker(endData && endData[0] ? endData[0] : null)}
                    icon={{
                        url: require('../../assets/location-red.png'),
                        scaledSize: window.google && window.google.maps ? new window.google.maps.Size(32, 32) : undefined,
                    }}
                />
                {selectedMarker && selectedMarker.Latitude && selectedMarker.Longitude && (
                    <InfoWindow
                        position={{ lat: selectedMarker.Latitude, lng: selectedMarker.Longitude }}
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <div>
                            <div className="flex"  ><h1 className=" font-semibold pr-1"  >Ονομασία κέντρου:</h1>{selectedMarker.Hub_Name}</div>
                            <div className="flex"><h1 className=" font-semibold pr-1">ID Κόμβου:</h1>{selectedMarker.Hub_ID}</div>
                            <div className="flex"  ><h1 className=" font-semibold pr-1" >Ανήκει στην εταιρεία:</h1> {selectedMarker.Company_Name}</div>
                            <div className="flex"  ><h1 className=" font-semibold pr-1" >Διεύθυνση:</h1> {selectedMarker.Address} </div>
                            <div className="flex"  ><h1 className=" font-semibold pr-1" >Ταχυδρομικός κώδικας:</h1> {selectedMarker.Postal_Code } </div>
                        </div>
                    </InfoWindow>
                )}
                <Polyline
                    key={start_id}
                    path={[
                        { lat: start ? start.lat : defaultCenter.lat, lng: start ? start.lng : defaultCenter.lng },
                        { lat: end ? end.lat : defaultCenter.lat, lng: end ? end.lng : defaultCenter.lng },
                    ]}
                    options={{ strokeColor: "#FC4C02", strokeWeight: 1.5, strokeOpacity: 1 }}
                    />
            </GoogleMap>
        </LoadScriptNext>
    );
}

export default ConnectionMap;
