import React, { useRef } from 'react';
import "../../index.css"
import SpinnerComponent from './../spinnerComponent';

const Modal = ({ isOpen, title, message, onConfirm, onClose, messagefooter, loading }) => {
const modalRef = useRef();

if (!isOpen) {
    return null;
}

const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
    onClose();
    }
}


return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        {loading ? <div className='m-[10%]'>  <SpinnerComponent  />  </div> :
        <> 
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                {title}
                </h3>
                
                <div className="mt-2">
                <p className="text-md text-gray-500 my-3">
                    {message}
                </p>
                
                </div>
            </div>    
            </div>
        </div>
        
        <div className="bg-gray-50 px-4 py-3 sm:px-6 ">
        <div className='sm:flex sm:flex-row-reverse justify-center'>
            <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onConfirm}
            >
            Ναι
            </button>
            <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
            >
            Επιστροφή
            </button>
            
        </div>
        <p className=' text-sm text-red-800 font-semibold text-center'>
        {messagefooter}
        </p>
        </div>
        </>}
        
        </div>
    </div>
    </div>
);
};

export default Modal;
