import React, { useRef, useState } from 'react';
import "../../index.css"
import axios from 'axios';
import HubName from './../HubNameComponent';
import { useNavigate } from 'react-router-dom';


const InsertItemModal = ({ isOpen,  onClose,  resetForm, Request_ID, Item_Type, Height, Length,  Width, Weight, isVulnerable, isDangerous, isFragile, Order_Hub_ID, Delivery_Hub_ID,  Volumetric_Weight  }) => {
const modalRef = useRef();
const [status, setStatus] = useState('idle');
const navigate = useNavigate();

if (!isOpen) {
    return null;
}
const modalStyle = {
    width: 'fit-content',
    maxWidth: '90%', // To avoid the modal going off-screen
};
const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
    onClose();
    }
}


const HandleConfirm = async () => {
    try {
        // Getting information about the parent request of the item.
        const getRequestResponse = await axios.get('http://195.251.111.90/api/request/'+Request_ID, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'velos123',
            },
        });

        // Retrieve the existing total volumetric weight.
        let totalVolumetricWeight = getRequestResponse.data[0].Total_volumetric_weight;

        // Adding item's volumetric weight to the total.
        totalVolumetricWeight += Volumetric_Weight;

        const postResponse = await axios.post(
            'http://195.251.111.90/api/item',
            {
                "Request_ID": Request_ID,
                "Item_Type": Item_Type,
                "Item_Height": Height,
                "Item_Length": Length,
                "Item_Width": Width,
                "Item_Weight": Weight,
                "Vulnerable": isVulnerable,
                "Dangerous": isDangerous,
                "Fragile": isFragile,
                "Order_hub_ID": Order_Hub_ID,
                "Current_hub_ID": Order_Hub_ID,
                "Next_hub_id": null,
                "Delivery_hub_ID": Delivery_Hub_ID,
                "Volumetric_Weight": Volumetric_Weight
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'velos123',
                },
            }
        );

        

        // Update the new Total_volumetric weight
        const putResponse = await axios.put(
            'http://195.251.111.90/api/request/'+Request_ID,
            {
                
                    "Orderer_ID": getRequestResponse.data[0].Orderer_ID,
                    "Receiver_ID": getRequestResponse.data[0].Receiver_ID,
                    "Priority": getRequestResponse.data[0].Priority,
                    "Status_Request": getRequestResponse.data[0].Status_Request,
                    "Total_volumetric_weight": totalVolumetricWeight,
                    "Delivery_Start_Date": getRequestResponse.data[0].Delivery_Start_Date,
                    "Delivery_End_Date": null
                
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'velos123',
                },
            }
        );
        //console.log(getRequestResponse.data)
        //console.log(putResponse.data)
        //console.log(postResponse.data)
        setStatus('success');
    } catch (error) {
        setStatus('error');
        console.log(error);
    }
}


const ReturnButton = () => (
    <button
      type="button"
      className="mt-3 w-28  justify-center text-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
      onClick={()=> navigate('/request_insertion') }
    >
      Επιστροφή
    </button>
  );

let message;
if (status === 'success') {
    message = 'The data was successfully sent!';
} else if (status === 'error') {
    message = 'An error occurred while sending the data.';
}

if (status === 'idle') {
    // Display the form and the confirmation button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <> 
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                Επιβεβαίωση εισαγωγής νέας παραγγελίας
                </h3>
                
                <div className="mt-2">
                <table className="table-fixed ">
                <thead className="sticky -top-1  text-center">
                <tr className='text-center '>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Μέρος της παραγγελίας</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Τύπος τεμαχίου</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Ύψος</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Μήκος</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Πλάτος</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Βάρος</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Ογκομετρικό βάρος</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Είναι Ευάλωτο;</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Είναι Επικίνδυνο;</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Είναι Εύθραυστο;</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Κέντρο Παραλαβής</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Κέντρο Παράδοσης</span></th>
                </tr>
                </thead>
                <tbody>
                
                    <tr
                    className=""
                    
                    >
                    <td className="border border-[#0063BE] p-3 text-center"><span >#{Request_ID}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Item_Type === "Pallet" ? <>Παλέτα</>: <>Φάκελος/Πακέτο</>}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Height}cm</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Length}cm</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Width}cm</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Weight}kg</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{`${Volumetric_Weight}m³/kg`}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{isVulnerable === true ? <>Ναι</>: <>Οχι</>}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{isDangerous === true ? <>Ναι</>: <>Οχι</>}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{isFragile === true ? <>Ναι</>: <>Οχι</>}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span ><HubName id={Order_Hub_ID}/>  </span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span ><HubName id={Delivery_Hub_ID}/></span></td>
                    

                    
                    </tr>
                
                </tbody>
            </table>
                
                </div>
            </div>    
            </div>
        </div>
        
        <div className="bg-gray-50 px-4 py-3 sm:px-6 ">
        <div className='sm:flex sm:flex-row-reverse justify-center'>
            <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={HandleConfirm}
            >
            Επιβεβαίωση
            </button>
            <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
            >
            Επιστροφή
            </button>
            
        </div>
        <p className=' text-sm text-green-800 font-semibold text-center'>
        
        </p>
        </div>
        </>
        
        </div>
    </div>
    </div>
    );
  } else if (status === 'success') {
    // Display a success message and a return button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <div className='flex flex-col justify-center items-center p-5'>
        <p className=' p-5 text-green-800 font-semibold text-lg w-full text-center mb-4 border-b-2 border-[#4F70FF] '>Η εγγραφή έγινε με επιτυχία! </p>
        <p className='p-2 font-medium text-green-800'>Αν θέλετε να εισάγεται και άλλα τεμάχια στην παραγγελία πατήστε συνέχεια!</p>
        <p className='p-2 font-medium text-red-800'>Αν δεν θέλετε πατήστε επιστροφή για να εισάγεται νέα Παραγγελία</p>
        
        <div className=' flex flex-row w-full justify-evenly'>
        <button
        type="button"
        className="mt-3 w-28 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        onClick={() => {onClose(); setStatus('idle'); resetForm();}}
        >
        Συνέχεια
        </button>


        <ReturnButton />
        </div>
        </div>
        
        </div>
    </div>
    </div>
    );
} else if (status === 'error') {
    // Display an error message and a return button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <>
        <p>Κάτι που λάθος! Παρακαλώ ελέξτε τα δεδομένα ξανά!</p>
        <ReturnButton />
        </>
        
        </div>
    </div>
    </div>
    );
  }



};

export default InsertItemModal;
