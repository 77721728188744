import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import el from "date-fns/locale/el";
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './customDatePickerStyles.css'; // Make sure to link your CSS file
import SpinnerComponent from './../spinnerComponent';
import moment from 'moment';


registerLocale("el", el);

const toDateFormat = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return new Date(year, month - 1, day);
};

const formatDate = (date) => {
  
  return moment(date).format('DD-MM-YYYY');
};

const EventCalendar = () => {
  const navigate = useNavigate();
  const { data: dates, isLoading, error } = useQuery(['fetchUniqueDates'], async () => {
    const res = await axios.get('http://195.251.111.90/api/tracking/unique_dates', {
      headers: {
          "Content-Type": "application/json",
          Authorization: "velos123",
      },
    });
    return res.data.map(dateObj => toDateFormat(dateObj.Date_Made.split(' ')[0]));
  });

  if (isLoading) {
    return <div className='m-12'><SpinnerComponent/></div>;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  const handleDateChange = (date) => {
    navigate(`/calendar_date/${formatDate(date)}`);
  };

  return (
    <DatePicker
      inline
      onChange={handleDateChange} // Here we handle the date change
      highlightDates={dates}
      className="customDatePickerWidth"
      locale="el"
    />
  );
};

export default EventCalendar;
