import React, {useEffect, useState} from "react";
import { GoogleMap, LoadScriptNext, Marker, InfoWindow, Polyline } from "@react-google-maps/api";
import axios from 'axios';

const containerStyle = {
    width: "100%",
    height: "60vh",
    borderRadius:'15px',
    position:'relative',
    margin: '10px',
};
const defaultCenter = {
    lat: 38.6389,
    lng: 22.4181,
};

const fetchNodes = async (HubID) => {
    const response = await axios.get("http://195.251.111.90/api/hub/"+HubID, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return response.data[0]; // Assuming each response array has 1 object
};

function ItemRoute({ route, userInfo }) { // Assuming userInfo is a prop
    const [hubData, setHubData] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [connections, setConnections] = useState([]); // Update this based on your connections data
    const [isMapApiLoaded, setMapApiLoaded] = useState(false);

    useEffect(() => {
        const fetchAllHubs = async () => {
            const hubPromises = route.map(hubId => fetchNodes(hubId));
            const hubs = await Promise.all(hubPromises);
            setHubData(hubs);
        };

        fetchAllHubs();
    }, [route]);

    useEffect(() => {
        const newConnections = hubData.slice(1).map((hub, index) => {
        const start = { lat: hubData[index].Latitude, lng: hubData[index].Longitude };
        const end = { lat: hub.Latitude, lng: hub.Longitude };
        return { start, end };
        });
        setConnections(newConnections);
    }, [hubData]);
    
    return (
        <LoadScriptNext
            googleMapsApiKey="AIzaSyCuUvJVCyGmUQXLGrksPRqdRVVF8-aQW7E"
            onLoad={() => setMapApiLoaded(true)}
        > {hubData && isMapApiLoaded &&
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={7}
            >
                {hubData.map((node, index) => {
                    let color;
                    let infoWindowTitle;
                    if (index === 0) {
                    color = "orange";
                    infoWindowTitle = "Εναρκτηριος κόμβος";
                    } else if (index === hubData.length - 1) {
                    color = "green";
                    infoWindowTitle = "Κομβος παραλαβης";
                    } else {
                    color = "red";
                    infoWindowTitle = `Μετακίνηση #${index}`;
                    }

                    return (
                    <Marker
                        key={node.Hub_ID}
                        position={{ lat: node.Latitude, lng: node.Longitude }}
                        onClick={() => setSelectedMarker(node)}
                        icon={{
                        url:
                            require(`../assets/location-${color}.png`),
                        scaledSize: new window.google.maps.Size(32, 32),
                        }}
                    >
                        {selectedMarker === node && (
                        <InfoWindow
                            position={{ lat: selectedMarker.Latitude, lng: selectedMarker.Longitude }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                            <h1 className="font-semibold">{infoWindowTitle}</h1>
                            <div className="flex"><h1 className=" font-semibold pr-1">Ονομασία κέντρου:</h1>{selectedMarker.Hub_Name}</div>
                            <div className="flex"><h1 className=" font-semibold pr-1">ID Κόμβου:</h1>{selectedMarker.Hub_ID}</div>
                            <div className="flex"><h1 className=" font-semibold pr-1">Ανήκει στην εταιρεία:</h1> {selectedMarker.Company_Name}</div>
                            <div className="flex"><h1 className=" font-semibold pr-1">Διεύθυνση:</h1> {selectedMarker.Address} </div>
                            <div className="flex"><h1 className=" font-semibold pr-1">Ταχυδρομικός κώδικας:</h1> {selectedMarker.Postal_Code } </div>
                            </div>
                        </InfoWindow>
                        )}
                    </Marker>
                    );
                })}
                {connections.map((connection, index) => (
                    <Polyline
                        key={index}
                        path={[
                            { lat: connection.start.lat, lng: connection.start.lng },
                            { lat: connection.end.lat, lng: connection.end.lng },
                        ]}
                        options={{ strokeColor: "#FC4C02", strokeWeight: 1, strokeOpacity: 0.8 }}
                    />
                ))}
            </GoogleMap>
        }
        </LoadScriptNext>
    )
}
export default ItemRoute;
