import React, { useState,useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from './../utils/AuthContext';
import {BsFillInfoCircleFill} from "react-icons/bs"
import { useNavigate} from 'react-router-dom';
import SpinnerComponent from './spinnerComponent';


function LatestItems() {
    const { nodes: CompanyNodes } = useAuth();
    const navigate = useNavigate();
    const hubIDs = CompanyNodes.map(node => node.Hub_ID);
    const [hoveredRow, setHoveredRow] = useState(null);
    let latestEntries = [];

    const mutation = useMutation(async () => {
        const response = await axios.post(
            'http://195.251.111.90/api/tracking/node_list',
            {
                "nodes": hubIDs
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'velos123',
                },
            }
        );

        return response.data;
    });

    useEffect(() => {
        mutation.mutate();
    }, []);

    if (mutation.isLoading) {
        return <SpinnerComponent/>;
    }

    if (mutation.isError) {
        return <div>An error occurred: {mutation.error.message}</div>;
    }

    if (mutation.isSuccess) {
        
        if (Array.isArray(mutation.data)) {
          // Parse Date_Made into Date objects and find the maximum date
          const maxDate = mutation.data.reduce((max, entry) => {
            const entryDate = new Date(entry.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
            return entryDate > max ? entryDate : max;
          }, new Date(0));
      
          // Filter the array to only include entries with the maximum date
          latestEntries = mutation.data.filter(entry => {
            const entryDate = new Date(entry.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
            return entryDate.getTime() === maxDate.getTime();
          });
      
          // return JSX...
        } else {
          return <div>Data is not an array</div>;
        }
      
      
    
        return (
            <div className="m-[1%] overflow-auto h-[68vh] border border-[#0063BE]">
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 border border-[#0063BE]">ID Πακέτου/Παλέτας</th>
                    <th className="p-3 border border-[#0063BE]">ID Μεταφοράς</th>
                    <th className="p-3 border border-[#0063BE]">ID Τρέχοντος κόμβου</th>
                    <th className="p-3 border border-[#0063BE]">Κατάσταση Παραγγελίας</th>
                    <th className="p-3 border border-[#0063BE]">ID Κόμβου έναρξης</th>
                    <th className="p-3 border border-[#0063BE]">ID Επόμενου κόμβου</th>
                    <th className="p-3 border border-[#0063BE]">ID Τελικού κόμβου</th>
                    <th className="p-3 border border-[#0063BE]">Ημερομηνία εκτέλεσης</th>
                    <th className="p-3 border border-[#0063BE]"></th> {/* Added an extra header for info icon */}
                </tr>
                </thead>
                <tbody>
                {latestEntries.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Item_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Shipment_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Current_Hub_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Status}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Order_Hub_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Next_hub_id}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_Hub_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Date_Made}</td>
                    <td className="border border-[#0063BE] w-[2.5%] text-center"> {/* Added text-center class for centering */}
                        {hoveredRow === index && (
                            <BsFillInfoCircleFill
                            className=" cursor-pointer"
                            size={25}
                            color="#FC4C02"
                            onClick={() => navigate(`/item_details/${encodeURIComponent(JSON.stringify(entry.Item_ID))}`)}
                            />
                        )}
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        );
        
    }

    return null;
}

export default LatestItems;
