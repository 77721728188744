import { useState, useEffect } from "react";
import InsertHubModal from "../../components/InsertComponents.js/InsertHubModal";
import { useNavigate } from 'react-router-dom';

function InsertHub() {
  const [formState, setFormState] = useState({
    Hub_Name: "",
    Company_Name: "",
    Address: "",
    City: "",
    Max_Capacity: "",
    Current_Capacity: "",
    Postal_Code: "",
    Longitude: "",
    Latitude: ""
});

const [submitFormState, setSubmitFormState] = useState({});
const [isModalOpen, setIsModalOpen] = useState(false);
const navigate = useNavigate();

const handleInputChange = (event) => {
    setFormState({
        ...formState,
        [event.target.name]: event.target.value
    });
};

useEffect(() => {
  if(Object.keys(submitFormState).length > 0) {
  console.log(submitFormState); // This will log after state update
  }
}, [submitFormState]); // Dependency array, it re-runs the effect when submitFormState changes

const handleSubmit = (event) => {
  event.preventDefault();
  
  // convert form state to appropriate data types and set submitFormState
  setSubmitFormState({
    ...formState,
    Longitude: parseFloat(formState.Longitude),
    Latitude: parseFloat(formState.Latitude),
    Max_Capacity: parseInt(formState.Max_Capacity),
  });

  // Open the modal
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

const resetForm = () => {
  setFormState({
    Hub_Name: "",
    Company_Name: "",
    Address: "",
    City: "",
    Max_Capacity: "",
    Current_Capacity: "",
    Postal_Code: "",
    Longitude: "",
    Latitude: ""
});
}


    return (
        <div className="min-h-screen flex items-center justify-center">
          <InsertHubModal isOpen={isModalOpen} onSubmitData={submitFormState} resetForm={resetForm}  onClose={closeModal} />
            <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center mt-[8%]">
              <div className="flex justify-center items-center">
              <div className=" border-2 border-[#0063BE] rounded-md w-1/3  p-3 m-2">
                <h1 className="text-2xl font-bold text-gray-800">Εισαγωγή νέου κέντρου διανομών</h1>
                </div>
                
                </div>
                <div className=" flex ">
                <h1 className="  text-gray-800">Υπάρχει ήδη το κέντρο; Πατήστε <span className=" text-blue-400 hover:text-blue-900 hover:underline cursor-pointer"
                onClick={() => navigate('/connection_insertion')}> 
                  εδώ </span> 
                για να εισάγεται συνδέσεις</h1>
                </div>
                <form onSubmit={handleSubmit} className="mt-8 space-y-6">

                <div className="w-2/3  ">
                  <div className="flex justify-start my-8">
                  <h1 className=" text-2xl font-semibold ">Ονομασία και εταιρεία</h1>
                  </div>
                <div className="flex flex-row justify-start items-center ">
                <div className="relative mr-[20%] ">
                  <label htmlFor='Hub_Name' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                              formState.Hub_Name.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                          } ${formState.Hub_Name.length > 0 && 'text-blue-500'}`}>Όνομα κέντρου</label>

                  <input 
                      type="text" 
                      name="Hub_Name" 
                      value={formState.Hub_Name} 
                      onChange={handleInputChange} 
                      className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                      required/>
              </div>

                <div className="relative">
                  <label htmlFor='Company_Name' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                              formState.Company_Name.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                          } ${formState.Company_Name.length > 0 && 'text-blue-500'}`}>Όνομα εταιρείας</label>

                  <input 
                      type="text" 
                      name="Company_Name"
                      value={formState.Company_Name} 
                      onChange={handleInputChange} 
                      className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2"
                      required/>
              </div>
              </div>
              </div>

                    <div>
                    <div className="flex justify-start my-8">
                      <h1 className=" text-2xl  font-semibold ">Τοποθεσία Κέντρου</h1>
                      </div>
                    <div className=" flex flex-row  mb-8  w-2/3">
                      
                    <div className="relative  mr-[20%]">
                    <label htmlFor='Address' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.Address.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                    } ${formState.Address.length > 0 && 'text-blue-500'}`}>Διεύθυνση</label>
                    
                    <input 
                    type="text" 
                    name="Address"
                    value={formState.Address} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>

                    <div className="relative  mr-[20%]">
                    <label htmlFor='City' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.City.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                    } ${formState.City.length > 0 && 'text-blue-500'}`}>Πόλη</label>
                    
                    <input 
                    type="text" 
                    name="City"
                    value={formState.City} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>

                    

                    <div className="relative">
                    <label htmlFor='Postal_Code' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.Postal_Code.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                    } ${formState.Postal_Code.length > 0 && 'text-blue-500'}`}>Ταχυδρομικός κώδικας</label>
                    
                    <input 
                    type="text" 
                    name="Postal_Code"
                    value={formState.Postal_Code} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>
                    </div>
                    

                    <div className="flex flex-row  w-2/3 ">
                    <div className="relative mr-[20%] mt-4">
                    <label htmlFor='Longitude' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.Longitude.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                    } ${formState.Longitude.length > 0 && 'text-blue-500'}`}>Γεωγραφικό μήκος</label>
                    
                    <input 
                    type="number" 
                    name="Longitude"
                    value={formState.Longitude} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>

                    <div className="relative mt-4">
                    <label htmlFor='Latitude' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.Latitude.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                    } ${formState.Latitude.length > 0 && 'text-blue-500'}`}>Γεωγραφικό πλάτος</label>
                    
                    <input 
                    type="number" 
                    name="Latitude"
                    value={formState.Latitude} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>
                    </div>
                    </div>


                    <div >
                    <div className="flex justify-start my-8">
                      <h1 className=" text-2xl  font-semibold ">Τεχνικά χαρακτηριστικά</h1>
                      </div>
                    <div className="relative">
                    <label htmlFor='Max_Capacity' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                        formState.Max_Capacity.length > 0 ? ' -top-7 left-0.5' : 'text-base top-2.5 left-3'
                    } ${formState.Max_Capacity.length > 0 && 'text-blue-500'}`}>Μέγιστη χωρητικότητα</label>
                    
                    <input 
                    type="number" 
                    name="Max_Capacity"
                    value={formState.Max_Capacity} 
                    onChange={handleInputChange} 
                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2" 
                    required/>
                    </div>
                    </div>
                    <button type="submit" className="bg-[#0063BE] rounded-2xl text-white p-5 font-semibold">Υποβολή νέου κόμβου!</button>
                </form>
            </div>
        </div>
    );
}

export default InsertHub;
