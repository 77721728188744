import { useParams } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import HubName from './../components/HubNameComponent';
import SpinnerComponent from '../components/spinnerComponent';

function CalendarDate() {
  const { date } = useParams();
  const { nodes: CompanyNodes } = useAuth;
  const hubIDs = CompanyNodes?.map((node) => node.Hub_ID);
  const formattedDate = moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY');

  const [trackingData, setTrackingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log(formattedDate);

  

  const createTracking = async () => {
    try {
      const response = await axios.post(
        'http://195.251.111.90/api/tracking/selected_date_with_nodes',
        {
          "Date_Made": formattedDate,
          "nodes": [1,2],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      throw new Error('Error creating tracking'); // Throw an error to be handled by onError
    }
  };

  function renderStatus(status) {
    switch(status) {
        case 'Last_mile':
        return <>Προς παράδοση</>;
        case 'In_transit':
        return <>Καθ' οδόν</>;
      // Add cases for all other tables here...
        case 'Being_processed':
        return <>Υπό επεξεργασία</>;
        case 'Delivered':
        return <>Παραδόθηκε</>;
        
    default:
        return <div>---</div>;
    }
}
  const { mutate } = useMutation(createTracking, {
    onSuccess: (data) => {
      console.log(data)
      setIsLoading(false)
      setTrackingData(data); // Update the trackingData state with the received data
    },
    onError: (error) => {
      setIsLoading(false)
      console.error(error);
      // Handle the error here
    },
  });

  useEffect(() => {
    setIsLoading(true)
    mutate();
    
  }, []); // Empty dependency array to trigger the mutation on component mount

  

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center mt-[7%]">
        {isLoading ? (
          <div className=' h-[75vh] w-[75vw] justify-center items-center flex'>
          <SpinnerComponent />
          </div>
        ) : trackingData === null || !Array.isArray(trackingData) ? (
          <div className=' h-[75vh] w-[75vw] justify-center items-center flex'>
          <p>Σφάλμα δικτύου. Παρακαλώ βεβαιωθείτε ότι έχετε σύνδεση στο Internet και επαναφορτώστε την σελίδα</p>
          </div>
        ) : (
          <>
            <h1 className="text-2xl font-semibold text-gray-800">
              Εκτέλεση αλγορίθμου για: {formattedDate}
            </h1>
            <div className="m-[1%] overflow-auto h-[68vh] border-b border-[#0063BE]">
              <table className="table-fixed">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                  <tr>
                    <th className="p-3 border border-[#0063BE]">ID Πακέτου/Παλέτας</th>
                    <th className="p-3 border border-[#0063BE]">ID Μεταφοράς</th>
                    <th className="p-3 border border-[#0063BE]">Κατάσταση Παραγγελίας</th>
                    <th className="p-3 border border-[#0063BE]">Τρέχον κόμβου</th>
                    <th className="p-3 border border-[#0063BE]">Κόμβος έναρξης</th>
                    <th className="p-3 border border-[#0063BE]">Επόμενος κόμβου</th>
                    <th className="p-3 border border-[#0063BE]">Ημερομηνία εκτέλεσης</th>
                    <th className="p-3 border border-[#0063BE]">Έναρξη αποστολής</th>
                    <th className="p-3 border border-[#0063BE]">Λήξη αποστολής</th>
                  </tr>
                </thead>
                <tbody>
                  {trackingData.map((entry, index) => (
                    <tr className="group cursor-auto hover:bg-blue-200" key={index}>
                      <td className="border border-[#0063BE] p-2">#{entry.Item_ID}</td>
                      <td className="border border-[#0063BE] p-2">#{entry.Shipment_ID}</td>
                      <td className="border border-[#0063BE] p-2">
                        {renderStatus(entry.Item_Status)}
                      </td>
                      <td className="border border-[#0063BE] p-2">
                        <HubName id={entry.Current_Hub_ID} />
                      </td>
                      <td className="border border-[#0063BE] p-2">
                        <HubName id={entry.Order_Hub_ID} />
                      </td>
                      <td className="border border-[#0063BE] p-2">
                        <HubName id={entry.Next_hub_id} />
                      </td>
                      <td className="border border-[#0063BE] p-2">{entry.Date_Made}</td>
                      <td className="border border-[#0063BE] p-2">
                        {entry.Delivery_Start_Date ? (
                          <>{entry.Delivery_Start_Date}</>
                        ) : (
                          <span>---</span>
                        )}
                      </td>
                      <td className="border border-[#0063BE] p-2">
                        {entry.Delivery_End_Date ? (
                          <>{entry.Delivery_End_Date}</>
                        ) : (
                          <span>---</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
  }

export default CalendarDate;
