import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const DriverName = ({ id }) => {
    const fetchDriverName = async () => {
        const response = await axios.get(`http://195.251.111.90/api/driver/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        
        return response.data;
    };

    const { data: DriverName, isLoading } = useQuery(['DriverName', id], fetchDriverName);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return <span>#{DriverName[0].User_ID} {DriverName[0].First_Name} {DriverName[0].Last_Name}</span>;
};

export default DriverName;
