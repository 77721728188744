import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Select from 'react-select';
import SpinnerComponent from '../../components/spinnerComponent';
import { useState } from 'react';
import InsertIndividualModal from '../../components/InsertComponents.js/InsertIndividualModal';
import InsertCompanyModal from '../../components/InsertComponents.js/InsertCompanyModal';


function InsertCustomer() {
    
    const [postalCode, setPostalCode] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [typeOfCustomer, setTypeOfCustomer] = useState('');


    const [companyName, setCompanyName] = useState('');
    const [taxIndentification, setTaxIndentification] = useState('');
    const [publicFinanceDep, setPublicFinanceDep] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [indentityNumber, setIndentityNumber] = useState('');

    // Handlers to update state
    const handlePostalCodeChange = e => setPostalCode(e.target.value);
    const handleContactNumberChange = e => setContactNumber(e.target.value);
    const handleDeliveryAddressChange = e => setDeliveryAddress(e.target.value);
    const handleCityChange = e => setCity(e.target.value);
    const handleEmailChange = e => setEmail(e.target.value);
    
    
    const handlecompanyNameChange = e => setCompanyName(e.target.value);
    const handletaxIndentificationChange = (e) => {
        const re = /^[0-9\b]+$/; // allow only numbers and backspace
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 9) {
            setTaxIndentification(e.target.value);
        }
    };
        const handlepublicFinanceDepChange = e => setPublicFinanceDep(e.target.value);


        const handleFirstNameChange = e => setFirstName(e.target.value);
        const handleLastNameChange = e => setLastName(e.target.value);
        
        const handleIndentityNumberChange = e => {
            
                setIndentityNumber(e.target.value);
            
        };
        

        const [individualModal, setIndividualModal] =useState(false)
        const [companyModal, setCompanyModal] =useState(false)
        
        const handleSubmit = (event) => {
            event.preventDefault();

            if (typeOfCustomer === "Individual"){
                setIndividualModal(true);
            }
            else{
                setCompanyModal(true)
            }
            
        };
        
        const closeModal = () => {
            setIndividualModal(false);
            setCompanyModal(false);
        };
        
        const resetForm = () => {
            setPostalCode('') 
            setContactNumber('')
            setDeliveryAddress('')
            setEmail('')
            setCity('')
            setTypeOfCustomer('')
            setCompanyName('')
            setTaxIndentification('')
            setPublicFinanceDep('')
            setFirstName('')
            setLastName('')
            setIndentityNumber('')
        }
        



    const fetchCustomers = async () => {
        const { data } = await axios.get('http://195.251.111.90/api/customer/get_all_companies_individuals', {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
        });
        return data;
    };
    
    const { data, isLoading, isError } = useQuery(['Customers'], fetchCustomers);

    if (isLoading) {
        return <span className="min-h-screen flex items-center justify-center"><SpinnerComponent/></span>;
    }

    if (isError) {
        return <span className="min-h-screen flex items-center justify-center">Error: Something went wrong...</span>;
    }
    

    const options = data.map(customer => ({
        value: customer.Customer_ID,
        label: `${customer.Type_of_Customer === 'Company' ? customer.Company_name : `${customer.First_Name} ${customer.Last_Name}`}, ${customer.Contact_Number}, ${customer.Email}`,
    }));
    
    
    



    return (
    <div className="min-h-screen flex items-center justify-center">
        

        <div className=" w-full  bg-white rounded-lg shadow-lg p-8 border-2 border-green-800  text-center my-[9%]">
        <InsertIndividualModal 
        isOpen={individualModal} 

        Postal_Code={postalCode} 
        Contact_Number={contactNumber}
        Delivery_Address={deliveryAddress}
        City= {city}
        Type_of_Customer={typeOfCustomer}
        Email={email}
        First_Name={firstName}
        Last_Name={lastName}
        Indentity_Number={indentityNumber}

        resetForm={resetForm}  
        onClose={closeModal} />

    <InsertCompanyModal 
        isOpen={companyModal} 

        Postal_Code={postalCode} 
        Contact_Number={contactNumber}
        Delivery_Address={deliveryAddress}
        City= {city}
        Type_of_Customer={typeOfCustomer}
        Email={email}
        Company_Name={companyName}
        TIN={taxIndentification}
        PFD={publicFinanceDep}

        resetForm={resetForm}  
        onClose={closeModal} />
            
            <div className=" flex justify-center items-center">
                <div className=" rounded-2xl bg-[#4F70FF] w-1/3 text-white p-5">
                <h1 className="text-2xl text-white font-bold ">Εισαγωγή νέου πελάτη</h1>
                </div>






                
            </div>
            <div className=' flex flex-row  justify-center align-middle items-center mt-10 ml-[7%]'>
                
            <div className='  items-center '>
            
            <div className='flex  items-center my-5'>
                <h1 className=' text-xl font-semibold text-gray-700 '>Δεν είστε σίγουροι για την ύπαρξη του χρήστη;</h1>
            </div>
            <div className='  items-center'>
                
            <Select
                id="hub1"
                options={options}
                className="my-1 "
                placeholder="694******* ή *****@gmail.com"
                isSearchable
            />
            <p className=' font-semibold text-sm  text-start py-3'>Πληκτρολογήστε τον αριθμό επικοινωνίας ή το Email του πελάτη για να τον αναζητήσετε!</p>
            </div>
            </div>
            <div>
                    <img
                    src={require('../../assets/not_sure.png')}
                    alt="not sure"
                    className="w-16 h-16 m-5" // Adjust these values to resize the image
                    />
                </div>
            </div>






                        <form onSubmit={handleSubmit}>

                            <div className=' flex flex-row w-1/4 justify-between items-center align-middle  my-10 mt-16'>
                                
                                <div className=' flex '>
                                    <h1 className='text-2xl font-semibold'>Νέος Πελάτης</h1>
                                </div>
                                <div className=' mr-14'>
                                    <img
                                    src={require('../../assets/add-cust.png')}
                                    alt="not sure"
                                    className="w-16 h-16 m-5" // Adjust these values to resize the image
                                    />
                                </div>
                            </div>


                        <div className='flex-col flex '>
                            <div className=' flex my-4'>
                            <h1 className=' text-xl font-semibold text-gray-700'>Επιλέξτε τον τύπο του πελάτη</h1>
                            </div>
                            <div className='flex justify-center items-center'>
                        <div className='flex flex-row justify-around items-center bg-slate-200 w-[40%] rounded-2xl'>
                            <div 
                            className={`${typeOfCustomer === 'Company' ? 'bg-blue-500 text-white font-bold' : ''} transition-colors duration-300 ease-in-out cursor-pointer p-5 px-16 my-4 rounded-2xl font-medium text-lg`}
                            onClick={() => {setTypeOfCustomer("Company")}}>
                            <h1>Εταιρεία</h1>
                            </div>

                            <div 
                            className={`${typeOfCustomer === 'Individual' ? 'bg-blue-500 text-white font-bold' : ''} transition-colors duration-300 ease-in-out cursor-pointer p-5 px-16 my-4 rounded-2xl font-medium text-lg`}
                            onClick={() => {setTypeOfCustomer("Individual")}}>
                            <h1>Ιδιώτης</h1>
                            </div>
                        </div>
                        </div>
                        </div>







                        


                        {typeOfCustomer === 'Company' && (
                            <div className='my-10'>
                                <div className=' flex flex-row justify-start item-center'>
                                <div>
                                <img
                                    src={require('../../assets/company.png')}
                                    alt="not sure"
                                    className="w-16 h-16 m-5" // Adjust these values to resize the image
                                    />
                                </div>
                                <div className=' flex justify-center items-center'>
                                    <h1  className=' text-lg font-semibold text-gray-700'>Νέα Εταιρεία</h1>
                                </div>
                                
                                </div>

                                <div className=' flex flex-row justify-evenly mb-4'>
                                <div className=' relative'>
                                    <label htmlFor='company_name' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                        companyName.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                    } ${companyName.length > 0 && 'text-blue-500'}`}>Επωνυμία εταιρείας</label>
                                    
                                    <input 
                                    type="text" 
                                    name="company_name"  
                                    value={companyName} 
                                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                    onChange={handlecompanyNameChange} 
                                    required/>
                                </div>

                                <div className=' relative'>
                                    <label htmlFor='TIN' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                        taxIndentification.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                    } ${taxIndentification.length > 0 && 'text-blue-500'}`}>ΑΦΜ Εταιρείας</label>
                                    
                                    <input 
                                    type="text"
                                    name="TIN"
                                    value={taxIndentification} 
                                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                    onChange={handletaxIndentificationChange} 
                                    required/>
                                </div>

                                <div className=' relative'>
                                    <label htmlFor='PFD' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                        publicFinanceDep.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                    } ${publicFinanceDep.length > 0 && 'text-blue-500'}`}>ΔΟΥ </label>
                                    
                                    <input 
                                    type="text" 
                                    name="PFD"  
                                    value={publicFinanceDep} 
                                    className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                    onChange={handlepublicFinanceDepChange} 
                                    required/>
                                    <div className=' w-80'>
                                        <h1 className=' text-sm font-thin text-gray-700'>*Παρακαλώ πληκτρολογήστε και την πόλη που βρίσκεται η ΔΟΥ</h1>
                                    </div>
                                </div>
                                </div>
                            </div>
                        )}




                        

                        {typeOfCustomer === 'Individual' && (
                            <div className='my-10  '>
                            <div className=' flex flex-row justify-start item-center'>
                            <div>
                            <img
                                src={require('../../assets/add-indiv.png')}
                                alt="not sure"
                                className="w-16 h-16 m-5" // Adjust these values to resize the image
                                />
                            </div>
                            <div className=' flex justify-center items-center'>
                                <h1  className=' text-lg font-semibold text-gray-700'>Νέος Ιδιώτης</h1>
                            </div>
                            
                            </div>

                            <div className=' flex flex-row justify-evenly mb-4'>
                            <div className=' relative'>
                                <label htmlFor='fist_name' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                    firstName.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                } ${firstName.length > 0 && 'text-blue-500'}`}>Όνομα ιδιώτη</label>
                                
                                <input 
                                type="text" 
                                name="fist_name"  
                                value={firstName} 
                                className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                onChange={handleFirstNameChange} 
                                required/>
                            </div>

                            <div className=' relative'>
                                <label htmlFor='last_name' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                    lastName.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                } ${lastName.length > 0 && 'text-blue-500'}`}>Επώνυμο ιδιώτη</label>
                                
                                <input 
                                type="text" 
                                name="last_name"  
                                value={lastName} 
                                className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                onChange={handleLastNameChange} 
                                required/>
                            </div>

                            
                            <div className=' relative'>
                                <label htmlFor='indentity' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                                    indentityNumber.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                                } ${indentityNumber.length > 0 && 'text-blue-500'}`}>Αριθμός ταυτότητας</label>
                                
                                <input 
                                type="text"
                                name="indentity"
                                maxLength="9"
                                value={indentityNumber} 
                                className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                                onChange={handleIndentityNumberChange} 
                                required/>
                                <div className=' w-80'>
                                        <h1 className=' text-sm font-thin text-white'>*Παρακαλώ πληκτρολογήστε και τα γράμματα στην αρχή</h1>
                                    </div>
                            </div>
                            </div>
                        </div>
                        )}





                <div>
                    <div className=' flex flex-row justify-start item-center'>
                        <div>
                            <img
                                src={require('../../assets/cust-info.png')}
                                alt="not sure"
                                className="w-16 h-16 m-5" // Adjust these values to resize the image
                                />
                        </div>
                        <div className=' flex justify-center items-center'>
                            <h1  className=' text-lg font-semibold text-gray-700'>Λεπτομέρειες {typeOfCustomer==="Company" ? <>Εταιρείας</>: <>Ιδιώτη</>} </h1>
                        </div>
                    
                    </div>
                    <div className=' flex flex-row justify-evenly mb-4'>
                    <div className=' relative'>
                        <label htmlFor='contact_Number' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                            contactNumber.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                        } ${contactNumber.length > 0 && 'text-blue-500'}`}>Τηλέφωνο επικοινωνίας</label>
                        
                        <input 
                        type="text" 
                        name="contact_Number"  
                        value={contactNumber} 
                        className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                        onChange={handleContactNumberChange} 
                        required/>
                    </div>

                    <div className=' relative'>
                        <label htmlFor='deliveryAddress' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                            deliveryAddress.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                        } ${deliveryAddress.length > 0 && 'text-blue-500'}`}>Διεύθυνση</label>
                        
                        <input 
                        type="text" 
                        name="deliveryAddress"  
                        value={deliveryAddress} 
                        className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                        onChange={handleDeliveryAddressChange} 
                        required/>
                    </div>

                    <div className=' relative'>
                        <label htmlFor='city' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                            city.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                        } ${city.length > 0 && 'text-blue-500'}`}>Πόλη</label>
                        
                        <input 
                        type="text" 
                        name="city"  
                        value={city} 
                        className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                        onChange={handleCityChange} 
                        required/>
                        <div className=' w-80'>
                                        <h1 className=' text-sm font-thin text-white'>*Παρακαλώ πληκτρολογήστε και τα γράμματα στην αρχή</h1>
                                    </div>
                    </div>
                    </div>
                    
                    <div className=' flex flex-row justify-evenly mr-[27%] '>
                    <div className=' relative'>
                        <label htmlFor='postal_code' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                            postalCode.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                        } ${postalCode.length > 0 && 'text-blue-500'}`}>Ταχυδρομικός κώδικας</label>
                        
                        <input 
                        type="text" 
                        name="postal_code"  
                        value={postalCode} 
                        className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                        onChange={handlePostalCodeChange} 
                        required/>
                    </div>

                    <div className=' relative'>
                        <label htmlFor='email' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200  ${
                            email.length > 0 ? ' -top-6 left-0.5' : 'text-base top-3.5 left-4'
                        } ${email.length > 0 && 'text-blue-500'}`}>Email</label>
                        
                        <input 
                        type="text" 
                        name="email"  
                        value={email} 
                        className="mt-1 block border-2 border-[#0063BE] rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2 "
                        onChange={handleEmailChange} 
                        required/>
                        <div className=' w-80'>
                                        <h1 className=' text-sm font-thin text-white'>*Παρακαλώ πληκτρολογήστε και τα γράμματα στην αρχή</h1>
                                    </div>
                    </div>
                    </div>
                    </div>

                    <button type="submit" className="bg-[#0063BE] rounded-2xl text-white p-5 font-semibold">Υποβολή {typeOfCustomer==="Company" ? <>νέας Εταιρείας</>: <>νέου Ιδιώτη</>}! </button>
                </form>

                        



        </div>
    </div>
    );
}

export default InsertCustomer;
