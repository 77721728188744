import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DriverName from '../components/ShipmentDetailsComponents/DriverDetails';
import ShipmentContents from '../components/ShipmentDetailsComponents/ShipmentContents';
import ShipmentMap from '../components/ShipmentDetailsComponents/ShipmentMapComponent';
//import { useState } from 'react';
import HubOverflow from '../components/ShipmentDetailsComponents/HubOverflowComponent';

async function fetchShipment(shipmentid) {
    
    const res = await axios.get("http://195.251.111.90/api/shipment/"+ shipmentid,{
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return res.data;
}


function AlgorithmExecuteDetailsShipment() {
    const location = useLocation();
    const entryParam = location.pathname.split('/').pop();
    const shipmentId = JSON.parse(decodeURIComponent(entryParam));
    
    const { data: ShipmentData, isLoading, error } = useQuery(['fetchShipments', shipmentId],() => fetchShipment(shipmentId));

    if (isLoading) return 'Loading...';
    if (error) return 'An error has occurred: ' + error.message;

    const locationIds = ShipmentData.flatMap(shipment => [shipment.Location_Start_ID, shipment.Location_End_ID]);


    

    



console.log(shipmentId)
    return (
      <div className="min-h-screen flex items-center justify-center">
        
  
        <div className=" w-full  bg-white rounded-lg shadow-lg p-8 border-2 border-green-800  text-center my-[9%]">
          <h1 className="text-xl font-bold mb-10 p-2">Λεπτομέρειες για την μεταφορά {shipmentId}, με οδηγό <DriverName id= {ShipmentData[0].User_ID}/>.  </h1>
          <div className='flex justify-between' >
          <div className=' justify-start items-start '>
            <div className='flex pl-7 text-lg font-bold ' >Περιεχόμενα μεταφοράς: </div>
            <div className='overflow-auto h-[90vh]'>
            <ShipmentContents id={shipmentId} Total_Weight={ShipmentData[0].Total_weight} Quantity={ShipmentData[0].Quantity  }  />
            </div>
            <div className='flex justify-between m-5 mr-24'>
            <div className=' font-semibold' >Κόστος μεταφοράς: {ShipmentData[0].Cost} </div>
            <div className=' font-semibold' >Ποσότητα σε τεμάχια: {ShipmentData[0].Quantity} </div>
            <div className=' font-semibold' >Συνολικό βάρος: {ShipmentData[0].Total_weight} </div>
            </div>
          </div>
            <div className='w-[45%]' >
            
            <ShipmentMap route={locationIds}/>
            </div>
        </div>



        <div className='flex'>
        <div className=' font-light justify-start items-start '>
        Ημερομηνία Έκδοσης: {ShipmentData[0].Date_Made}
        </div>
        </div>
        <HubOverflow start_hub={ShipmentData[0].Location_Start_ID} weight={ShipmentData[0].Total_weight} Shipment_Status={ShipmentData[0].Shipment_Status} shipmentId={shipmentId} end_hub = {ShipmentData[0].Location_End_ID} />
        
        </div>
      </div>
    );
  }
  
  export default AlgorithmExecuteDetailsShipment;
  