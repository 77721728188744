import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';



const SideMenu = ({ isOpen, onClose }) => {
const sideMenuRef = useRef(null);
const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
    const handleClickOutside = (event) => {
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        onClose();
    }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
}, [onClose]);

return (
    <div
    ref={sideMenuRef}
    className={`fixed top-0 left-0 h-screen bg-white w-64 shadow-md transition-all duration-300 ease-in-out transform  ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
    }`}
    >
    <div className="  flex flex-col justify-between h-[100%] p-6 ">

        <div
        onClick={() => navigate('/')}
        className={`flex flex-col items-center justify-between border p-2 mx-3 cursor-pointer text-center border-[#05683A] rounded-2xl shadow-inner hover:ring-2 ring-green-300 hover:bg-orange-200 
        transition-all ease-in-out duration-300 transform hover:scale-110 hover:-translate-y-1  hover:font-normal  ${
        location.pathname === '/' ? ' bg-orange-200' : ''
        }`}
        style={{ width: '100%', maxWidth: '200px', maxHeight: '125px' }}
        >

        <div
            className={`font-extralight text-md border w-4/5 mx-1 p-2 border-[#FC4C02] rounded-lg ${
            location.pathname === '/' ? 'italic' : ''
            } transition-all duration-300`}
            style={{ fontFamily: 'Arial, sans-serif' }}
        >
            Αρχική Οθόνη
        </div>
        <img
            src={require('../assets/home.png')}
            alt="Belos Logo"
            className="w-14 h-14 mt-2" // Adjust these values to resize the image
        />

        </div>


        <div
        onClick={() => navigate('/graph')}
        className={`flex flex-col items-center justify-between border p-2 mx-3 cursor-pointer text-center border-[#05683A] rounded-2xl shadow-inner hover:ring-2 ring-green-300 hover:bg-blue-200 
        transition-all ease-in-out duration-300 transform hover:scale-110 hover:-translate-y-1  hover:font-normal  ${
        location.pathname === '/graph' ? ' bg-blue-200' : ''
        }`}
        style={{ width: '100%', maxWidth: '200px', maxHeight: '125px' }}
        >

        <div
            className={`font-extralight text-md border w-4/5 mx-1 p-2 border-[#FC4C02] rounded-lg ${
            location.pathname === '/graph' ? 'italic' : ''
            } transition-all duration-300`}
            style={{ fontFamily: 'Arial, sans-serif' }}
        >
            Δίκτυο Διανομής
        </div>
        <img
            src={require('../assets/graph-side-menu.png')}
            alt="Belos Logo"
            className="w-14 h-14 mt-2" // Adjust these values to resize the image
        />

        </div>



        <div onClick={() => navigate('/request_list')}
        className={`flex flex-col items-center justify-between border p-2 mx-3 cursor-pointer text-center border-[#05683A] rounded-2xl shadow-inner hover:ring-2 ring-green-300 hover:bg-yellow-200 
        transition-all ease-in-out duration-300 transform hover:scale-110 hover:-translate-y-1  hover:font-normal ${
        location.pathname === '/request_list' ? ' bg-yellow-200' : ''
        }`}

        style={{ width: '100%', maxWidth: '200px', maxHeight: '125px' }}
        >
        
        <div className={`font-extralight text-md border w-4/5 mx-1 p-2 border-[#FC4C02] rounded-lg ${
        location.pathname === '/request_list' ? 'italic' : ''
        } transition-all duration-300`}
        style={{ fontFamily: 'Arial, sans-serif' }}>
        
            Τα δέματα μου
        </div>
        <img
        src={require('../assets/request-list.png')}
        alt="Belos Logo"
        className="w-14 h-14 mt-2" // Adjust these values to resize the image
        />
        </div>

        <div onClick={() => navigate('/all_data')}
        className={`flex flex-col items-center justify-between border p-2 mx-3 cursor-pointer text-center border-[#05683A] rounded-2xl shadow-inner hover:ring-2 ring-green-300 hover:bg-green-200 
        transition-all ease-in-out duration-300 transform hover:scale-110 hover:-translate-y-1  hover:font-normal ${
        location.pathname === '/all_data' ? ' bg-green-200' : ''
        }`}

        style={{ width: '100%', maxWidth: '200px', maxHeight: '125px' }}
        >

            <div className={`font-extralight text-md border w-4/5 mx-1 p-2 border-[#FC4C02] rounded-lg ${
            location.pathname === '/all_data' ? 'italic' : ''
            } transition-all duration-300`}
            style={{ fontFamily: 'Arial, sans-serif' }} >
                Όλα τα δεδομένα
            </div>
        <img
        src={require('../assets/all-data.png')}
        alt="Belos Logo"
        className="w-14 h-14 mt-2" // Adjust these values to resize the image
        />

        </div>

        <div onClick={() => navigate('/statistics')}
        className={`flex flex-col items-center justify-between border p-2 mx-3 cursor-pointer text-center border-[#05683A] rounded-2xl shadow-inner hover:ring-2 ring-green-300 hover:bg-purple-200 
        transition-all ease-in-out duration-300 transform hover:scale-110 hover:-translate-y-1  hover:font-normal ${
        location.pathname === '/statistics' ? ' bg-purple-200' : ''
        }`}

        style={{ width: '100%', maxWidth: '200px', maxHeight: '125px' }}
        >

            <div className={`font-extralight text-md border w-4/5 mx-1 p-2 border-[#FC4C02] rounded-lg ${
            location.pathname === '/statistics' ? 'italic' : ''
            } transition-all duration-300`}
            style={{ fontFamily: 'Arial, sans-serif' }} >
                Στατιστικά
            </div>
        <img
        src={require('../assets/statistics.png')}
        alt="Belos Logo"
        className="w-14 h-14 mt-2" // Adjust these values to resize the image
        />
        </div>
        </div>
        </div>
);
};  
export default SideMenu;
