


export const CustomToolTip = ({name, children}) =>{
    return (
    <div className="  group relative inline-block" >
        {children}
        <div  className="invisible left-[15%] group-hover:visible opacity-0 group-hover:opacity-100 
        transition px-2 rounded  absolute top-full border text-white bg-[#4F70FF]  mt-2" >
            {name}   </div>
    </div>
    )
} 