import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import HubName from '../../components/HubNameComponent';
import { useState } from 'react';
import {BsFillInfoCircleFill} from "react-icons/bs"
import { Arrivals, Departures } from '../../components/photoComponents/IsCompanyNodes';

async function fetchShipments() {
    const res = await axios.get("http://195.251.111.90/api/shipment", {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return res.data;
}

function GraphShipment() {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entryParam = pathParts[pathParts.length - 2];
    const hub_id = JSON.parse(decodeURIComponent(entryParam));
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();




    const { data: ShipmentData, isLoading, error } = useQuery(['fetchShipments'], fetchShipments);

    const maxDate = ShipmentData?.reduce((max, entry) => {
        const entryDate = new Date(entry.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
        return entryDate > max ? entryDate : max;
    }, new Date(0));

    const latestShipments = ShipmentData?.filter(item => new Date(item.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")).getTime() === maxDate.getTime());

    const arrivingShipments = latestShipments?.filter(item => item.Location_End_ID === hub_id);
    const leavingShipments = latestShipments?.filter(item => item.Location_Start_ID === hub_id);

    //console.log('Arriving Shipments: ', arrivingShipments);
    //console.log('Leaving Shipments: ', leavingShipments);

    return (
        <div className="min-h-screen flex items-center justify-center mt-[5%]">
            <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center flex-col items-center justify-center ">

                <div className=' justify-center items-center flex'>
                    <div className='border-2 border-[#0063BE] rounded-md w-2/4  m-1 p-2 flex  items-center justify-center'>
                        <h1 className="text-xl font-normal text-gray-800  p-2">Τρέχουσες μετακινήσεις για το κέντρο διανομών <span className=' font-semibold'> <HubName id={hub_id}/></span></h1>
                    </div>
                </div>

                <div className="flex justify-around mt-5">
                
                    
                
                <div>
                <div className='flex justify-center items-center overflow-hidden  translate-y-[10%]  '>
                    <div className='flex justify-center items-center rounded-full bg-orange-400 w-20 h-20'>
                    <Arrivals />
                    </div>
                </div>
                    <div className='border-2 border-[#0063BE] rounded-md'>
                    <div className='flex justify-center items-center  p-5'>
                        <div className='flex justify-center items-center'>
                            <h2 className="font-semibold  text-lg">Αφίξεις</h2>
                        </div>
                    </div>
                        {arrivingShipments?.map((shipment, index) => (
                            <div key={index} className={` border-2 border-[#0063BE] flex flex-row m-3  rounded-xl items-center justify-center cursor-pointer
                            ${shipment.Shipment_Status === 'Confirmed' ? 'bg-green-500 border-[#0063BE]' : 
                            shipment.Shipment_Status === 'Rejected' ? 'bg-red-500 border-[#0063BE]' : 
                            'border-[#0063BE]'} 
                            ${hoveredRow === shipment.Shipment_ID ? 'hover:bg-[#0063BE] hover:text-white hover:border-green-800' : ''}
                            
                            `}
                            onMouseEnter={() => setHoveredRow(shipment.Shipment_ID)}
                            onMouseLeave={() => setHoveredRow(null)}
                            onClick={() => navigate(`/algorithm_execution/details/shipment/${encodeURIComponent(JSON.stringify(shipment.Shipment_ID))}`)}
                            >



                                <div className='border-r-2 border-[#0063BE] p-4'>
                                    <h1 className='font-semibold'>ID Μεταφοράς</h1>
                                    <span>{shipment.Shipment_ID}</span>
                                </div>
                                <div className='border-r-2 border-[#0063BE] p-4'>
                                <h1 className='font-semibold'>ID οδηγού</h1>
                                <span>{shipment.User_ID}</span>
                                </div>
                                <div className='border-r-2 border-[#0063BE] p-4'>
                                <h1 className='font-semibold'>Ημερομηνία Έκδοσης</h1>
                                <span>{shipment.Date_Made}</span>
                                </div>
                                <div className='border-r-2 border-[#0063BE] p-4 w-48 '>
                                <h1 className='font-semibold'>Φτάνει από:</h1>
                                <span><HubName id= {shipment.Location_Start_ID}/></span>
                                </div>
                                <div className='w-10' >
                                    {hoveredRow === shipment.Shipment_ID && (
                                        <BsFillInfoCircleFill
                                        className=" cursor-pointer "
                                        size={25}
                                        color="#FC4C02"
                                        
                                        />
                                    )}
                                    </div> 
                            </div>
                            
                        ))}
                    </div>
                    </div>


                    <div>
                    <div className='flex justify-center items-center overflow-hidden  translate-y-[10%]  '>
                    <div className='flex justify-center items-center rounded-full bg-green-400 w-20 h-20'>
                        <Departures/>
                    </div>
                </div>
                    <div className='border-2 border-[#0063BE] rounded-md '>
                    <div className='flex justify-center items-center  p-5'>
                        <div className='flex justify-center items-center'>
                            <h2 className="font-semibold  text-lg">Αναχωρήσεις</h2>
                        </div>
                    </div>
                        {leavingShipments?.map((shipment, index) => (
                            <div key={index} className={` border-2 border-[#0063BE] flex flex-row m-3  rounded-xl items-center justify-center cursor-pointer
                            ${shipment.Shipment_Status === 'Confirmed' ? 'bg-green-500 border-[#0063BE]' : 
                            shipment.Shipment_Status === 'Rejected' ? 'bg-red-500 border-[#0063BE]' : 
                            'border-[#0063BE]'} 
                            ${hoveredRow === shipment.Shipment_ID ? 'hover:bg-[#0063BE] hover:text-white hover:border-green-800' : ''}
                            
                            `}
                            onMouseEnter={() => setHoveredRow(shipment.Shipment_ID)}
                            onMouseLeave={() => setHoveredRow(null)}
                            onClick={() => navigate(`/algorithm_execution/details/shipment/${encodeURIComponent(JSON.stringify(shipment.Shipment_ID))}`)}
                            >
                            <div className='border-r-2 border-[#0063BE] p-4'>
                                <h1 className='font-semibold'>ID Μεταφοράς</h1>
                                <span>{shipment.Shipment_ID}</span>
                            </div>
                            <div className='border-r-2 border-[#0063BE] p-4'>
                            <h1 className='font-semibold'>ID οδηγού</h1>
                            <span>{shipment.User_ID}</span>
                            </div>
                            <div className='border-r-2 border-[#0063BE] p-4'>
                                <h1 className='font-semibold'>Ημερομηνία Έκδοσης</h1>
                                <span>{shipment.Date_Made}</span>
                                </div>
                            <div className='border-r-2 border-[#0063BE] p-4 w-48 '>
                            <h1 className='font-semibold'>Αναχωρεί για:</h1>
                            <span><HubName id= {shipment.Location_End_ID}/></span>
                            </div>
                            <div className='w-10' >
                            {hoveredRow === shipment.Shipment_ID && (
                                <BsFillInfoCircleFill
                                className=" cursor-pointer "
                                size={25}
                                color="#FC4C02"
                                
                                />
                            )}
                            </div> 
                        </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GraphShipment;
