import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import HubName from './../HubNameComponent';

function TrackingData() {
  const fetchItems = async () => {
    const response = await axios.get('http://195.251.111.90/api/tracking', 
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
    });
    return response.data;
  };
  

  const { isLoading, error, data } = useQuery(['items'], fetchItems);


  function renderStatus(status) {
    switch(status) {
        case 'Last_mile':
        return <>Προς παράδοση</>;
        case 'In_transit':
        return <>Καθ' οδόν</>;
      // Add cases for all other tables here...
        case 'Being_processed':
        return <>Υπό επεξεργασία</>;
        case 'Delivered':
        return <>Παραδόθηκε</>;
        
    default:
        return <div>---</div>;
    }
}




 //console.log(data[0])
  if (error) return 'An error has occurred: ' + error.message;
  return (
    <div className=" flex flex-col items-center justify-center border-2 border-[#0063BE] rounded-lg bg-white min-w-full min-h-full   ">
        {isLoading ? <SpinnerComponent/> :
        <>
        <div className=' p-5'>
        <h1 className=' text-2xl font-semibold text-slate-800'> Εντοπισμός</h1>
        </div>
     
       <>
       <div className=' overflow-auto h-[100vh]   '>
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 ">ID Πακέτου/Παλέτας</th>
                    <th className="p-3 ">ID Κέντρου</th>
                    <th className="p-3 ">ID Μεταφοράς</th>
                    <th className="p-3 ">Κατάσταση</th>
                    <th className="p-3 ">Έναρξη μεταφοράς</th>
                    <th className="p-3 ">Λήξη μεταφοράς</th>
                    <th className="p-3 ">Εναρκτήριος κόμβος </th>
                    <th className="p-3 ">Τρέχον κόμβος</th>
                    <th className="p-3 ">Επόμενος κόμβος</th>
                    <th className="p-3 ">Τελικός κόμβος</th>
                    <th className="p-3 ">Ημερομηνία έκδοσης </th>
                </tr>
                </thead>
                <tbody>
                {data?.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                   
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Item_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Hub_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Shipment_ID}</td>
                    <td className="border border-[#0063BE] p-1">{renderStatus(entry.Item_Status)}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_Start_Date? <>{entry.Delivery_Start_Date}</> : <>---</>}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_End_Date? <>{entry.Delivery_End_Date}</> : <>---</>}</td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Order_Hub_ID}/></td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Current_Hub_ID}/></td>

                    <td className="border border-[#0063BE] p-1">
                        {entry.Next_hub_id ?<HubName id={entry.Next_hub_id}/> : <>---</>}
                        
                        </td>

                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Delivery_Hub_ID}/></td>
                    <td className="border border-[#0063BE] p-1">{entry.Date_Made}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            
        </>
        </>
    }
    
    </div>
  );
}

export default TrackingData;
