import ConfirmedShipmentsStatistics from "../components/statisticsComponents/statisticsConfirmedShipments";
import ItemStatistics from "../components/statisticsComponents/statisticsItem";
import RejectedShipmentsStatistics from "../components/statisticsComponents/statisticsRejectedShipments";
import AmountShippedStatistics from "../components/statisticsComponents/statisticsTotalAmountShipped";
import TotalShipmentsStatistics from "../components/statisticsComponents/statisticsTotalShipments";
import WeightShippedStatistics from "../components/statisticsComponents/statisticsTotalWeightShipped";
import { useAuth } from "../utils/AuthContext";

function getColorClass(percentage) {
  if (percentage <= 50) {
    return "bg-green-600";
  } else if (percentage <= 75) {
    return "bg-orange-600";
  } else {
    return "bg-red-600";
  }
}

function Statistics() {
  const { nodes, userInfo } = useAuth();

  return (
    <div className=" flex items-center justify-center mt-[8%] mb-[4%]">
      <div className="bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center min-h-[75vh] min-w-[75vw] justify-center items-center flex mt-[3%]">
        <div className=" h-full">
        <div className=" flex justify-center flex-col ">


        <div className="flex justify-center items-center">
        <div className=" py-1 w-[35%] bg-[#4F70FF]  rounded-2xl  hover:bg-[#3054f7] cursor-auto">
          <h1 className=" text-white text-ld"
          >Στατιστικά στοιχεία για: <h1 className="font-bold">{userInfo.Company_Assigned}</h1> </h1>
        </div>
        </div>


        <div className="flex flex-wrap w-[75vw] justify-center items-center  flex-row">
          {nodes.map((node, index) => (
            <div key={node.Hub_ID} className="flex items-center justify-around flex-col w-[550px] p-1   m-[3%] border-2 rounded-lg border-green-800">
              <h2 className="text-xl font-semibold">{node.Hub_Name}</h2>
              <div className=" flex justify-start items-center w-full p-1">
                <h1 className=" font-medium">Χωρητικότητα:</h1>
                </div>
              <div className="relative w-full bg-gray-200 rounded-lg h-8  ">
                <div
                  className={`absolute h-full rounded-lg transition-all ${getColorClass(
                    (node.Current_Capacity / node.Max_Capacity) * 100
                  )}`}
                  style={{
                    width: `${(node.Current_Capacity / node.Max_Capacity) * 100}%`,
                  }}
                >
                  <div className="absolute inset-0 flex items-center justify-center text-white">
                    {Math.round((node.Current_Capacity / node.Max_Capacity) * 100)}%
                  </div>
                </div>
              </div>
              
              <div className=" w-full mt-3">
                    <ItemStatistics hub_id={node.Hub_ID}/>
                  </div>

              <div className=" w-full">
                <AmountShippedStatistics hub_id={node.Hub_ID}/>
                  </div>

              <div className=" w-full">
                <WeightShippedStatistics hub_id={node.Hub_ID}/>
              </div>

              <div className=" w-full">
                <TotalShipmentsStatistics hub_id={node.Hub_ID}/>
              </div>

              <div className=" w-full">
                <ConfirmedShipmentsStatistics hub_id={node.Hub_ID}/>
              </div>
              
              <div className=" w-full">
                <RejectedShipmentsStatistics hub_id={node.Hub_ID}/>
              </div>
            </div>
          ))}
        </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
