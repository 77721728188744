import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

function RejectedShipmentsStatistics({ hub_id }) {
const { data, isLoading, isError } = useQuery(
    ["RejectedShipmentsStatistics", hub_id],
    async () => {
        const response = await axios.get(
            `http://195.251.111.90/api/shipment/shipment_statistics_count/${hub_id}`,
            {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'velos123', 
            },
            }
        );
        
    return response.data;
    }
);

if (isLoading) {
    return <div>Loading...</div>;
}

if (isError) {
    return <div>Error loading data</div>;
}

//console.log(data[0])

return (
    <div className=" flex items-center flex-row w-[100%]">

            <div className=" flex items-center mr-[8%]  ">
        <img
        src={require('../../assets/delivery-failed.png')}
        alt="fragile"
        className=" m-2 " // Adjust these values to resize the image
        />
            </div>

<div className="flex w-full h-full justify-start items-center ">
    <div className=" flex  ">
    <h1>Συνολικός αριθμός <b>απορριφθέντων</b> μεταφορών: <span>{data[0].Rejected_Shipments}</span></h1>
    </div>
    </div>
    </div>
);
}

export default RejectedShipmentsStatistics;
