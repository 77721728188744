import React, {useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ItemModal from './ItemModal';


const HubOverflow = ({ start_hub, weight, Shipment_Status, shipmentId, end_hub }) => {
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    const [isOpenModalReject, setIsOpenModalReject] = useState(false);

    //console.log(shipmentId);
    async function handleButtonClickConfirmed() {
        
        setLoading(true);
    
        try {
            const responseConfirm = await axios.put("http://195.251.111.90/api/shipment/" + shipmentId + "/status/", 
            {
                "Shipment_Status": "Confirmed",
                
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });
    
            // If the request is successful, setStatus will update the status state
            setStatus("Confirmed");
            setIsOpenModalConfirm(false) // <-- Add this line to close the modal
            //console.log(responseConfirm);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            window.location.reload();
        }
    }



    
///////////////////////////////// REJECT   ////////////////////////////////////////////////////////

async function handleButtonClickRejected() {
    
    setLoading(true);

    try {


        const responseReject = await axios.put("http://195.251.111.90/api/shipment/" + shipmentId + "/status/", 
        {
            "Shipment_Status": "Rejected",
            
        }, 
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });


        const shipmentContentsResponse = await axios.get(`http://195.251.111.90/api/tracking/shipment/${shipmentId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        const shipmentContents = shipmentContentsResponse.data;
        //console.log(shipmentContents)
         
        const updatePromises = await axios.put(`http://195.251.111.90/api/tracking/shipment/${shipmentId}/status`, 
            {
                "Item_Inclusion_Status": "Rejected", 
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            })
        

        
        //console.log(updatePromises)
        
        const updateItemPromises = shipmentContents.map((item) =>
            axios.put(`http://195.251.111.90/api/item/${item.Item_ID}/next_hub`,
            {
                "Next_hub_id": null, 
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            })
        );

        await Promise.all(updateItemPromises);
        //console.log(updateItemPromises)

        const requestUpdatePromises = [];
        for (const item of shipmentContents) {
            const itemListResponse = await axios.get(`http://195.251.111.90/api/item/${item.Item_ID}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });
            const itemList = itemListResponse.data;
            itemList.forEach((item) => {
                const promise = axios.put(`http://195.251.111.90/api/request/${item.Request_ID}/status`, 
                {
                    "Status_Request":"In_transit"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "velos123",
                    },
                });
                requestUpdatePromises.push(promise);
            });
        }

        await Promise.all(requestUpdatePromises);
        //console.log(requestUpdatePromises)

        const StartHubRes = await axios.put(`http://195.251.111.90/api/hub/${start_hub}`, 
            {
                "Address": HubOverflow[0].Address,
                "City": HubOverflow[0].City,
                "Company_Name":HubOverflow[0].Company_Name,
                "Current_Capacity": HubOverflow[0].Current_Capacity + weight,
                "Hub_Name":HubOverflow[0].Hub_Name,
                "Latitude":HubOverflow[0].Latitude,
                "Longitude":HubOverflow[0].Longitude,
                "Max_Capacity":HubOverflow[0].Max_Capacity,
                "Postal_Code":HubOverflow[0].Postal_Code
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            })

            const response = await axios.get(`http://195.251.111.90/api/hub/${end_hub}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });

            const EndHubRes = await axios.put(`http://195.251.111.90/api/hub/${end_hub}`, 
            {
                "Address": response[0].Address,
                "City": response[0].City,
                "Company_Name":response[0].Company_Name,
                "Current_Capacity": response[0].Current_Capacity - weight,
                "Hub_Name":response[0].Hub_Name,
                "Latitude":response[0].Latitude,
                "Longitude":response[0].Longitude,
                "Max_Capacity":response[0].Max_Capacity,
                "Postal_Code":response[0].Postal_Code
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            })

            //console.log(StartHubRes)
            //console.log(EndHubRes)

        setStatus("Rejected");
        setIsOpenModalReject(false) 
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
        window.location.reload(); 
    }
}

    
    const handleModalCloseConfirm = () => {
        ;
        
    }

    const handleModalCloseReject = () => {
        
        setIsOpenModalReject(false);
    }


    const fetchHubOverflow = async () => {
        const response = await axios.get(`http://195.251.111.90/api/hub/${start_hub}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        setStatus(Shipment_Status)
        return response.data;
    };

    

    const { data: HubOverflow, isLoading } = useQuery(['HubOverflow', start_hub], fetchHubOverflow);

    if (isLoading) {
        return <p>Loading...</p>;
    }
//console.log(HubOverflow)
    return (
        <div className=' m-5'>
        {status === "Pending" ?
        <>
        
        <div className='flex justify-center'>
            <div 
                className={`p-5 m-3 border-green-700 font-semibold border-2 w-60 rounded-xl  ${status === 'Confirmed' && 'bg-green-700 text-white'} hover:bg-green-700 hover:text-white cursor-pointer`}
                onClick={() => { setIsOpenModalConfirm(true); setTitle('Επιβεβαίωση πρότασης!'); setMessage('Είστε σίγουρος για την επιβεβαίωση της μεταφοράς '+ shipmentId +';')}}
                disabled={loading}
            >
                Επιβεβαίωση πρότασης

                {isOpenModalConfirm &&
                <ItemModal
                    isOpen={isOpenModalConfirm}
                    title={title}
                    message={message}
                    loading = {loading}
                    messagefooter = {"Η εν λόγω πράξη είναι μη αναστρέψιμη."}
                    onClose={() =>{setIsOpenModalConfirm(false)}}
                    onConfirm={handleButtonClickConfirmed}
                /> }

            </div>

            <div 
                className={`p-5 m-3 border-red-700 font-semibold border-2 w-60  rounded-xl  ${status === 'Rejected' && 'bg-red-700 text-white'} hover:bg-red-700 hover:text-white cursor-pointer`}
                onClick={() => { setIsOpenModalReject(true); setTitle('Απόρριψη πρότασης!'); setMessage('Είστε σίγουρος για την απόρριψη της μεταφοράς '+ shipmentId +';')}}
                disabled={loading}
            >
                Απόρριψη πρότασης
            </div>
            {isOpenModalReject &&
            <ItemModal
            isOpen={isOpenModalReject}
            title={title}
            message={message}
            loading = {loading}
            messagefooter = {"Η εν λόγω πράξη είναι μη αναστρέψιμη."}
            onClose={handleModalCloseReject}
            onConfirm={handleButtonClickRejected}
            />
            }
            </div>
        </>
        : 
        <>
        
        <div className='flex justify-center cursor-not-allowed'>
            <div 
                className={`p-5 m-3 border-green-700 font-semibold border-2 w-60 rounded-xl  ${status === 'Confirmed' && 'bg-green-700 text-white'} cursor-not-allowed`}
                
                disabled={loading}
            >
                Επιβεβαίωση πρότασης

                

            </div>

            <div 
                className={`p-5 m-3 border-red-700 font-semibold border-2 w-60  rounded-xl  ${status === 'Rejected' && 'bg-red-700 text-white'}cursor-not-allowed`}
                
                disabled={loading}
            >
                Απόρριψη πρότασης
            </div>
            
            </div>
            {status==="Confirmed" ? 
            <div className=' text-green-700 font-bold' >Η αποστολή επιβεβαιώθηκε!</div>
            :<div className=' text-red-700 font-bold' >Η αποστολή απορίφθηκε!</div>}
        </>
        }
    
        
        
        
    
</div>
    )
    
    
    
    {HubOverflow[0].Current_Capacity + weight >= HubOverflow[0].Max_Capacity ?
        <>
        <div className=' text-red-700 font-bold' >Η μεταφορά δεν γίνεται να απορριφθεί! Δεν υπάρχει διαθέσιμη χωρητικότητα στον κόμβο {HubOverflow[0].Hub_Name}</div> 
        </>
        : <></> }
    
    
    
    
};

export default HubOverflow;
