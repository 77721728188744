import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import HubName from './../HubNameComponent';

function CustomerData() {
  const fetchItems = async () => {
    const response = await axios.get('http://195.251.111.90/api/customer', 
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
    });
    return response.data;
  };

  const { isLoading, error, data } = useQuery(['items'], fetchItems);

 //console.log(data[0])
  if (error) return 'An error has occurred: ' + error.message;
  return (
    <div className=" flex flex-col items-center justify-center border-2 border-[#0063BE] rounded-lg bg-white   ">
        <div className=' p-5'>
        <h1 className=' text-2xl font-semibold text-slate-800'> Πελάτες</h1>
        </div>
     {isLoading ? <SpinnerComponent/> :
       <>
       <div className=' overflow-auto h-[100vh]   '>
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 ">ID Πελάτη</th>
                    <th className="p-3 ">Ιδιότητα Πελάτη</th>
                    <th className="p-3 ">Email</th>
                    <th className="p-3 ">Αριθμός επικοινωνίας</th>
                    <th className="p-3 ">Ταχυδρομικός κώδικας</th>
                    <th className="p-3 ">Διεύθυνση</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                   
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Customer_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Type_of_Customer}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Email}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Contact_Number}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Postal_Code}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_Address}</td>
                    
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            
        </>
    }
    </div>
  );
}

export default CustomerData;
