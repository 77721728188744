import { useState } from "react";
import ItemData from "../../components/allDataComponents/ItemData";
import RequestData from "../../components/allDataComponents/RequestData";
import CustomerData from "../../components/allDataComponents/CustomerData";
import ShipmentData from "../../components/allDataComponents/ShipmentData";
import HubData from "../../components/allDataComponents/HubData";
import DriverData from "../../components/allDataComponents/DriverData";
import TrackingData from "../../components/allDataComponents/TrackingData";


function AllData() {
  const [selectedTable, setSelectedTable] = useState('Item');
  const [selectedSubTable, setSelectedSubTable] = useState('');
  

  function renderTableData() {
    switch(selectedTable) {
      case 'Item':
        return <ItemData/>;
      case 'Request':
        return <RequestData/>;

      case 'Customer':
        return <CustomerData/>;
      
      case 'Shipment':
        return <ShipmentData/>;

      case 'Hub':
        return <HubData/>;
      
      case 'Tracking':
        return <TrackingData/>;

      case 'Driver':
        return <DriverData/>;
      default:
        return <div>No Table Selected</div>;
    }
  }




    return (
      <div className="min-h-screen flex items-center justify-center ">
        <div className=" w-[98vw]  text-center my-[9%]">
            <div className="flex ">
                <div className="flex flex-col justify-around items-center border-2 bg-white border-[#05683A] rounded-2xl p-8 w-60">
                  <div
                  className={`flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer mb-5 
                  ${selectedTable === 'Item' ? 'bg-[#4F70FF] text-white' : ''}`}
                  onClick={() => { setSelectedTable('Item') }}>

                <h1 className=" text-2xl font-semibold">
                Τεμάχια
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Request'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Request')}}>
              <h1 className=" text-2xl font-semibold">
                Παραγγελίες
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Customer'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Customer')}}>
              <h1 className=" text-2xl font-semibold">
                Πελάτες
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Shipment'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Shipment')}}>
              <h1 className=" text-2xl font-semibold">
                Μεταφορές
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Hub'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Hub')}}>
              <h1 className=" text-2xl font-semibold">
                Κέντρα
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Tracking'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Tracking')}}>
              <h1 className=" text-2xl font-semibold">
                Εντοπισμός
                </h1>
              </div>

              <div className={` flex px-10 py-5 border-2 border-[#4F70FF] hover:opacity-90 hover:bg-[#4F70FF] hover:text-white rounded-2xl w-52 items-center justify-center cursor-pointer my-5 
              ${selectedTable==='Driver'? 'bg-[#4F70FF] text-white' : ''}`}
              onClick={ () =>{setSelectedTable('Driver')}}>
              <h1 className=" text-2xl font-semibold">
                Οδηγοί
                </h1>
              </div>
              </div>
            
              <div className="flex-grow flex items-center justify-center w-[80%] ml-5">
          {selectedTable && renderTableData() }
        </div>
        </div>
       

       <div>
        SubTables
       </div>

          </div>


          
            
        </div>
      
    );
  }
  
  export default AllData;
  