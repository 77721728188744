import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const UserInfo = ({ children }) => {
  const { userId, setIsLoggedIn, userInfo } = useAuth();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isChildHovered, setIsChildHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { data } = useQuery(['employeeData'], async () => {
    const response = await axios.get('http://195.251.111.90/api/Driver/' + userId, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'velos123',
      },
    });

    return response.data;
  });

  useEffect(() => {
    let timeoutId;
    if (isHovered || isChildHovered) {
      setIsVisible(true);
    } else {
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 100); // Add a delay of 1000 ms (1 second)
    }
    return () => clearTimeout(timeoutId);
  }, [isHovered, isChildHovered]);
  
  useEffect(() => {
    setIsChildHovered(false);
  }, [data]);
  

  const handleLogout = () => {
    Cookies.remove('userId');
    setIsLoggedIn(false);
    navigate('/login');
  }

  const handleResponse = () => {
    if (data)
      return (
        <div className=" bg-white p-3 rounded-xl">
          <h1>User_ID: {userId}</h1>
          <h1>Username: {userInfo.Username}</h1>
          <h1 className="w-56 break-words">{data[0].Last_Name} {data[0].First_Name}</h1>
          <h1>Εταιρεία: {userInfo.Company_Assigned} </h1>
          
        
        </div>
      );
  };

  return (
    <div
      className="group relative inline-block transition"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      <div
        className={`transition-all duration-500 ease-in-out rounded-xl absolute top-full left-[-110%]  max-w-2xl border border-[#05683A] h-auto w-auto mt-1 bg-slate-300 text-center  shadow-md p-6 ${
          isVisible ? "visible opacity-100" : "invisible opacity-0"
        }`}
        onMouseEnter={() => setIsChildHovered(true)}
        onMouseLeave={() => setIsChildHovered(false)}
      >
        <div className="flex flex-col space-y-4 ">
          {handleResponse()}
          <button
            onClick={handleLogout}
            className="transition bg-white p-3 rounded-xl m-4 text-center flex items-center justify-between hover:bg-red-700 hover:animate-pulse hover:text-white "
          >
            <div className="flex items-center space-x-2 justify-between">
              <img
                src={require("../assets/logout 1.png")}
                alt="Belos Logo"
                className="mx-3 w-auto h-auto"
              />
              <h1 className="text-center mb-1 ">Αποσύνδεση</h1>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
      };
