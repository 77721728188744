import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import HubName from './../HubNameComponent';

function RequestData() {
  const fetchRequests = async () => {
    const response = await axios.get('http://195.251.111.90/api/request', 
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
    });
    return response.data;
  };


function renderStatus(status) {
    switch(status) {
        case 'Last_mile':
        return <>Προς παράδοση</>;
        case 'In_transit':
        return <>Καθ' οδόν</>;
      // Add cases for all other tables here...
        case 'Being_processed':
        return <>Υπό επεξεργασία</>;
        case 'Delivered':
        return <>Παραδόθηκε</>;
        
    default:
        return <div>---</div>;
    }
}

  const { isLoading, error, data } = useQuery(['requests'], fetchRequests);

 //console.log(data[0])
  if (error) return 'An error has occurred: ' + error.message;
  return (
    <div className=" flex flex-col items-center justify-center border-2 border-[#0063BE] rounded-lg bg-white   ">
        <div className=' p-5'>
        <h1 className=' text-2xl font-semibold text-slate-800'> Παραγγελίες</h1>
        </div>
     {isLoading ? <SpinnerComponent/> :
       <>
       <div className=' overflow-auto h-[100vh]   '>
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 ">ID Παραγγελίας</th>
                    <th className="p-3 ">Αποστολέας</th>
                    <th className="p-3 ">Παραλήπτης</th>
                    <th className="p-3 ">Προτεραιότητα</th>
                    <th className="p-3 ">Κατάσταση</th>
                    <th className="p-3 ">Ογκομετρικό βάρος</th>
                    <th className="p-3 ">Ημερομηνία έναρξης</th>
                    <th className="p-3 ">Ημερομηνία λήξης</th>
                    
                </tr>
                </thead>
                <tbody>
                {data?.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                   
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Request_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Orderer_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Receiver_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Priority === 'High' ? <>Υψηλή</> : <>Κανονική</>}</td>
                    <td className="border border-[#0063BE] px-2 py-1">{renderStatus(entry.Status_Request)}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Total_volumetric_weight}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_Start_Date}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Delivery_End_Date ? <>{entry.Delivery_End_Date}</> : <>---</>}</td>
                    
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            
        </>
    }
    </div>
  );
}

export default RequestData;
