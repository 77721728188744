import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import InsertRequestModal from '../../components/InsertComponents.js/InsertRequestModal';

function InsertRequest() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCustomer1, setSelectedCustomer1] = useState(null);
  const [selectedCustomer2, setSelectedCustomer2] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [startLocation, setstartLocation] = useState(null);
  const [endLocation, setendLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const priorityOptions = [
    { value: 'Normal', label: 'Κανονική' },
    { value: 'High', label: 'Υψυλή' },
  ];

  const fetchCustomers = async () => {
    const { data } = await axios.get('http://195.251.111.90/api/customer/get_all_companies_individuals', {
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'velos123',
    },
    });
    return data;
};

const { data:CustomerData, isLoading, isError } = useQuery(['Customers'], fetchCustomers);

const optionsCustomer = CustomerData?.map(customer => ({
  value: customer.Customer_ID,
  label: `${customer.Type_of_Customer === 'Company' ? customer.Company_name : `${customer.First_Name} ${customer.Last_Name}`}, ${customer.Contact_Number}, ${customer.Email}`,
}));

  const queryRequest = useQuery(['requests'], () =>
    axios.get('http://195.251.111.90/api/request/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'velos123',
      },
    })
    .then((response) => response.data)
  );

  const options = queryRequest.data?.map((request) => ({
    value: request.Request_ID,
    label: `#${request.Request_ID}`,
  }));

  const fetchHubs = async () => {
    const { data } = await axios.get('http://195.251.111.90/api/hub', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'velos123',
      },
    });
    return data;
  };

  const { data:hubData } = useQuery(['hubs'], fetchHubs);

  const optionsHubs = hubData?.map(hub => ({ value: hub.Hub_ID, label: ` #${hub.Hub_ID}, ${hub.Hub_Name} , ${hub.Company_Name}`  })); 

  const optionsHubs1 = endLocation ? optionsHubs.filter(option => option.value !== endLocation.value) : optionsHubs;
  const optionsHubs2 = startLocation ? optionsHubs.filter(option => option.value !== startLocation.value) : optionsHubs;

  const handleSelect1 = selectedOption => {
    setstartLocation(selectedOption);
  };

  const handleSelect2 = selectedOption => {
    setendLocation(selectedOption);
  };

  const handleChangeCustomer1 = (option) => {
    setSelectedCustomer1(option);
  };

  const handleChangeCustomer2 = (option) => {
    setSelectedCustomer2(option);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };


  const resetForm = () => {
    setSelectedCustomer1(null);
    setSelectedCustomer2(null);
    setSelectedPriority(null);
    //setstartLocation(null);
    //setendLocation(null);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };





  const handleSubmit = (event) => {
    event.preventDefault();
    setIsModalOpen(true)
    
    
};

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center my-[9%]">
        <InsertRequestModal  
        isOpen={isModalOpen} 
        Orderer={selectedCustomer1} 
        Receiver={selectedCustomer2} 
        Priority={selectedPriority} 
        startLocation={startLocation}
        endLocation={endLocation} 
        resetForm={resetForm}  
        onClose={closeModal}/>




        <div className="flex justify-center items-center">
          <div className="rounded-2xl bg-[#4F70FF] w-1/3 text-white p-5">
            <h1 className="text-2xl text-white font-bold">Εισαγωγή νέας παραγγελίας</h1>
          </div>
        </div>

        <div className=' flex justify-center items-center mt-10 ml-[7%]'>
        <div className='w-1/3 flex justify-center items-center flex-col'>
          <div className=' flex my-5 '>
            <h1 className='text-xl font-semibold text-gray-700'>Υπάρχει ήδη η παραγγελία;</h1>
          </div>
        <div className='w-full'>
          <Select
            value={selectedOption}
            
            options={options}
            onChange={handleChange}
            noOptionsMessage={() => 'No further results found'}
            placeholder="123********"
            autoFocus
          />
        </div>
        <div>
        <p className=' font-semibold text-sm  text-start py-3'>Αναζήτησε μία παραγγελία με το αναγνωριστικό της</p>
        </div>
        </div>
        <div>
                    <img
                    src={require('../../assets/not_sure.png')}
                    alt="not sure"
                    className="w-16 h-16 m-5" // Adjust these values to resize the image
                    />
                </div>
        </div>


        <form onSubmit={handleSubmit} >

      <div className=' flex flex-row w-1/4 justify-between items-center align-middle  my-10 mt-16'>
          
          <div className=' flex '>
              <h1 className='text-2xl text-gray-700 font-semibold'>Νέα Παραγγελία</h1>
          </div>
          <div className=' mr-14'>
              <img
              src={require('../../assets/add-cust.png')}
              alt="not sure"
              className="w-16 h-16 m-5" // Adjust these values to resize the image
              />
          </div>
      </div>
    <div className='flex flex-row justify-start items-center my-4'>
      <div>
        <img
            src={require('../../assets/new-request1.png')}
            alt="not sure"
            className="w-16 h-16 m-5" // Adjust these values to resize the image
            />
      </div>
        <div className=' flex mt-3'>
          <h1 className=' text-xl font-semibold text-gray-700'>Λεπτομέρειες παραγγελίας</h1>
        </div>
        </div>



      <div className=' flex flex-row justify-evenly'>
      <div className='w-1/3'>
        <h1 className=' text-xl font-semibold text-gray-700 p-2'>Αποστολέας</h1>
        <h1>
          Επιλέξτε τον πελάτη που <b>κάνει</b> την παραγγελία
        </h1>
          <div className='  items-center '>
                    
          <Select
              id="customer1"
              options={optionsCustomer}
              value={selectedCustomer1}
              onChange={handleChangeCustomer1}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
                  boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
                  backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
                }),
              }}
            />
                    <p className=' font-semibold text-sm  text-start py-3'>Πληκτρολογήστε τον αριθμό επικοινωνίας ή το Email του πελάτη για να τον αναζητήσετε!</p>
            </div>
        </div>
        
        <div className='w-1/3'>
        <h1 className=' text-xl font-semibold text-gray-700 p-2'>Παραλήπτης</h1>
        <h1>
          Επιλέξτε τον πελάτη που <b>λαμβάνει</b> την παραγγελία 
        </h1>
          <div className='  items-center'>
                    
          <Select
              id="customer2"
              options={optionsCustomer}
              value={selectedCustomer2}
              onChange={handleChangeCustomer2}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
                  boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
                  backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
                }),
              }}
            />
                    <p className=' font-semibold text-sm  text-start py-3'>Πληκτρολογήστε τον αριθμό επικοινωνίας ή το Email του πελάτη για να τον αναζητήσετε!</p>
            </div>
        </div>
      </div>
        <div className=' flex justify-center '>
              <div className=' flex flex-col mt-5 w-[20%]'>
          <h1 className=' text-xl font-semibold text-gray-700 mr-5'>Προτεραιότητα παραγγελίας</h1>
          <Select
          value={selectedPriority}
          options={priorityOptions}
          onChange={setSelectedPriority}
          styles={{
            control: (base, state) => ({
              ...base,
              borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
              boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
              backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
            }),
          }}
        />
        </div>
        </div>
        <div className='flex flex-row justify-evenly items-center mt-[5%]'>
          <div className=" w-1/3">
            <label htmlFor="hub1" className="block text-base font-medium text-gray-700">Κέντρο <b>παραλαβής</b> παραγγελίας</label>
            <Select
              id="hub1"
              value={startLocation}
              onChange={handleSelect1}
              options={optionsHubs1}
              className="mt-1"
              isSearchable
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
                  boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
                  backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
                }),
              }}
            />
          </div>
          
          <div className=" w-1/3 align-middle">
            <label htmlFor="hub2" className="block text-base font-medium text-gray-700">Κέντρο <b>παράδοσης</b> παραγγελίας</label>
            <Select
              id="hub2"
              value={endLocation}
              onChange={handleSelect2}
              options={optionsHubs2}
              className="mt-1"
              isSearchable
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
                  boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
                  backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
                }),
              }}
            />
          </div>
        </div>


    <button type="submit" className="bg-[#0063BE] rounded-2xl text-white p-5 m-10 font-semibold">Υποβολή παραγγελίας και εισαγωγή περιεχομένων! </button>
    </form>


      </div>
    </div>
  );
}

export default InsertRequest;
