import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const HubName = ({ id }) => {
    const fetchHubName = async () => {
        const response = await axios.get(`http://195.251.111.90/api/hub/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        
        return response.data;
    };

    const { data: hubName, isLoading } = useQuery(['hubName', id], fetchHubName);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return <span>{hubName[0]?.Hub_Name}</span>;
};

export default HubName;
