export  function InTransit(){
    return <img
    src={require('../../assets/truck.png')}
    alt="In transit"
    className=" m-auto w-12 h-12" // Adjust these values to resize the image
  />
}

export  function LastMile(){
    return <img
    src={require('../../assets/done.png')}
    alt="Last mile"
    className=" m-auto " // Adjust these values to resize the image
  />
}

export  function ShippingStart(){
  return <img
  src={require('../../assets/shipping-start2.png')}
  alt="Shipping start"
  className=" m-auto w-12 h-12" // Adjust these values to resize the image
/>
}