import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/homePage';
import Login from './pages/loginPage';
import { AuthProvider, useAuth } from './utils/AuthContext';
import Navbar from './components/navBar';
import Graph from './pages/GraphPages/graphPage';
import Request_List from './pages/requestPage';
import AllData from './pages/AllDataPages/allDataPage';
import Statistics from './pages/statisticsPage';
import ProtectedRoute from './components/protectedRoute';
import ItemDetails from './pages/ItemDetailsPage';
import AlgorithmExecute from './pages/algorithmExecutePage';
import AlgorithmExecuteDetails from './pages/algorithmExecuteDetailsPage';
import AlgorithmExecuteDetailsShipment from './pages/algorithmExecuteShipmentDetailsPage';
import GraphHub from './pages/GraphPages/graphHubPage';
import GraphConnections from './pages/GraphPages/graphConnectionsPage';
import GraphShipment from './pages/GraphPages/graphShipmentPage';
import RequestLastMile from './pages/GraphPages/graphRequestLastMilePage';
import GraphItemHub from './pages/GraphPages/graphItemsHubPage';
import InsertHub from './pages/InsertPages/insertHubPage';
import InsertConnection from './pages/InsertPages/insertConnectionPage';
import InsertCustomer from './pages/InsertPages/InsertCustomerPage';
import InsertRequest from './pages/InsertPages/InsertRequestPage';
import InsertItem from './pages/InsertPages/insertItemPage';
import CalendarDate from './pages/CalendarDatePage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function AppContent() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="container mx-auto">
      <Routes>
        <Route path="/" element={!isLoggedIn ? <Navigate to="/login" /> : <MainContent />} />
        <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/" />} />
        <Route path="/graph" element={isLoggedIn ? <ProtectedRoute><Graph/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/graph/hub_details/:hub" element={isLoggedIn ? <ProtectedRoute><GraphHub/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/graph/hub_details/:hub/hub_connections" element={isLoggedIn ? <ProtectedRoute><GraphConnections/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/graph/hub_details/:hub/hub_shipments" element={isLoggedIn ? <ProtectedRoute><GraphShipment/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/graph/hub_details/:hub/items_in_hub" element={isLoggedIn ? <ProtectedRoute><GraphItemHub/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/graph/hub_details/:hub/request_last_mile" element={isLoggedIn ? <ProtectedRoute><RequestLastMile/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/request_list" element={isLoggedIn ? <ProtectedRoute><Request_List/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/all_data" element={isLoggedIn ? <ProtectedRoute><AllData/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/statistics" element={isLoggedIn ? <ProtectedRoute><Statistics/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/item_details/:entry" element={isLoggedIn ? <ProtectedRoute><ItemDetails/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/hub_insertion" element={isLoggedIn ? <ProtectedRoute><InsertHub/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/connection_insertion" element={isLoggedIn ? <ProtectedRoute><InsertConnection/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/request_insertion" element={isLoggedIn ? <ProtectedRoute><InsertRequest/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/item_insertion/request/:insertId/start/:startLocation/end/:endLocation" element={isLoggedIn ? <ProtectedRoute><InsertItem/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/customer_insertion" element={isLoggedIn ? <ProtectedRoute><InsertCustomer/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/algorithm_execution" element={isLoggedIn ? <ProtectedRoute><AlgorithmExecute/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/algorithm_execution/details" element={isLoggedIn ? <ProtectedRoute><AlgorithmExecuteDetails/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/algorithm_execution/details/shipment/:shipment" element={isLoggedIn ? <ProtectedRoute><AlgorithmExecuteDetailsShipment/></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/calendar_date/:date" element={isLoggedIn ? <ProtectedRoute><CalendarDate/></ProtectedRoute> : <Navigate to="/login" />} />
      </Routes>
    </div>
  );
}
function MainContent() {
  return (
    <>
      <Navbar />
      <Home />
      
    </>
  );
}

export default App;
