import MapComponent from "../../components/graphPageComponents/MapComponent";
import { useAuth } from '../../utils/AuthContext';


function Graph() {
  const { userInfo } = useAuth();

  return (
    <div className="min-h-screen flex items-center justify-center py-8 pt-[8%] m-0 p-0">
      <div className="w-full bg-white rounded-lg shadow-lg border-2 border-green-800 text-center">
        <div className="flex justify-center">
          <div className="w-2/6 border-2 rounded-md border-blue-700 hover:bg-[#3054f7] cursor-auto text-center bg-[#4F70FF] text-white m-2">
            <h1>Λεπτομέρειες για το δίκτυο διανομών της εταιρείας:</h1>
            <h1 className="font-bold">{userInfo.Company_Assigned}</h1>
          </div>
        </div>
        <MapComponent />
      </div>
    </div>
  );
}

export default Graph;