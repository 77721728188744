import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import ItemModal from './ItemModal';

const ItemDetailsShipment = ({ Item_ID, Hub_ID, Shipment_ID, Quantity, Total_Weight, status }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [confirmedLoading, setConfirmedLoading] = useState(false);
    const [error, setError] = useState(false);
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const fetchItemDetailsShipment = async () => {
        const response = await axios.get(`http://195.251.111.90/api/item/` + Item_ID, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });

        return response.data;
    };

    

    const { data: ItemDetailsShipment, isLoading, error:FetchError } = useQuery(['ItemDetailsShipment', Item_ID], fetchItemDetailsShipment);

    const updateItemStatusMutation = useMutation((itemToUpdate) => axios.put(`http://195.251.111.90/api/tracking/hub/${Hub_ID}/item/${Item_ID}/shipment/${Shipment_ID}/status`, itemToUpdate, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchShipments', Shipment_ID]);
            queryClient.invalidateQueries(['ShipmentContents', Shipment_ID, Quantity, Total_Weight]);
        }
    });

    if (isLoading) {
        return <p>Loading...</p>;
    }

    const handleModalClose = () => {
        setIsOpenModal(false);
        setIsHovered(false)
    }

    const handleModalConfirm = async () => {
        setConfirmedLoading(true);

        try {
            updateItemStatusMutation.mutate({ Item_Inclusion_Status: 'Rejected' });

            var newQuantity = Quantity - 1;
            var newWeight = Total_Weight - Number(ItemDetailsShipment[0].Volumetric_Weight);

            await axios.put(`http://195.251.111.90/api/shipment/${Shipment_ID}/delete_item`, {
                "Quantity": newQuantity,
                "Total_Weight": newWeight
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });

            

            await axios.put(`http://195.251.111.90/api/item/${Item_ID}/next_hub`, {
                "Next_hub_id": null,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });

            const itemRequestResponse = await axios.get(`http://195.251.111.90/api/item/${Item_ID}`,  
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });
            //console.log(itemRequestResponse.data[0].Request_ID)
            const Request_ID = itemRequestResponse.data[0].Request_ID;

            const reqResponse =   await axios.put(`http://195.251.111.90/api/request/${Request_ID}/status`, {
                "Status_Request": "In_transit"
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "velos123",
                },
            });

            

            
            setConfirmedLoading(false);
            setIsOpenModal(false);
            setIsHovered(false);
        } catch (error) {
            
            setError(true)
        }
    }

    if (error || FetchError){
        <div>
            Παρουσιάστηκε ένα σφάλμα, Παρακαλώ επαναφορτώστε την σελίδα. Αν το πρόβλημα παραμείνει παρακαλώ ελέγξετε την σύνδεση σας στο διαδίκτυο 
        </div>
    }

    return (
        <div className={`flex my-6 `} 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        
        >
        <div  className={`  border-2 rounded-xl border-[#0063BE] flex ${status === 'Rejected' ? ' bg-red-400 cursor-not-allowed ' : 'cursor-pointer hover:bg-[#0063BE]  hover:text-white hover:border-green-800'}    items-center`}
        
        >
            
            <div className='     border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' > 
            ID Τεμαχίου  
            <p className='  break-words' >{ItemDetailsShipment[0].Item_ID}</p>
            </div>
            <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                ID Παραγγελίας
                <p className='  break-words'>{ItemDetailsShipment[0].Request_ID}</p>
                </div>
            <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                Τύπος τεμαχίου
                <p className='  break-words' >{ItemDetailsShipment[0].Item_Type === "Pallet" ? <span>Παλέτα</span> : <span>Πακέτο</span> }</p>
                </div>
            <div className='  p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                Ογκομετρικό βάρος τεμαχίου
                <p className='  break-words' >{ItemDetailsShipment[0].Volumetric_Weight}</p>
                </div>
            
            
            

            
            </div>
            <div className='w-20 flex items-center justify-center '>
            {isHovered && status !== "Rejected" && (
            <div className=' flex '
            onClick={()=>{setIsOpenModal(true)}}
            >
                <img
                src={require('../../assets/delete.png')}
                alt="Shipping start"
                className="  w-8 h-8 hover:opacity-80 hover:shadow-lg" // Adjust these values to resize the image
              />
              </div>
            )}
            </div>

            {isOpenModal && (
        <ItemModal
            isOpen={isOpenModal}
            title="Επιβεβαίωση διαγραφής"
            message={`Είστε σίγουρος για την διαγραφή του τεμαχίου ${Item_ID} από την μεταφορά ${Shipment_ID}? `}
            messagefooter = {"Η εν λόγω πράξη είναι μη αναστρέψιμη."}
            onClose={handleModalClose}
            onConfirm={handleModalConfirm}
        />
    )}
            
            </div>
    )
};

export default ItemDetailsShipment;
