import HashLoader from "react-spinners/HashLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function SpinnerComponent() {
  let loading =true
  let color = "#FC4C02"

  return (
    <div className="sweet-loading">
      

      <HashLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier= {1.5}
      />
    </div>
  );
}

export default SpinnerComponent;