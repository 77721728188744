import React, { useState, useEffect } from 'react';
import { BiMenuAltLeft } from 'react-icons/bi';
import { CustomToolTip } from './toolTipComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserInfo } from './userInfoComponent';
import SideMenu from './sideMenu';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);

  useEffect(() => {
    setIsMenuOpen(location.pathname === '/');
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      
      // If the current scroll position is more than 80, hide the navbar
      if (currentScrollPos > 80) {
        setIsNavbarHidden(true);
      } else {
        setIsNavbarHidden(false);
      }
    };
    
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    
    // Cleanup function to remove the listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);  // Empty array means this effect runs once on mount and cleanup on unmount
  

  return (
    <nav className={`fixed top-0 left-0 right-0 h-32 bg-[#FCFDFD] bg-opacity-75 border-b-2 border-[#05683A] flex items-center justify-between z-50 transition-all duration-300 ${isNavbarHidden ? 'transform -translate-y-full' : ''}`}>

      <div className="relative">
        
        <BiMenuAltLeft
          size={60}
          color="#FC4C02"
          className="align-middle self-center mx-14 cursor-pointer"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        <SideMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      </div>

      <CustomToolTip name="Επιστροφή στο αρχικό μενού">
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            src={require('../assets/belos-navbar.png')}
            alt="Belos Logo"
            className="mx-auto px-4 w-auto h-auto"
          />
        </button>
      </CustomToolTip>

      <UserInfo>
        <img
          src={require('../assets/profile.png')}
          alt="Belos Logo"
          className="mx-11"
        />
      </UserInfo>
    </nav>
  );
};

export default Navbar;
