import React,{ useState } from 'react';
import axios from 'axios';
import sha1 from 'crypto-js/sha1';
import { AlertComponent } from '../components/alertComponent';
import { toast } from 'react-toastify';
import {BiShowAlt, BiHide} from 'react-icons/bi'
import SpinnerComponent from '../components/spinnerComponent';
import { useNavigate } from 'react-router-dom';
//import axiosCookieJarSupport from 'axios-cookiejar-support';
import { useAuth } from '../utils/AuthContext';
import Cookies from 'js-cookie'; 

function Login() {  
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [showErrorAlert, setShowErrorAlert] = useState(false)

const [showPassword, setShowPassword] = useState(false);
const[isLoading, setIsLoading] = useState(false)

const handleUsernameChange = (event) => {
    setUsername(event.target.value);
};

const navigate = useNavigate();
const { setIsLoggedIn, setUserId, setUserInfo, fetchCompanyNodes } = useAuth();

const handlePasswordChange = (event) => {
    setPassword(event.target.value);
};


const handleLogin = async (event) => {
  setIsLoading(true);
  event.preventDefault(); // prevent default form submission behavior
  try {
    const response = await axios.post(
      'http://195.251.111.90/api/employee/login',
      {
        "Username": username,
        "User_password": password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'velos123',
        },
        //jar: cookieJar, // Attach the cookie jar to the request
        //withCredentials: true,
      }
    );

    const hashedPassword = sha1(password).toString();
    const userData = response.data[0];
    //console.log(hashedPassword)
    handleResponse(userData, hashedPassword);
    

  } catch (error) {
      console.log(error);
      setIsLoading(false);
  }
};
const handleResponse = (userData, hashedPassword) => {
  if (userData && userData.Username === username && userData.User_password === hashedPassword) {
    //console.log("Success");
    
    setIsLoggedIn(true);
    setUserId(userData.User_ID);
    
    // Set the user's information without the password
    const userInfoWithoutPassword = { ...userData };
    delete userInfoWithoutPassword.User_password;
    setUserInfo(userInfoWithoutPassword);
    
    // Store the user ID and userInfo in cookies
    Cookies.set('userId', userData.User_ID);
    Cookies.set('userInfo', JSON.stringify(userInfoWithoutPassword));
    //console.log(userData.User_ID)
    sessionStorage.setItem('showWelcomeAlert', 'true');
    //store the list of nodes that this company has 
    fetchCompanyNodes(userInfoWithoutPassword.Company_Assigned);
    

    navigate('/');

  } else {
    console.log("failed")
    setShowErrorAlert(true);
    notifyError();
  }
  setIsLoading(false);
};
// const notifyWarn = () => toast.warn('Το χρονικό όριο έληξε! Για λόγος ασφαλείας παρακαλώ συνδεθείτε ξανά', {
//   position: "top-right",
//   autoClose: 5000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
//   theme: "light",
//   });




  const notifyError = () => toast.error('Παρακαλώ προσπαθήστε ξανα!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

    // useEffect(() => {
    //   if (!isLoggedIn && intervalTimeout && !timeoutAlertShown) {
    //     setTimeoutAlertShown(true);
    //     notifyWarn();
    //   }
    // }, [isLoggedIn, intervalTimeout, timeoutAlertShown, notifyWarn]);
    
return (
    <div className="min-h-screen flex items-center justify-center">
      
<div className={`max-w-md w-auto space-y-4 py-8 px-6 bg-white rounded-lg shadow-md border-2 ${isLoading ? 'opacity-50' : ''}`}>
{isLoading && (
  <div className="fixed inset-0 z-50 flex justify-center items-center">
    <SpinnerComponent className='absolute max-w-[30%] max-h-[30%]' />
  </div>
)}

        <div>
            <img src={require('../assets/Belos-img.png')} alt="Login Page" className="mx-auto py-4 px-4" />
            <h2 className="text-center text-lg font-bold text-gray-800">Συνδεθείτε στο ΒΕΛΟΣ</h2>
            <form className="mt-6 space-y-4" onSubmit={handleLogin}>
                <div className="relative">
                    <label htmlFor="username" className={`text-gray-700 font-bold absolute pointer-events-none transition-all duration-100 ${
                        username.length > 0 ? 'text-sm -top-5 left-2' : 'text-base top-2 left-4'
                    } ${username.length > 0 && 'text-blue-500'}`}>Username</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        value={username}
                        onChange={handleUsernameChange}
                        className="mt-1 block w-full border-gray-300 border-2 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2"
                        required
                    />
                </div>
                <div className="relative flex-column " >
                    <label htmlFor="password" className={`text-gray-700 font-bold absolute pointer-events-none transition-all duration-100 ${
                        password.length > 0 ? 'text-sm -top-5 left-2' : 'text-base top-2 left-4'
                    } ${password.length > 0 && 'text-blue-500'}`}>Password</label>
                    <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                            }
                        }}
                        className="mt-1 block w-full border-gray-300 border-2 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 px-3.5 py-2"
                        required
                    />
                    <button
                        type='button'
                        className="absolute right-0 top-0 bottom-0 px-3.5 py-2"
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <BiHide style={{color:"#4F70FF", fontSize: '18px'}}/> : <BiShowAlt style={{color:"#4F70FF", fontSize: '18px'}}/>}
                    </button>
                </div>
                <div>
                    <button
                        type="submit"
                        //onClick={handleLogin}
                        className="w-full py-3 px-4 mt-6 text-white font-bold bg-[#4F70FF] rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    >
                        Σύνδεση
                    </button>
                </div>
            </form>
        </div>
    
</div>
    
    {showErrorAlert && <AlertComponent/> }
    
    </div>
);
}

export default Login;
