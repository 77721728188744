import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import SpinnerComponent from '../../components/spinnerComponent';
import axios from 'axios';
import { BsFillArrowDownCircleFill, BsFillInfoCircleFill} from "react-icons/bs";
import HubName from '../../components/HubNameComponent';
import { Dangerous, Dimentions, Features, Fragile, NoItems, Request, Vulnerable } from '../../components/photoComponents/isNotCompanyIcons';
import  Timeline  from '../../components/graphPageComponents/Timeline';


function GraphItemHub() {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entryParam = pathParts[pathParts.length - 2];
    const hub_id = JSON.parse(decodeURIComponent(entryParam));
    const [openDropdown, setOpenDropdown] = useState(''); // use state to control which dropdown is open
    const [hoveredRow, setHoveredRow] = useState(null);
    const [details, setDetails] = useState(null)
    const [item, setItem] = useState({})

    const { data: hubData, isLoading, error } = useQuery(['hubData'], () =>
    axios.get(`http://195.251.111.90/api/item/get_current_hub_items/${hub_id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        }
    }).then(res => res.data)
);

    if (isLoading) return <div className=' h-[70vh] min-h-screen flex items-center justify-center m-auto' ><SpinnerComponent/> </div>;

    // You can also check if there was an error
    if (error) return <div className=' h-[70vh] min-h-screen flex items-center justify-center m-auto'>An error has occurred:   {error.message} </div>

    // filter data into two separate lists
    const beingProcessedItems = hubData.filter(item => item.Status_Request === 'Being_processed');
    const inTransitItems = hubData.filter(item => item.Status_Request === 'In_transit');

    

    return (
      <div className="min-h-screen flex items-center justify-center">
          <div className=" w-full  bg-white rounded-lg shadow-lg p-8 border-2 border-green-800  text-center my-[9%]">
              <div className='flex justify-center items-center'>
              <div className='border-2 rounded-xl border-[#0063BE] mb-5 p-3 w-2/3 '>
              <span className="text-3xl font-semibold text-gray-800">Τεμάχια στον κόμβο  </span>
              <span className='text-3xl font-bold text-gray-800'><HubName id= {hub_id}/></span>
              </div>
              </div>
              <div className=' flex flex-row justify-around'>
              <div className='w-[40%] overflow-y-auto  overflow-x-hidden h-[113vh]
              scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600'>


              <div className="my-4 w-[98%]  ">

                <div className=' border-2 border-[#0063BE] flex justify-center items-center p-3 rounded-xl hover:bg-[#0063BE] text-gray-700  hover:text-white  ' 
                onClick={() => setOpenDropdown(openDropdown === 'processed' ? '' : 'processed')}>
                  <h2 className="text-2xl font-bold  mx-5" >Τεμάχια υπό επεξεργασία </h2>
                  <BsFillArrowDownCircleFill
                    className={`transition duration-500 ease-in-out transform ${
                      openDropdown === 'processed' ? "rotate-180" : ""
                    } text-[#FC4C02] text-2xl`}
                  />
                  </div>
                  {openDropdown === 'processed' && (
                      <div className="mt-4 transition-all duration-300 ease-in-out overflow-hidden">
                          {beingProcessedItems.map(item => (
                              <div className='items-center justify-center flex-row flex cursor-pointer '
                              onMouseEnter={() => setHoveredRow(item.Item_ID)}
                              onMouseLeave={() => setHoveredRow(null)}
                              onClick={() => {setDetails(item.Item_ID) ; setItem(item) }}
                          >
                              <div key={item.Item_ID} className={`flex-row flex items-end justify-center rounded-lg m-2 border-2 ${details === item.Item_ID ? 'bg-[#0063BE] text-white border-orange-600' : ''} border-[#0063BE] hover:bg-[#0063BE]  hover:text-white`}>
                                  <div className='p-1 border-r-2 border-[#0063BE]'>Tεμάχιο <p className=' font-semibold'>#{item.Item_ID}</p></div>
                                  <div className='p-1 border-r-2 border-[#0063BE]'>Παραγγελία <p className=' font-semibold'>#{item.Request_ID}</p></div>
                                  <div className='p-1 border-r-2 border-[#0063BE] w-44'>Αρχικός προορισμός <p className=' font-semibold'><HubName id={item.Current_hub_ID}/></p></div>
                                  <div className='p-1 border-[#0063BE] w-44'>Τελικός προορισμός <p className=' font-semibold'><HubName id={item.Delivery_hub_ID}/></p></div>
                              </div>
                              <div className='w-44'>
                              {hoveredRow === item.Item_ID  && details !== item.Item_ID && (
                                  <BsFillInfoCircleFill
                                      className=" cursor-pointer"
                                      size={25}
                                      color="#FC4C02"
                                  />
                              )}
                              </div>
                          </div>
                          ))}
                      </div>
                  )}
              </div>
{/*                                                 */}


              <div className="my-4   w-[98%] ">

                <div className=' border-2 border-[#0063BE] flex justify-center items-center p-3 rounded-xl hover:bg-[#0063BE] text-gray-700  hover:text-white ' 
                onClick={() => setOpenDropdown(openDropdown === 'transit' ? '' : 'transit')}>
                  <h2 className="text-2xl font-bold   mx-5" >Τεμάχια από τρίτους </h2>
                  <BsFillArrowDownCircleFill
                    className={`transition duration-500 ease-in-out transform ${
                      openDropdown === 'transit' ? "rotate-180" : ""
                    } text-[#FC4C02] text-2xl`}
                  />
                  </div>
                  {openDropdown === 'transit' && (
                      <div className="mt-4 transition-all duration-300 ease-in-out overflow-hidden">
                          {inTransitItems.map(item => (
                              <div className='items-center justify-center flex-row flex cursor-pointer '
                              onMouseEnter={() => setHoveredRow(item.Item_ID)}
                              onMouseLeave={() => setHoveredRow(null)}
                              onClick={() => {setDetails(item.Item_ID) ; setItem(item) }}
                          >
                              <div key={item.Item_ID} className={`flex-row flex items-end justify-center rounded-lg m-2 border-2 ${details === item.Item_ID ? 'bg-[#0063BE] text-white border-orange-600' : ''} border-[#0063BE] hover:bg-[#0063BE]  hover:text-white`}>
                                  <div className='p-1 border-r-2 border-[#0063BE]'>Tεμάχιο <p className=' font-semibold'>#{item.Item_ID}</p></div>
                                  <div className='p-1 border-r-2 border-[#0063BE]'>Παραγγελία <p className=' font-semibold'>#{item.Request_ID}</p></div>
                                  <div className='p-1 border-r-2 border-[#0063BE] w-44'>Αρχικός προορισμός <p className=' font-semibold'><HubName id={item.Order_hub_ID}/></p></div>
                                  
                                  <div className='p-1 border-[#0063BE] w-44'>Τελικός προορισμός <p className=' font-semibold'><HubName id={item.Delivery_hub_ID}/></p></div>
                              </div>
                              <div className='w-44'>
                              {hoveredRow === item.Item_ID  && details !== item.Item_ID && (
                                  <BsFillInfoCircleFill
                                      className=" cursor-pointer"
                                      size={25}
                                      color="#FC4C02"
                                  />
                              )}
                              </div>
                          </div>
                          ))}
                      </div>
                  )}
              </div>
              </div>
              <div className='m-3 w-[80vh] border-2 border-[#0063BE] bg-white rounded-lg shadow-lg   text-center '>

        

{details && 
<div>

    <div>
        
        <h1 className=' p-3 text-xl text-gray-800 font-normal border-b-2 border-[#0063BE] mb-4'>Λεπτομέρεις για {item.Item_Type ==="Pallet" ? <>το πακέτο</>: <>την παλέτα </>} <span  className=' font-semibold'> #{item.Item_ID}</span></h1>
    </div>

<div className='flex-row flex justify-between border-b-2 border-[#0063BE] pb-4'>
    <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
        <Dimentions/>
        <div >
        <h1 className='pb-1 font-medium'>Διαστάσεις {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>
        </div>
        <h2 className='pb-1'>Μήκος: <span className=' font-semibold'> {item.Item_Width} cm </span></h2>
        <h2 className='pb-1'>Πλάτος: <span className=' font-semibold'>{item.Item_Length} cm </span></h2>
        <h2 className='pb-1'>Ύψος: <span className=' font-semibold'>{item.Item_Height} cm </span></h2>
        <h2 className='pb-1'>Βάρος: <span className=' font-semibold'>{item.Item_Weight} kg </span></h2>
        {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
    </div>

    <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto   w-[30%] min-w-[230px] min-h-[230px]'> 
        <Features/>
        <h1 className='pb-1 font-medium' >Χαρακτηριστικά  {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>

        <div className='flex-row flex justify-center items-center my-1'>
        <Dangerous/><h2 className='ml-2'  > Επικίνδυνο: {item.Dangerous === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
        </div>

        <div className='flex-row flex justify-center items-center my-1'>
        <Fragile/> <h2 className='ml-2'  > Εύθραυστο: {item.Fragile === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
        </div>
        <div className='flex-row flex justify-center items-center my-1 '>
        <Vulnerable/> <h2 className='mx-[5px] w-[50%]'  > Ευάλωτο:    <span>{item.Vulnerable === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white '>   Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>   Όχι</span>}</span>  </h2>
        </div>
        
    </div>

    <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
        <Request/>
        <div >
        <h1 className='pb-1 font-medium'> Μέρος της Παραγγελίας <p>#{item.Request_ID} </p></h1>
        </div>
        <h2 className='pb-1'>Αρχικός κόμβος:  <p className='font-medium'><HubName id={item.Order_hub_ID}/> </p></h2>
        <h2 className='pb-1'>Τελικός κόμβος:  <p className='font-medium'><HubName id={item.Delivery_hub_ID}/> </p></h2>
        
        {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
    </div>

</div>



    
  {item.Status_Request !== "Being_processed" ?
<>
<div>
        <h1 className=' text-xl text-gray-800 font-normal my-3   pb-2' >Χρονολόγιο {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} <span  className=' font-semibold'> #{item.Item_ID}</span></h1>
    </div>
  <Timeline itemId={details} />
  </>
  
  
  : <div></div>}

</div>
}

</div>
</div>
          </div>
      </div>
  );
}

export default GraphItemHub;
