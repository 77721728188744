import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../spinnerComponent';
import HubName from './../HubNameComponent';

function ItemData() {
  const fetchItems = async () => {
    const response = await axios.get('http://195.251.111.90/api/item', 
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
        },
    });
    return response.data;
  };

  const { isLoading, error, data } = useQuery(['items'], fetchItems);

 //console.log(data[0])
  if (error) return 'An error has occurred: ' + error.message;
  return (
    <div className=" flex flex-col items-center justify-center border-2 border-[#0063BE] rounded-lg bg-white min-w-full min-h-full   ">
        {isLoading ? <SpinnerComponent/> :
        <>
        <div className=' p-5'>
        <h1 className=' text-2xl font-semibold text-slate-800'> Τεμάχια</h1>
        </div>
     
       <>
       <div className=' overflow-auto h-[100vh]   '>
            <table className="table-fixed ">
                <thead className="sticky -top-1 bg-[#0063BE] text-white">
                <tr>
                    <th className="p-3 ">ID Πακέτου/Παλέτας</th>
                    <th className="p-3 ">ID Παραγγελίας</th>
                    <th className="p-3 ">Τύπος τεμαχίου</th>
                    <th className="p-3 ">Ύψος</th>
                    <th className="p-3 ">Μήκος</th>
                    <th className="p-3 ">Πλάτος</th>
                    <th className="p-3 ">Βάρος </th>
                    <th className="p-3 ">Είναι Ευάλωτο;</th>
                    <th className="p-3 ">Είναι Επικίνδυνο;</th>
                    <th className="p-3 ">Είναι Εύφραυστο;</th>
                    <th className="p-3 ">Εναρκτήριος κόμβος </th>
                    <th className="p-3 ">Τρέχον κόμβος</th>
                    <th className="p-3 ">Επόμενος κόμβος</th>
                    <th className="p-3 ">Τελικός κόμβος</th>
                    <th className="p-3 ">Ογκομετρικό βάρος </th>
                </tr>
                </thead>
                <tbody>
                {data?.map((entry, index) => (
                    <tr
                    className="group cursor-auto hover:bg-blue-200 "
                    key={index}
                   
                    >
                    <td className="border border-[#0063BE] p-1">{entry.Item_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Request_ID}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Type}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Height}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Length}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Width}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Item_Weight}</td>
                    
                    <td className="border border-[#0063BE] p-1">{entry.Vulnerable === 1 ? <>Ναι</> : <>Όχι</>}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Dangerous=== 1 ? <>Ναι</> : <>Όχι</>}</td>
                    <td className="border border-[#0063BE] p-1">{entry.Fragile === 1 ? <>Ναι</> : <>Όχι</>}</td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Order_hub_ID}/></td>
                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Current_hub_ID}/></td>

                    <td className="border border-[#0063BE] p-1">
                        {entry.Next_hub_id ?<HubName id={entry.Next_hub_id}/> : <>---</>}
                        
                        </td>

                    <td className="border border-[#0063BE] p-1"><HubName id={entry.Delivery_hub_ID}/></td>
                    <td className="border border-[#0063BE] p-1">{entry.Volumetric_Weight}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            
        </>
        </>
    }
    
    </div>
  );
}

export default ItemData;
