import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { InTransit, LastMile, ShippingStart } from '../photoComponents/TimelIneIcons';
import HubName from '../HubNameComponent';
import SpinnerComponent from '../spinnerComponent';
import RequestDate from '../RequestDateComponent';

async function fetchItemDetails(itemId) {
    const response = await axios.get("http://195.251.111.90/api/tracking/item/"+itemId, {  
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return response.data;
}



function Timeline({itemId}) {
    

    const { data: rawData, isLoading } = useQuery(['itemDetails', itemId], () => fetchItemDetails(itemId));


    const sortedData = rawData?.sort((a, b) => {
        const aDateParts = a.Date_Made.split(' ');
        const aDate = new Date(aDateParts[0].split('/').reverse().join('-') + 'T' + aDateParts[1]);
        const bDateParts = b.Date_Made.split(' ');
        const bDate = new Date(bDateParts[0].split('/').reverse().join('-') + 'T' + bDateParts[1]);
        return aDate - bDate;
    });

    const routeSet = new Set(sortedData?.map(step => step.Hub_ID));
    let route = Array.from(routeSet);

    // Check if sortedData is not empty or undefined
    if(sortedData && sortedData.length > 0) {
      // Add Order_Hub_ID of the first item to the start of the route array
      route.unshift(sortedData[0]?.Order_Hub_ID);}


      console.log(sortedData)

    

    return (
      <div className=" justify-center items-center flex">
        
        <div className=" ">
        {isLoading ? <div className='m-auto'> <SpinnerComponent/> </div>:
            
        
    <>
      {sortedData && (
        <div className="flex">
        <div className="    h-full">
          <div className="relative flex flex-col my-10 ">
            <div className="absolute h-[90%] border-l-2 border-[#FC4C02] left-1/2 transform -translate-x-1/2 mt-5 " />
  
            {/* Add this section for the start circle */}
            <div className="flex items-center relative justify-between ">
              <div className="h-16 w-16 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 flex items-center justify-center">
                <div className="w-14 h-14 flex items-center justify-center rounded-full border-[#FC4C02] bg-[#FC4C02] border-2 mb-6">
                  {/* Assuming you have a component or icon for Start */}
                  <ShippingStart/>
                </div>
              </div>
              <div className="w-1/2 rounded-lg shadow-lg p-2 border-2 border-[#0063BE] mr-9 mb-6">
                <h3 className="text-black font-semibold text-center">Έναρξη παραγγελίας</h3>
                <p className="text-center">Κόμβος έναρξης: <HubName id={sortedData[0]?.Order_Hub_ID}/></p>
              </div>

              <div className="w-1/2 flex items-center ">
                          <p className={`text-center mx-3 text-white font-medium bg-[#0063BE] rounded-xl mr-[50%] mb-6 `}>
                              
                              <RequestDate Item_ID={sortedData[0]?.Item_ID}/>
                              {/* Εδω π΄ρεπει να μπει το request start data */}
                          </p>
                        </div>
            </div>
              {sortedData.map((entry, index) => {
                // split date and time
                
                    return (
                      <>
                      {entry.Item_Inclusion_Status !== "Rejected" ?
                      <div className={`flex items-center relative justify-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`} key={index}>
                        <div className="h-16 w-16 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 flex items-center justify-center ">
                          <div className={`w-14 h-14 flex items-center justify-center rounded-full mb-6 ${entry.Item_Status === "In_transit" ? 'border-red-800 bg-red-800' : 'border-green-500 bg-green-500'} border-2`}>
                            {entry.Item_Status === "In_transit" ? <InTransit /> : entry.Item_Status === "Last_mile" ? <LastMile /> : null}
                          </div>
                        </div>
                        <div className={`w-[50%] rounded-lg shadow-lg p-4 border-2 mb-6 border-[#0063BE] ${index % 2 === 0 ? 'ml-10' : 'mr-10'}`}>
                          <h3 className="text-black font-semibold text-center">Μετακίνηση:  #{index+1}</h3>
                          <p className="text-center" >Κόμβος έναρξης: <HubName id= {entry.Current_Hub_ID} />  </p>
                          <p className="text-center" >Κόμβος άφιξης: <HubName id={entry.Next_hub_id}/></p>
                          <p className="text-center" >ID Μετακίνησης: {entry.Shipment_ID}</p>
                          <p className="text-center" >Κατάσταση: {entry.Item_Status === "Last_mile" ? "Προς παράδοση": "Καθ'οδον"}</p>
                        </div>
                        <div className="w-1/2 flex items-center ">
                          <p className={`text-center mx-3 text-white font-medium bg-[#0063BE] rounded-xl mb-6 ${index % 2 === 0 ? 'ml-[50%] ' : 'mr-[50%] '}`}>
                              {entry?.Date_Made}
                            
                          </p>
                        </div>
                      </div>
                      :<div></div>}
                      </>
                  )})}
                </div>
              </div>
              {/* <div className="w-1/2">
                {route &&  <ItemRoute route={route} />}
              </div> */}
            </div>
          )}
          </>}
        </div>
      </div>
    );
  
}

export default Timeline;