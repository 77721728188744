import React, { useState } from "react";
import { BsFillArrowDownCircleFill} from "react-icons/bs";
import { Transition } from "react-transition-group";
import { useAuth } from '../../utils/AuthContext';
import {BsFillInfoCircleFill} from "react-icons/bs"
import { useNavigate} from 'react-router-dom';

const MapComponentSideMenu = ({ nodes }) => {
  const [expandedNode, setExpandedNode] = useState(null);
  const {  userInfo } = useAuth();
  const navigate = useNavigate();


  const toggleNode = (nodeId) => {
    if (expandedNode === nodeId) {
      setExpandedNode(null);
    } else {
      setExpandedNode(nodeId);
    }
  };

  const duration = 300;
  const defaultStyle = {
    transition: `max-height ${duration}ms ease-in-out`,
    maxHeight: 0,
    overflow: "hidden",
  };

  const transitionStyles = {
    entering: { maxHeight: "1000px" },
    entered: { maxHeight: "1000px" },
    exiting: { maxHeight: 0 },
  };

  return (
    <div className="flex justify-center">
      <div className="w-full h-[60vh] overflow-y-auto  scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-400">
        {nodes.map((node) => (
          <div
          key={node.Hub_ID}
          className={`border-2 rounded-md border-green-800 m-[5%] hover:bg-[#4F70FF] hover:text-white transition duration-300 ease-in-out ${
            userInfo.Company_Assigned === node.Company_Name ? "bg-green-200" : ""
          }`}
        >
            <div
            className="p-4 cursor-pointer flex justify-between items-center"
            onClick={() => toggleNode(node.Hub_ID)}
            >
            <div className="flex items-center">
                <h1 className="font-bold text-xl">#{node.Hub_ID}</h1>
                <div className="h-full w-3"></div>
                <h1 className="font-bold text-xl text-center">{node.Hub_Name}</h1>
            </div>
            <BsFillArrowDownCircleFill
                className={`transition duration-300 ease-in-out ${
                expandedNode === node.Hub_ID ? "transform rotate-180" : ""
                } text-[#FC4C02] text-2xl`}
            />
            </div>

            <Transition in={expandedNode === node.Hub_ID} timeout={duration}>
              {(state) => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  <div className="p-4 whitespace-pre-wrap ">                
                                <div className="flex pb-1 "  ><h1 className=" font-semibold pr-1 " >Πολη:</h1>{node.City}</div>
                                <div className="flex pb-1"  ><h1 className=" font-semibold pr-1" >Εταιρεία: </h1> {node.Company_Name} </div>
                                <div className="flex pb-1"  ><h1 className=" font-semibold pr-1" >Διεύθυνση:</h1> {node.Address} </div>
                                <div className="flex pb-1"  ><h1 className=" font-semibold pr-1" >Ταχυδρομικός κώδικας:</h1> {node.Postal_Code}  </div>
                                <div className="flex pb-1"  ><h1 className=" font-semibold pr-1" >Μέγιστη χωρητικότητα::</h1> {node.Max_Capacity}*  </div>
                                <div className="flex pb-1"  ><h1 className=" font-semibold pr-1" >Τρέχον απόθεμα πακέτων</h1> {node.Current_Capacity}*  </div>
                                <div
                                className=" flex flex-row cursor-pointer hover:bg-blue-300 rounded-lg justify-between w-[90%] py-3"
                                onClick={() => navigate(`/graph/hub_details/${encodeURIComponent(JSON.stringify(node.Hub_ID))}`)}>
                                  Περισσότερες πληροφορίες
                                <BsFillInfoCircleFill
                            className=" mr-3"
                            size={25}
                            color="#FC4C02"
                            />
                                
                                </div>
                <p className=" mt-1 text-left text-xs" >*Η χωρητικότητα και το απόθεμα είναι σε ογκομετρικό βάρος  </p>
                
                </div>
                </div>
              )}
            </Transition>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapComponentSideMenu;
