import React, { useRef, useState } from 'react';
import "../../index.css"
import axios from 'axios';

const InsertIndividualModal = ({ isOpen, onClose,  resetForm, Postal_Code, Contact_Number, Delivery_Address, City, Type_of_Customer, Email, Company_Name,  TIN, PFD }) => {
const modalRef = useRef();
const [status, setStatus] = useState('idle');
if (!isOpen) {
    return null;
}
const modalStyle = {
    width: 'fit-content',
    maxWidth: '90%', // To avoid the modal going off-screen
};
const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
    onClose();
    }
}

const HandleConfirm = async() => {
    try {
        const response = await axios.post('http://195.251.111.90/api/customer', {
            "Postal_Code": Postal_Code,
            "Contact_Number": Contact_Number,
            "Delivery_Address": Delivery_Address +", "+City,
            "Email": Email,
            "Type_of_Customer": Type_of_Customer
        }, 
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'velos123',
            },
        });

        const customerId = response.data.insertId;

        const secondResponse = await axios.post('http://195.251.111.90/api/company', {
            "Customer_ID": customerId,
            "Company_name": Company_Name,
            "Tax_Identification_Number": TIN,
            "Public_Finance_Department": PFD
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'velos123',
            },
        });

        setStatus('success');
        console.log(response.data, secondResponse.data);
        resetForm();
    } catch (error) {
        setStatus('error');
        console.log(error);
    }
}


const ReturnButton = () => (
    <button
      type="button"
      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-[#0063BE] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
      onClick={()=>{ onClose(); setStatus('idle')}}
    >
      Επιστροφή
    </button>
  );

let message;
if (status === 'success') {
    message = 'The data was successfully sent!';
} else if (status === 'error') {
    message = 'An error occurred while sending the data.';
}

if (status === 'idle') {
    // Display the form and the confirmation button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <> 
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                Επιβεβαίωση εισαγωγής ιδιώτη
                </h3>
                
                <div className="mt-2">
                <table className="table-fixed ">
                <thead className="sticky -top-1  text-center">
                <tr className='text-center '>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Επωνυμία εταιρείας </span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>ΑΦΜ</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>ΔΟΥ</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Διεύθυνση</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Ταχυδρομικός κώδικας</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Τηλέφωνο επικοινωνίας</span></th>
                    <th className="p-3 border border-[#0063BE] "><span className='text-center'>Email</span></th>
                    
                    
                </tr>
                </thead>
                <tbody>
                
                    <tr
                    className=""
                    
                    >
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Company_Name}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{TIN}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{PFD}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Delivery_Address}, {City}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Postal_Code}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Contact_Number}</span></td>
                    <td className="border border-[#0063BE] p-3 text-center"><span >{Email}</span></td>
                    
                    
                    </tr>
                
                </tbody>
            </table>
                
                </div>
            </div>    
            </div>
        </div>
        
        <div className="bg-gray-50 px-4 py-3 sm:px-6 ">
        <div className='sm:flex sm:flex-row-reverse justify-center'>
            <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={HandleConfirm}
            >
            Επιβεβαίωση
            </button>
            <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
            >
            Επιστροφή
            </button>
            
        </div>
        <p className=' text-sm text-green-800 font-semibold text-center'>
        
        </p>
        </div>
        </>
        
        </div>
    </div>
    </div>
    );
  } else if (status === 'success') {
    // Display a success message and a return button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <div className='flex flex-col justify-center items-center p-5'>
        <p className=' p-5 text-green-800 font-semibold text-lg'>Η εγγραφή έγινε με επιτυχία!</p>
        <ReturnButton />
        </div>
        
        </div>
    </div>
    </div>
    );
  } else if (status === 'error') {
    // Display an error message and a return button
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackdropClick}>
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
    <div className={`relative flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${isOpen ? 'animate-slideInDown' : 'animate-slideOutDown'}`}>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} style={modalStyle} className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle ${isOpen ? 'translate-y-0 ease-out transition-medium' : 'translate-y-full ease-in transition-medium'}`}>
        
        <>
        <p>Κάτι που λάθος! Παρακαλώ ελέξτε τα δεδομένα ξανά!</p>
        <ReturnButton />
        </>
        
        </div>
    </div>
    </div>
    );
  }



};

export default InsertIndividualModal;
