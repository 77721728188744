import { useLocation } from 'react-router-dom';
import HubName from '../../components/HubNameComponent';
import {  useQuery } from '@tanstack/react-query';
import SpinnerComponent from '../../components/spinnerComponent';
import axios from 'axios';
import { useState, useEffect } from 'react';
import ConnectionMap from '../../components/graphPageComponents/ConnectionMap';

function GraphConnections() {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entryParam = pathParts[pathParts.length - 2];
    const hub_id = JSON.parse(decodeURIComponent(entryParam));
    const [selectedConnection, setSelectedConnection] = useState({});

    const { data: ConnectionData, isLoadingConnection, errorConnection } = useQuery(['ConnectionData'], () =>
    axios.get(`http://195.251.111.90/api/is_connected_to/hub/${hub_id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        }
    }).then(res => res.data)
);

const { data: hubData, isLoading, error } = useQuery(['hubData'], () =>
    axios.get(`http://195.251.111.90/api/hub/${hub_id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        }
    }).then(res => res.data)
);

useEffect(() => {
    // This will set the first connection as selected when the data loads
    if (ConnectionData) {
      setSelectedConnection(ConnectionData[0]);
    }
  }, [ConnectionData]);

    if (isLoading) return <div className=' min-h-screen flex items-center justify-center' ><SpinnerComponent/> </div>;

    // You can also check if there was an error
    if (error) return 'An error has occurred: ' + error.message;

    if (isLoadingConnection) return 

    // You can also check if there was an error
    if (errorConnection) return 'An error has occurred: ' + error.message;

    

    return (
      <div className="min-h-screen flex items-center justify-center mt-[7%]">
        
  
        <div className=" w-full  bg-white rounded-lg shadow-lg p-8 border-2 border-green-800  text-center">
        <div className=' justify-center items-center flex'>
        <div className='border-2 border-[#0063BE] rounded-md w-3/6 m-1 '>
                <h1 className="text-2xl font-normal text-gray-800 p-2">Συνδέσεις με τον κέντρο διανομών <span className='font-semibold'> <HubName id={hub_id}/></span></h1>
            </div>
            </div>
            <div className=' justify-between items-center flex-row flex m-5'>
            
            
            
            {isLoadingConnection ? <div className=' min-h-screen flex items-center justify-center' ><SpinnerComponent/> </div> :
            <div className='overflow-y-auto h-[75vh] w-[29%]  scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600'>
            {ConnectionData?.map((connection, index) => (
                <div 
                    key={index} 
                    className={`flex flex-col  border-2 border-[#0063BE] items-center justify-center mb-3 mx-2 rounded-lg 
                    ${connection === selectedConnection ? 'bg-[#0063BE] text-white' : 'hover:bg-[#0063BE] hover:text-white transition-colors duration-200'} 
                    cursor-pointer`}
                    onClick={() => setSelectedConnection(connection)}
                    >                    
                    <div className='flex-col flex '>
                        <div className='flex m-1 items-center border-b-2 border-[#0063BE]'> 
                            <div className="flex items-center">
                                <h1 className=' font-medium'> <HubName id={connection.Hub_ID}/></h1>
                                <div className="mx-10 flex items-center ">
                                    
                                    <svg className="w-20 h-20 self-center -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FC4C02">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.4} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <h1 className=' font-medium'><HubName id= {connection.Connecting_Hub_ID}/></h1>
                            </div>
                        </div>
                        <div className='flex  justify-around'>
                            <h1 className=' w-54 ' title='Μέγιστη ημερήσια χωρητικότητα'>
                                Μέγιστη ημερήσια χωρητικότητα <p>{connection.Max_capacity_rate}</p>
                            </h1>
                            <h1>Κόστος: <br/> 300</h1>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            
        }
        

        <div>
            <ConnectionMap start_id={selectedConnection.Hub_ID} end_id={selectedConnection.Connecting_Hub_ID}/>
        </div>
        </div>
        </div>
      </div>
    );
  }
  
  export default GraphConnections;
  