import { useAuth } from '../utils/AuthContext';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import HubName from '../components/HubNameComponent';
import {BsFillInfoCircleFill} from "react-icons/bs"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

async function fetchShipments() {
    const res = await axios.get("http://195.251.111.90/api/shipment",{
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return res.data;
}

function groupByStartLocation(shipments) {
    return shipments.reduce((groups, shipment) => {
        const key = shipment.Location_Start_ID;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(shipment);
        return groups;
    }, {});
}

function groupByProperty(shipments, prop) {
    return shipments.reduce((groups, shipment) => {
        const key = shipment[prop];
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(shipment);
        return groups;
    }, {});
}

// The updated function to group by the end location and then the start location
function groupByEndAndStartLocation(shipments) {
    const groupedByEndLocation = groupByProperty(shipments, 'Location_End_ID');

    for (let endLocation in groupedByEndLocation) {
        groupedByEndLocation[endLocation] = groupByProperty(groupedByEndLocation[endLocation], 'Location_Start_ID');
    }

    return groupedByEndLocation;
}

function AlgorithmExecuteDetails() {
    const navigate = useNavigate();
    const { userInfo, nodes: CompanyNodes } = useAuth();
    const { data: ShipmentData, isLoading, error } = useQuery(['fetchShipments'], fetchShipments);
    const [hoveredRow, setHoveredRow] = useState(null);
    

    if (isLoading) return 'Loading...';
    if (error) return 'An error has occurred: ' + error.message;

    // Filter the data
    // const filteredData = ShipmentData.filter(item => item.Location_Start_ID === 4 ||item.Location_Start_ID === 1);

    // Find the maximum date
    const maxDate = ShipmentData.reduce((max, entry) => {
        const entryDate = new Date(entry.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
        return entryDate > max ? entryDate : max;
    }, new Date(0));

    // Filter the data to keep only the items with the maximum date
    const latestShipments = ShipmentData.filter(item => new Date(item.Date_Made.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")).getTime() === maxDate.getTime());
    
    const companyNodeIds = CompanyNodes.map(node => node.Hub_ID);

    const departuresFromCompanyNodes = latestShipments.filter(shipment => 
        companyNodeIds.includes(shipment.Location_Start_ID));
    
        //console.log(CompanyNodes)

    const arrivalsToCompanyNodes = latestShipments.filter(shipment => 
        companyNodeIds.includes(shipment.Location_End_ID));

        //console.log(arrivalsToCompanyNodes)

        const groupedDepartures = groupByStartLocation(departuresFromCompanyNodes);
        const groupedArrivals = groupByEndAndStartLocation(arrivalsToCompanyNodes);    
        // console.log(arrivalsToCompanyNodes)
        //console.log(companyNodeIds)
    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className='flex-col mt-[8%] mb-[4%] items-center justify-center' >
            <div className='items-center justify-center flex'>
            <h1 className="text-xl text-center bg-white font-bold text-gray-800 border-2 rounded-lg border-[#0063BE] my-5 p-6 w-2/4">Τελευταία εκτέλεση του αλγορίθμου για: {userInfo.Company_Assigned}</h1>
            </div>
            <div className="w-full bg-white rounded-lg shadow-lg p-3 border-2 border-green-800 text-center flex-col flex justify-center  items-center">
                <h2 className=' font-bold p-2 text-lg' >Αναχωρήσεις</h2>
                {Object.entries(groupedDepartures).map(([startLocation, shipments]) => (
                    <div key={startLocation} className='border-2 rounded-lg border-[#0063BE] w-[100%] m-[3%] p-3' >
                        <h2 className='p-2 m-2' >Μεταφορές που <span className='font-medium' >αναχωρούν</span> από τον κόμβο: <span className=' font-semibold' ><HubName id={startLocation}/> </span></h2>
                        {shipments.map((shipment, index) => (

                                <div 
                                key={index} 
                                className={`border-2 rounded-xl flex w-full my-3 cursor-pointer items-center 
                                ${shipment.Shipment_Status === 'Confirmed' ? 'bg-green-500 border-[#0063BE]' : 
                                shipment.Shipment_Status === 'Rejected' ? 'bg-red-500 border-[#0063BE]' : 
                                'border-[#0063BE]'} 
                                ${hoveredRow === shipment.Shipment_ID ? 'hover:bg-[#0063BE] hover:text-white hover:border-green-800' : ''}`}
                                onMouseEnter={() => setHoveredRow(shipment.Shipment_ID)}
                                onMouseLeave={() => setHoveredRow(null)}
                                onClick={() => navigate(`/algorithm_execution/details/shipment/${encodeURIComponent(JSON.stringify(shipment.Shipment_ID))}`)}
                                >
                                
                                <div className='     border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' > 
                                ID Μεταφοράς 
                                <p className='  break-words' title={` ${shipment.Shipment_ID}`}>{shipment.Shipment_ID}</p>
                                </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    ID Οδηγού 
                                    <p className='  break-words' title={` ${shipment.User_ID}`}>{shipment.User_ID}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Ποσότητα σε τεμάχια:
                                    <p className='  break-words' title={` ${shipment.Quantity}`}>{shipment.Quantity}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Συνολικό βάρος φορτίου:
                                    <p className='  break-words' title={` ${shipment.Total_weight}`}>{shipment.Total_weight}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Κόστος μεταφοράς:
                                    <p className='  break-words' title={` ${shipment.Cost}`}>{shipment.Cost}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Ημερομηνία έκδοσης:
                                    <p className='  break-words' title={` ${shipment.Date_Made}`}>{shipment.Date_Made}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-wrap break-word w-44' >
                                    Από:
                                    <p className='  break-words' title={`Κόμβος: ${shipment.Location_Start_ID}`}><HubName id={shipment.Location_Start_ID}/></p>
                                </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-wrap break-word w-44 ' >
                                    Προς: 
                                    <p className='' title={`Κόμβος: ${shipment.Location_End_ID}`}><HubName id={shipment.Location_End_ID}/>
                                    </p>
                                    </div>
                                    
                                
                                </div>
                        ))}
                    </div>
                ))}
            </div>
            
            <div className="w-full bg-white rounded-lg shadow-lg p-3 border-2 border-green-800 text-center flex-col flex justify-center my-[4%] items-center">
            <h2 className=' font-bold p-2 text-lg' >Αφίξεις</h2>
    {Object.entries(groupedArrivals).map(([endLocation, shipmentsGroupedByStartLocation]) => (
        <div key={endLocation} className='border-2 rounded-lg border-[#0063BE] w-[100%] m-[3%] p-3'>
            <h2 className='p-2 m-2'>Μεταφορές που <span className=' font-medium' >καταφθάνουν</span> στον κόμβο: <span className='font-semibold'><HubName id={endLocation}/></span></h2>
            
            {Object.entries(shipmentsGroupedByStartLocation).map(([startLocation, shipments]) => (
                <div key={startLocation}>
                    <h3>Από τον κόμβο: <HubName id={startLocation}/></h3>

                    {shipments.map((shipment, index) => (
                        <div 
                            key={index} 
                            className={`border-2 rounded-xl flex w-full my-3 cursor-pointer items-center 
                            ${shipment.Shipment_Status === 'Confirmed' ? 'bg-green-500 border-[#0063BE]' : 
                            shipment.Shipment_Status === 'Rejected' ? 'bg-red-500 border-[#0063BE]' : 
                            'border-[#0063BE]'} 
                            ${hoveredRow === shipment.Shipment_ID ? 'hover:bg-[#0063BE] hover:text-white hover:border-green-800' : ''}`}
                            onMouseEnter={() => setHoveredRow(shipment.Shipment_ID)}
                            onMouseLeave={() => setHoveredRow(null)}
                            onClick={() => navigate(`/algorithm_execution/details/shipment/${encodeURIComponent(JSON.stringify(shipment.Shipment_ID))}`)}
                        >
                                
                                <div className='     border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' > 
                                ID Μεταφοράς 
                                <p className='  break-words' title={` ${shipment.Shipment_ID}`}>{shipment.Shipment_ID}</p>
                                </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    ID Οδηγού 
                                    <p className='  break-words' title={` ${shipment.User_ID}`}>{shipment.User_ID}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Ποσότητα σε τεμάχια:
                                    <p className='  break-words' title={` ${shipment.Quantity}`}>{shipment.Quantity}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Συνολικό βάρος φορτίου:
                                    <p className='  break-words' title={` ${shipment.Total_weight}`}>{shipment.Total_weight}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Κόστος μεταφοράς:
                                    <p className='  break-words' title={` ${shipment.Cost}`}>{shipment.Cost}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-hidden' >
                                    Ημερομηνία έκδοσης:
                                    <p className='  break-words' title={` ${shipment.Date_Made}`}>{shipment.Date_Made}</p>
                                    </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-wrap break-word w-44' >
                                    Από:
                                    <p className='  break-words' title={`Κόμβος: ${shipment.Location_Start_ID}`}><HubName id={shipment.Location_Start_ID}/></p>
                                </div>
                                <div className=' border-r-2 p-2 font-semibold mx-1 border-[#0063BE] overflow-wrap break-word w-44 ' >
                                    Προς: 
                                    <p className='' title={`Κόμβος: ${shipment.Location_End_ID}`}><HubName id={shipment.Location_End_ID}/>
                                    </p>
                                    </div>
                                    <div className='w-10' >
                                    {hoveredRow === shipment.Shipment_ID && (
                                        <BsFillInfoCircleFill
                                        className=" cursor-pointer "
                                        size={25}
                                        color="#FC4C02"
                                        
                                        />
                                    )}
                                    </div> 

                                
                                </div>
                    ))}
                </div>
            ))}
        </div>
    ))}
</div>
</div>
    </div>
    );
}
export default AlgorithmExecuteDetails;
