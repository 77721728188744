import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';
import { useEffect, useState } from 'react';
import HubName from './../HubNameComponent';
import SpinnerComponent from '../spinnerComponent';
import {BsFillInfoCircleFill} from "react-icons/bs"
import  Timeline  from './Timeline';
import { Dangerous, Dimentions, Features, Fragile, NoItems, Request, Vulnerable } from '../photoComponents/isNotCompanyIcons';


function IsNotCompanyNode({hub_id}) {
    const { nodes, userInfo } = useAuth()
    let hubIds = nodes.map(node => node.Hub_ID);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [details, setDetails] = useState(null)
    const [item, setItem] = useState({})
    const [mutationLoading, setMutationLoading] = useState(false);

    const mutation = useMutation((data) => axios.post('http://195.251.111.90/api/item/fetch_current_hub_items', JSON.stringify(data), {
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        }
    }), {
        onSuccess: (data) => {
            setMutationLoading(false);
            setDetails(data.data[0].Item_ID)
            setItem(data.data[0])
        },
        onError: (error) => {
            setMutationLoading(false);
        }
    });

    const { data: hubData, isLoading, error } = useQuery(['hubData'], () =>
        axios.get(`http://195.251.111.90/api/hub/${hub_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            }
        }).then(res => res.data)
    );

    useEffect(() => {
        setMutationLoading(true);
        mutation.mutate({
            "Order_Hub_ID": hubIds,
            "Current_Hub_ID": hub_id
        });
    }, []); 

    // You can check if the query is still loading
    if (isLoading) return <div className=' w-[70vh] m-auto' ><SpinnerComponent/> </div>;

    // You can also check if there was an error
    if (error) return 'An error has occurred: ' + error.message;

    // Then you can use your data in your component
    //console.log(mutation.data); // data from the hub
//console.log(item)
    return (
<div className="flex flex-col items-center  w-full">
    <div className='border-2 border-[#0063BE] rounded-md w-3/6 m-1'>
        <h1 className="text-2xl font-normal text-gray-800 p-2">
            Λεπτομέρειες για το κέντρο διανομών <span className='font-semibold'>{hubData[0].Hub_Name}</span>
        </h1>
    </div>
    <div className='flex-row flex w-full justify-between' >
    <div className='w-[40%] flex flex-col items-start border-2 border-[#0063BE] m-3 overflow-y-auto  overflow-x-hidden h-[100vh] rounded 
    scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600'>
        <div className=' '>
            <h1 className="text-xl font-normal text-gray-800 p-2 m-1">Πακέτα της <span className='font-semibold'> {userInfo.Company_Assigned} </span> στο κέντρο διανομών <span className='font-semibold'>{hubData[0].Hub_Name}</span></h1>
        </div>
        
        {mutationLoading ? (
            <div className='m-auto' ><SpinnerComponent/></div>
        ) : (
            mutation.data && Array.isArray(mutation.data.data) ? (
                mutation.data.data.map(item => (
                    <div className='items-center justify-center flex-row flex cursor-pointer '
                        onMouseEnter={() => setHoveredRow(item.Item_ID)}
                        onMouseLeave={() => setHoveredRow(null)}
                        onClick={() => {setDetails(item.Item_ID) ; setItem(item) }}
                    >
                        <div key={item.Item_ID} className={`flex-row flex items-end justify-center rounded-lg m-2 border-2 ${details === item.Item_ID ? 'bg-[#0063BE] text-white border-orange-600' : ''} border-[#0063BE] hover:bg-[#0063BE]  hover:text-white`}>
                            <div className='p-1 border-r-2 border-[#0063BE]'>Tεμάχιο <p className=' font-semibold'>#{item.Item_ID}</p></div>
                            <div className='p-1 border-r-2 border-[#0063BE]'>Παραγγελία <p className=' font-semibold'>#{item.Request_ID}</p></div>
                            <div className='p-1 border-r-2 border-[#0063BE] w-44'>Αρχικός προορισμός <p className=' font-semibold'><HubName id={item.Order_hub_ID}/></p></div>
                            <div className='p-1 border-[#0063BE] w-44'>Τελικός προορισμός <p className=' font-semibold'><HubName id={item.Delivery_hub_ID}/></p></div>
                        </div>
                        <div className='w-44'>
                        {hoveredRow === item.Item_ID  && details !== item.Item_ID && (
                            <BsFillInfoCircleFill
                                className=" cursor-pointer"
                                size={25}
                                color="#FC4C02"
                            />
                        )}
                        </div>
                    </div>
                ))
            ) : (
                <div className=' m-auto'>
                    <div className='w-3/4 m-auto' >Δεν υπάρχουν διαθέσιμα δέματα της <span className='font-semibold'> {userInfo.Company_Assigned} </span> στο κέντρο διανομών <span className='font-semibold'>{hubData[0].Hub_Name}</span></div>
                    <NoItems />
                </div>
            )
        )}
    </div>
    <div className='m-3 w-[80vh] border-2 border-[#0063BE] bg-white rounded-lg shadow-lg   text-center '>

        

        {details && 
        <div>

            <div>
                
                <h1 className=' p-3 text-xl text-gray-800 font-normal border-b-2 border-[#0063BE] mb-4'>Λεπτομέρεις για {item.Item_Type ==="Pallet" ? <>το πακέτο</>: <>την παλέτα </>} <span  className=' font-semibold'> #{item.Item_ID}</span></h1>
            </div>

        <div className='flex-row flex justify-between border-b-2 border-[#0063BE] pb-4'>
            <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
                <Dimentions/>
                <div >
                <h1 className='pb-1 font-medium'>Διαστάσεις {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>
                </div>
                <h2 className='pb-1'>Μήκος: <span className=' font-semibold'> {item.Item_Width} cm </span></h2>
                <h2 className='pb-1'>Πλάτος: <span className=' font-semibold'>{item.Item_Length} cm </span></h2>
                <h2 className='pb-1'>Ύψος: <span className=' font-semibold'>{item.Item_Height} cm </span></h2>
                <h2 className='pb-1'>Βάρος: <span className=' font-semibold'>{item.Item_Weight} kg </span></h2>
                {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
            </div>

            <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto   w-[30%] min-w-[230px] min-h-[230px]'> 
                <Features/>
                <h1 className='pb-1 font-medium' >Χαρακτηριστικά  {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>

                <div className='flex-row flex justify-center items-center my-1'>
                <Dangerous/><h2 className='ml-2'  > Επικίνδυνο: {item.Dangerous === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
                </div>

                <div className='flex-row flex justify-center items-center my-1'>
                <Fragile/> <h2 className='ml-2'  > Εύθραυστο: {item.Fragile === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
                </div>
                <div className='flex-row flex justify-center items-center my-1 '>
                <Vulnerable/> <h2 className='mx-[5px] w-[50%]'  > Ευάλωτο:    <span>{item.Vulnerable === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white '>   Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>   Όχι</span>}</span>  </h2>
                </div>
                
            </div>
        
            <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
                <Request/>
                <div >
                <h1 className='pb-1 font-medium'> Μέρος της Παραγγελίας <p>#{item.Request_ID} </p></h1>
                </div>
                <h2 className='pb-1'>Αρχικός κόμβος:  <p className='font-medium'><HubName id={item.Order_hub_ID}/> </p></h2>
                <h2 className='pb-1'>Τελικός κόμβος:  <p className='font-medium'><HubName id={item.Delivery_hub_ID}/> </p></h2>
                
                {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
            </div>

        </div>



            <div>
                <h1 className=' text-xl text-gray-800 font-normal my-3   pb-2' >Χρονολόγιο {item.Item_type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} <span  className=' font-semibold'> #{item.Item_ID}</span></h1>
            </div>
        <Timeline itemId={details} />
        </div>
        }
        
        </div>
    </div>
</div>
    );
}

export default IsNotCompanyNode;
