import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ItemRoute from '../components/ItemRouteComponent';
import { InTransit, LastMile, ShippingStart } from '../components/photoComponents/TimelIneIcons';
import HubName from '../components/HubNameComponent';
import SpinnerComponent from './../components/spinnerComponent';

async function fetchItemDetails(itemId) {
    const response = await axios.get("http://195.251.111.90/api/tracking/item/"+itemId, {  
        headers: {
            "Content-Type": "application/json",
            Authorization: "velos123",
        },
    });
    return response.data;
}



function ItemDetails() {
    const location = useLocation();
    const entryParam = location.pathname.split('/').pop();
    const itemId = JSON.parse(decodeURIComponent(entryParam));

    const { data: rawData, isLoading } = useQuery(['itemDetails', itemId], () => fetchItemDetails(itemId));


    const sortedData = rawData?.sort((a, b) => {
        const aDateParts = a.Date_Made.split(' ');
        const aDate = new Date(aDateParts[0].split('/').reverse().join('-') + 'T' + aDateParts[1]);
        const bDateParts = b.Date_Made.split(' ');
        const bDate = new Date(bDateParts[0].split('/').reverse().join('-') + 'T' + bDateParts[1]);
        return aDate - bDate;
    });

    const routeSet = new Set(sortedData?.map(step => step.Hub_ID));
    let route = Array.from(routeSet);

    // Check if sortedData is not empty or undefined
    if(sortedData && sortedData.length > 0) {
      // Add Order_Hub_ID of the first item to the start of the route array
      route.unshift(sortedData[0].Order_Hub_ID);}


      async function fetchDetails(Hub_ID) {
        const response = await axios.get("http://195.251.111.90/api/hub/"+Hub_ID, {  
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        return <p>{response?.data.Hub_Name}</p>
      }

    console.log(sortedData)

    return (
      <div className="min-h-screen flex items-center justify-center pt-[8%]">
        
        <div className="w-full bg-white rounded-lg shadow-lg p-5 border-2 border-green-800 text-center">
        {isLoading ? <SpinnerComponent/>:
            <div className='flex justify-center items-center h-full'>
                <div className='border-2 border-[#0063BE] rounded-xl w-2/3'>
                    <h1 className="text-3xl font-bold text-gray-800 p-1">Αριθμός Δέματος #{sortedData[0].Item_ID}</h1>
                    <div className="flex justify-center items-center pb-2">
                        <div className="w-1/4 text-center">
                            <div className="rounded-full h-6 w-6 bg-blue-500 text-center flex items-center justify-center mx-auto">
                                <span className="text-white text-sm">{sortedData[0].Order_Hub_ID}</span>
                            </div>
                            <p className="text-gray-800 text-lg mt-2">Κόμβος αποστολής</p>
                        </div>
                        <svg className="w-8 h-8 mx-2 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                        <div className="w-1/4 text-center">
                            <div className="rounded-full h-6 w-6 bg-blue-500 text-center flex items-center justify-center mx-auto">
                                <span className="text-white text-sm">{sortedData[0].Delivery_Hub_ID}</span>
                            </div>
                            <p className="text-gray-800 text-lg mt-2">Κόμβος παραλαβής </p>
                        </div>
                    </div>
                </div>
            </div>
        }
    
      {sortedData && (
        <div className="flex">
        <div className="w-1/2  my-6 p-4 h-full">
          <div className="relative flex flex-col my-10 ">
            <div className="absolute h-full border-l-2 border-[#FC4C02] left-1/2 transform -translate-x-1/2 mt-3 " />
  
            {/* Add this section for the start circle */}
            <div className="flex items-center relative justify-between ">
              <div className="h-16 w-16 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 flex items-center justify-center">
                <div className="w-14 h-14 flex items-center justify-center rounded-full border-[#FC4C02] bg-[#FC4C02] border-2 mb-6">
                  {/* Assuming you have a component or icon for Start */}
                  <ShippingStart/>
                </div>
              </div>
              <div className="w-1/2 rounded-lg shadow-lg p-2 border-2 border-[#0063BE] mr-9 mb-6">
                <h3 className="text-black font-semibold text-center">Έναρξη παραγγελίας</h3>
                <p className="text-center">Κόμβος έναρξης: <HubName id={sortedData[0].Order_Hub_ID}/></p>
              </div>

              <div className="w-1/2 flex items-center ">
                          <p className={`text-center mx-3 text-white font-medium bg-[#0063BE] rounded-xl mr-[60%] mb-6 `}>
                              {sortedData[0].Date_Made}
                            
                          </p>
                        </div>
            </div>
              {sortedData.map((entry, index) => {
                // split date and time
                
                    return (
                      <div className={`flex items-center relative justify-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`} key={index}>
                        <div className="h-16 w-16 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 flex items-center justify-center ">
                          <div className={`w-14 h-14 flex items-center justify-center rounded-full mb-6 ${entry.Item_Status === "In_transit" ? 'border-red-800 bg-red-800' : 'border-green-500 bg-green-500'} border-2`}>
                            {entry.Item_Status === "In_transit" ? <InTransit /> : entry.Item_Status === "Last_mile" ? <LastMile /> : null}
                          </div>
                        </div>
                        <div className={`w-1/2 rounded-lg shadow-lg p-4 border-2 mb-6 border-[#0063BE] ${index % 2 === 0 ? 'ml-8' : 'mr-8'}`}>
                          <h3 className="text-black font-semibold text-center">Μετακίνηση:  #{index+1}</h3>
                          <p className="text-center" >Κόμβος έναρξης: <HubName id= {entry.Current_Hub_ID} />  </p>
                          <p className="text-center" >Κόμβος άφιξης: <HubName id={entry.Next_hub_id}/></p>
                          <p className="text-center" >ID Μετακίνησης: {entry.Shipment_ID}</p>
                          <p className="text-center" >Κατάσταση: {entry.Item_Status}</p>
                        </div>
                        <div className="w-1/2 flex items-center ">
                          <p className={`text-center mx-3 text-white font-medium bg-[#0063BE] rounded-xl mb-6 ${index % 2 === 0 ? 'ml-[60%] ' : 'mr-[60%] '}`}>
                              {entry.Date_Made}
                            
                          </p>
                        </div>
                      </div>
                  )})}
                </div>
              </div>
              <div className="w-1/2">
                {route &&  <ItemRoute route={route} />}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  
}

export default ItemDetails;