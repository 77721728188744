// src/components/ProtectedRoute.js
import React from 'react';
import Navbar from './navBar';

const ProtectedRoute = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default ProtectedRoute;
