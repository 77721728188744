import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { AlertComponent } from '../components/alertComponent';
import EventCalendar from '../components/calendarComponents/EventCalendar';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('showWelcomeAlert') === 'true') {
      setShowSuccessAlert(true);
      notifySuccess();
      sessionStorage.removeItem('showWelcomeAlert');
    }
  }, []);

  

  const notifySuccess = () => toast.success('Καλως ηρθατε!', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });  
  return (
    <div className="min-h-screen flex items-center justify-between  " >
      {showSuccessAlert &&(<> <AlertComponent/></>) }

      <div className="w-[60%] h-max-[10%] bg-white rounded-xl shadow-lg p-4 py-10  border-2 border-green-800 text-center mx-[3%]">
        <h1 className="text-xl font-semibold text-gray-800 border rounded-3xl border-[#FC4C02] mx-[8%] py-[4%] shadow-lg">Εισαγωγή νέων δεδομένων</h1>

        <div className='flex items-center justify-around flex-row bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
          hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
          onClick={() => navigate('/hub_insertion')}>
          <h1 className='text-white  p-2 w-[70%] ml-4 '>
            Εισαγωγή νέου κέντρου διανομής ή αποθήκης
          </h1>
          <img
            src={require('../assets/add-node.png')}
            alt="Belos Logo"
            className="w-14 h-14 m-2" // Adjust these values to resize the image
          />
        </div>

        <div className='flex items-center justify-around flex-row bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
      hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
      onClick={() => navigate('/request_insertion')}>
          <h1 className='text-white flex-shrink p-2 ml-5'>
            Εισαγωγή νέας παραγγελίας 
          </h1>
          <img
            src={require('../assets/add-req-cust.png')}
            alt="Belos Logo"
            className="w-14 h-14 m-2" // Adjust these values to resize the image
          />
        </div>
        <div className='flex items-center justify-around flex-row bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
      hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
      onClick={() => navigate('/customer_insertion')}>
          <h1 className='text-white flex-shrink p-2 ml-10'>
            Εισαγωγή νέου πελάτη
          </h1>
          <img
            src={require('../assets/add-customer.png')}
            alt="Belos Logo"
            className="w-14 h-14 m-2" // Adjust these values to resize the image
          />
        </div>
      </div>





      <div className="w-[60%] h-[50vh] bg-white   rounded-xl shadow-lg p-4 py-10  border-2 border-green-800 text-center mx-[3%]">
        
        <h1 className="text-xl font-semibold text-gray-800 border rounded-3xl border-[#FC4C02] mx-[8%] py-[4%] shadow-lg">Λειτουργία Αλγορίθμου</h1>
        <div className='flex flex-col justify-center h-[85%]'>
        <div className='flex items-center flex-row bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
          hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
          onClick={() => navigate('/algorithm_execution')}>
          <h1 className='text-white flex-shrink p-2 '>
            Εκκίνηση νέας επανάληψης του αλγορίθμου
          </h1>
          <img
            src={require('../assets/algorithm-exe.png')}
            alt="Belos Logo"
            className="w-14 h-14 m-2" // Adjust these values to resize the image
          />
        </div>

        <div className='flex items-center flex-row bg-[#4F70FF] py-[1%] mx-[8%] my-[5%] shadow-lg rounded-2xl relative
      hover:bg-[#3054f7] cursor-pointer hover:ring-1 ring-green-300 hover:scale-110 transition-all duration-700'
        onClick={() => navigate('/algorithm_execution/details')}>
          <h1 className='text-white flex-shrink px-3'>
            Τελευταία επανάληψη του αλγορίθμου
          </h1>
          <img
            src={require('../assets/latest-iter.png')}
            alt="Belos Logo"
            className="w-14 h-14 m-2" // Adjust these values to resize the image
          />
        </div>
        </div>
      </div>

      <div className="w-[60%] h-[50vh] bg-white rounded-lg shadow-lg p-4 border-2 border-green-800 text-center  flex flex-col justify-center items-center mx-[3%]">
  <h1 className="text-xl font-semibold text-gray-800 border rounded-3xl border-[#FC4C02] mb-[16%]  p-[4%] shadow-lg">Ιστορικό επαναλήψεων αλγορίθμου</h1>
  <div className=' mb-3'>
  <EventCalendar  events={events} setEvents={setEvents} />
  </div>
</div>

    </div>
  );
}

export default Home;
