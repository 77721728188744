import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScriptNext, Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SpinnerComponent from "../spinnerComponent";
import MapComponentSideMenu from "./MapComponentSideMenu";
import { useAuth } from "../../utils/AuthContext";


const containerStyle = {
width: "100%",
height: "70vh",
borderRadius:'15px',
position:'relative'
};

const fetchNodes = async () => {
    const response = await axios.get("http://195.251.111.90/api/hub", {
    headers: {
        "Content-Type": "application/json",
        Authorization: "velos123",
    },
    });
    return response.data;
};

const fetchConnectedHubs = async (hubId) => {
    const response = await axios.get(`http://195.251.111.90/api/is_connected_to/hub/${hubId}`, {
    headers: {
        "Content-Type": "application/json",
        Authorization: "velos123",
    },
    });
    return response.data;
};

const defaultCenter = {
    lat: 38.6389,
    lng: 22.4181,
    };


const MapComponent = () => {
    const { userInfo } = useAuth();
    const [map, setMap] = useState(null);
    const [connectionsReady, setConnectionsReady] = useState(false);
    const [companyNodesWithConnectedHubs, setCompanyNodesWithConnectedHubs] = useState([]);
    const [uniqueConnectedNodes, setUniqueConnectedNodes] = useState([]);
    const [connections, setConnections] = useState([]);
    const [center, setCenter] = useState(defaultCenter);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const { isLoading, data: nodesData, error } = useQuery(["nodes"], fetchNodes);

    const handleDrag = () => {
        if (map) {
        setCenter({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
        });
        }
    };

    const currentCompanyNodeList = nodesData
    ? nodesData.filter((node) => node.Company_Name === userInfo.Company_Assigned)
    : [];

    useEffect(() => {
        if (currentCompanyNodeList?.length > 0) {
            const fetchAllConnectedHubs = async () => {
                const connectedHubsPromises = currentCompanyNodeList.map(node =>
                fetchConnectedHubs(node.Hub_ID)
                );
                const connectedHubsResults = await Promise.all(connectedHubsPromises);

                const updatedNodes = currentCompanyNodeList.map((node, index) => ({
                    ...node,
                    connectedHubs: connectedHubsResults[index],
                }));

                setCompanyNodesWithConnectedHubs(updatedNodes);
            };

            fetchAllConnectedHubs();
        }
    }, [currentCompanyNodeList]);


    useEffect(() => {
        if (map && companyNodesWithConnectedHubs.length > 0 && !connectionsReady) {
        const connectedNodeIds = new Set();
        const generatedConnections = [];
    
        companyNodesWithConnectedHubs.forEach((node) => {
            connectedNodeIds.add(node.Hub_ID); // add node.Hub_ID to connectedNodeIds
            node.connectedHubs.forEach((connectedHub) => {
            connectedNodeIds.add(connectedHub.Connecting_Hub_ID);
    
            const startNode = nodesData.find((n) => n.Hub_ID === connectedHub.Hub_ID);
            const endNode = nodesData.find((n) => n.Hub_ID === connectedHub.Connecting_Hub_ID);
    
            generatedConnections.push({
                start: { lat: startNode?.Latitude, lng: startNode?.Longitude },
                end: { lat: endNode?.Latitude, lng: endNode?.Longitude },
            });
            });
        });
    
        const generatedUniqueConnectedNodes = nodesData.filter((node) =>
            connectedNodeIds.has(node.Hub_ID)
        );
        console.log(companyNodesWithConnectedHubs);
        setUniqueConnectedNodes(generatedUniqueConnectedNodes);
        setConnections(generatedConnections);
        setConnectionsReady(true);
        }
    }, [map, connectionsReady, companyNodesWithConnectedHubs, nodesData]);




    if (isLoading) return  <div className=" h-[70vh] flex items-center justify-center  "><SpinnerComponent/> </div>;
    if (error) return <div className=" h-[70vh] flex items-center justify-center">An error occurred while fetching nodes.</div>;

    return (
        
                    <div className="flex justify-between p-3">
                        

            <div className="w-1/4 h-auto border-2 rounded-lg border-green-800">
                <h1 className="font-bold  font-sans text-2xl p-[5%] border-b-2 border-green-800 "   >Κομβοι του Δικτύου</h1>
                {connectionsReady && <MapComponentSideMenu  nodes ={uniqueConnectedNodes} />}
            </div>
            <LoadScriptNext
                googleMapsApiKey="AIzaSyCuUvJVCyGmUQXLGrksPRqdRVVF8-aQW7E"
            >
                <div className="w-4/6">
                    
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={defaultCenter}
                    zoom={7}
                    onLoad={(map) => setMap(map)}
                    onUnmount={() => setMap(null)}
                    onDragEnd={handleDrag}
                    onClick={() => setSelectedMarker(null)}
                >
                    {connectionsReady &&
                        uniqueConnectedNodes.map((node) => (
                            <Marker
                            key={node.Hub_ID}
                            position={{ lat: node?.Latitude, lng: node?.Longitude }}
                            onClick={() => setSelectedMarker(node)}
                            icon={{
                                url:
                                userInfo.Company_Assigned === node.Company_Name
                                    ? require('../../assets/location-green.png')
                                    : require('../../assets/location-red.png'),
                                scaledSize: new window.google.maps.Size(32, 32),
                            }}
                            />
                        ))}
                    {selectedMarker && (
                        <InfoWindow
                            position={{ lat: selectedMarker?.Latitude, lng: selectedMarker?.Longitude }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                                <div className="flex"  ><h1 className=" font-semibold pr-1"  >Ονομασία κέντρου:</h1>{selectedMarker.Hub_Name}</div>
                                <div className="flex"  ><h1 className=" font-semibold pr-1" >Ανήκει στην εταιρεία:</h1> {selectedMarker.Company_Name}</div>
                                <div className="flex"  ><h1 className=" font-semibold pr-1" >Διεύθυνση:</h1> {selectedMarker.Address} </div>
                                <div className="flex"  ><h1 className=" font-semibold pr-1" >Ταχυδρομικός κώδικας:</h1> {selectedMarker.Postal_Code } </div>
                            </div>
                        </InfoWindow>
                    )}
                    {connections.map((connection, index) => (
                        <Polyline
                        key={index}
                        path={[
                            { lat: connection.start.lat, lng: connection.start.lng },
                            { lat: connection.end.lat, lng: connection.end.lng },
                        ]}
                        options={{ strokeColor: "#FC4C02", strokeWeight: 1, strokeOpacity: 0.8 }}
                        />
                        
                    ))}
                </GoogleMap>
                </div>
            </LoadScriptNext>
            {!connectionsReady && <SpinnerComponent/> }
            </div>

    );
};

export default MapComponent;
