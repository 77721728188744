import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useEffect } from 'react';
import HubName from '../../components/HubNameComponent';
import SpinnerComponent from './../../components/spinnerComponent';
import { Pallete, Parcel, RequestReady } from '../../components/photoComponents/IsCompanyNodes';
import { BsFillArrowDownCircleFill} from "react-icons/bs";
import { Transition } from "react-transition-group";
import RequestItemDetails from '../../components/graphPageComponents/RequestItemDetails';


function RequestLastMile() {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entryParam = pathParts[pathParts.length - 2];
    const hub_id = JSON.parse(decodeURIComponent(entryParam));
    const [expandedNode, setExpandedNode] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const toggleRequest = (requestId) => {
        if (expandedNode === requestId) {
          setExpandedNode(null);
          setSelectedRequest(null);
        } else {
          setExpandedNode(requestId);
          setSelectedRequest(RequestData[requestId]);
        }
    };
    
      const duration = 500;
      const defaultStyle = {
        transition: `max-height ${duration}ms ease-in-out`,
        maxHeight: 0,
        overflow: "hidden",
      };
    
      const transitionStyles = {
        entering: { maxHeight: "1000px" },
        entered: { maxHeight: "1000px" },
        exiting: { maxHeight: 0 },
      };

      var groupItemsByRequest = function(items) {
        var groupedItems = {};
    
        items.forEach(item => {
            var requestId = item.Request_ID;
    
            if (!groupedItems[requestId]) {
                groupedItems[requestId] = {
                    requestId: requestId,
                    priority: item.Priority,
                    deliveryStartDate: item.Delivery_Start_Date,
                    totalVolumetricWeight: item.Total_volumetric_weight,  // add this line
                    receiverID: item.Receiver_ID,  // add this line
                    items: []
                };
            }
    
            groupedItems[requestId].items.push(item);
        });
    
        return groupedItems;
    }

    async function fetchRequests() {
        const res = await axios.get("http://195.251.111.90/api//item/get_requests_by_hub/" +hub_id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
    
        const groupedRequests = groupItemsByRequest(res.data);
        return groupedRequests;
    }

    const { data: RequestData, isLoading, error } = useQuery(['fetchRequests'], fetchRequests);

    //console.log(RequestData)
    useEffect(() => {
        // Set the first request to be expanded by default
        if (RequestData) {
          const firstRequestId = Object.keys(RequestData)[0];
          if (firstRequestId) {
            setExpandedNode(firstRequestId);
            setSelectedRequest(RequestData[firstRequestId]);
          }
        }
      }, [RequestData]);



    return (
      <div className="w-[82vw]  flex items-center justify-center">
        
        {isLoading ? <SpinnerComponent/> :
    <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center flex-col justify-center items-center my-[9%]">
        <div className='flex justify-center items-center'>
        <div className='border-2 border-[#0063BE] rounded-md w-2/4 m-1 p-2 flex items-center justify-center'>
            <h1 className="text-xl font-normal text-gray-800 p-2">
                Παραγγελίες προς παράδοση για το κέντρο διανομών  <span className='font-semibold'> <HubName id={hub_id}/> </span>
                
                    
                
            </h1>
        </div>
        </div>
        <div className=' flex-row flex justify-between items-center '>
        <div className='flex-col w-[43%] border-2 border-[#0063BE] mt-3 rounded-lg overflow-y-auto h-[90vh]  scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600'> 
        {RequestData ?  
        (<>
        {Object.entries(RequestData).map(([requestId, request]) => {
            //console.log(request);  // Add this line
            return (
                <div key={requestId} >
                    <div className=' flex  items-center justify-center border-2 border-[#0063BE] mx-3 mt-3 mb-1 rounded-lg cursor-pointer hover:text-white hover:bg-[#0063BE]'
                    onClick={() => toggleRequest(requestId)}>
                        <div className='flex justify-start'>
                        <RequestReady />
                        </div>
                        <div className='flex-row flex ml-8'>
                        <div  className='mr-2 p-2 border-r-2 border-l-2 border-[#0063BE] items-center flex flex-col justify-center '>
                            <h2 className=' font-semibold'>ID παραγγελίας: </h2>
                                <p>
                                    {requestId}
                                </p>
                            
                        </div>
                        <div className='mr-2 p-1 border-r-2  border-[#0063BE] items-center flex flex-col justify-center'>
                            <h2 className=' font-semibold'>Προτεραιότητα: </h2>
                                <p>
                                    {request.priority === "High" ? <p className=' text-orange-700 font-medium' >Yψηλή</p> :<p className=' text-green-700 font-medium' >Κανονική </p> }
                                </p>
                            
                        </div>
                        <div className='mr-2 p-1 border-r-2  border-[#0063BE] items-center flex flex-col justify-center'>
                            <h2 className=' font-semibold'>Ημ/νια Έναρξης: </h2>
                            
                                <p>
                                    {request.deliveryStartDate}
                                </p>
                            
                        </div>
                        <div className=' flex items-center justify-center '>
                        <BsFillArrowDownCircleFill
                      className={`transition duration-500 ease-in-out ${
                        expandedNode === requestId ? "transform rotate-180" : ""
                      } text-[#FC4C02] text-2xl`}
                    />
                        
                        </div>
                        </div>
                    </div>

                    <Transition
                        in={expandedNode === requestId}
                        timeout={duration}
                    >
                        {(state) => (
                        <div
                            style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                            }}
                        >
                            <div
                                
                            >
                            {Array.isArray(request.items) &&
                            request.items.map((item, index) => (
                                <div key={index} className={`border-2 border-[#0063BE] rounded-lg mx-[20%] m-2  flex flex-row justify-around items-center`}>
                                    <div>
                                        {item.Item_Type === "Pallet" ? <Pallete/> :<Parcel/>}
                                    </div>
                                    <div className='flex flex-row justify-between items-center '>
                                    <div className='mr-3 p-2 border-r-2 border-l-2 border-[#0063BE]'>
                                        <h1 className=' font-semibold'>ID Τεμαχίου</h1>
                                        <h1>{item.Item_ID}</h1>
                                    </div>
                                    <div className='mr-3 p-2 border-[#0063BE]'>
                                        <h1 className=' font-semibold'>Είδος τεμαχίου</h1>
                                        <h1>{item.Item_Type === "Pallet" ? <div>Παλέτα</div> :<div>Πακέτο</div>}</h1>
                                    </div>
                                    
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        )}
                    </Transition>
                </div>
            );
        })}
        </>)
        :<SpinnerComponent/>}
        
        </div>
        <RequestItemDetails request={selectedRequest}/>
        </div>



    </div>
}
      </div>
    );
  }
  
  export default RequestLastMile;
  