import { Dangerous, Dimentions, Features, Fragile, NoItems, Request, Vulnerable } from '../photoComponents/isNotCompanyIcons';
import HubName from './../HubNameComponent';



function RequestItemDetails({request}) {


    console.log(request)
    return (
      <div className="flex-col w-[55%] border-2 border-[#0063BE] mt-3 rounded-lg overflow-y-auto h-[90vh] scrollbar-thin scrollbar-track-rounded-md  scrollbar-thumb-rounded-md scrollbar-thumb-blue-400  scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600">
          <h1 className="text-xl  text-gray-800 ">Λεπτομέρειες για την παραγγελία <span className=' font-semibold'> #{request?.requestId}</span></h1>

          {request ? (
            <div>
            <div>
            
            <div className=' flex-row flex justify-center items-center p-1'>
            <h2 title='Συνολικό ογκομετρικο βάρος' className='pr-1 font-semibold' >Συνολικό ογκο/κο βάρος: </h2>
            <span> {request.totalVolumetricWeight}</span>
            </div>
            <div className=' flex-row flex justify-center items-center p-1'>
            <h2 className='pr-1 font-semibold'> Αποδέκτης παραγγελίας </h2>
            <span>{request.receiverID}</span>
            </div>
            </div>
            <h2 className="text-xl font-semibold  text-gray-800 p-3 mb-2 border-t-2 border-[#0063BE]">Αναλυτικά τα περιεχόμενα της παραγγελίας</h2>
            {request.items?.map((item, index) => (

                <div key={index} className='   pb-4'>
                    <h1 className='p-2'>Λεπτομέρειες για το τεμαχίου  <span className='font-semibold  text-gray-800 '> #{item.Item_ID}</span></h1>

                    <div className='flex-row flex justify-between  pb-4'> 
                <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
                    <Dimentions/>
                    <div >
                    <h1 className='pb-1 font-medium'>Διαστάσεις {item.Item_Type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>
                    </div>
                    <h2 className='pb-1'>Μήκος: <span className=' font-semibold'> {item.Item_Width} cm </span></h2>
                    <h2 className='pb-1'>Πλάτος: <span className=' font-semibold'>{item.Item_Length} cm </span></h2>
                    <h2 className='pb-1'>Ύψος: <span className=' font-semibold'>{item.Item_Height} cm </span></h2>
                    <h2 className='pb-1'>Βάρος: <span className=' font-semibold'>{item.Item_Weight} kg </span></h2>
                    {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
                </div>
    
                <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto   w-[30%] min-w-[230px] min-h-[230px]'> 
                    <Features/>
                    <h1 className='pb-1 font-medium' >Χαρακτηριστικά  {item.Item_Type ==="Parcel" ? <>πακέτου</>: <> παλέτας </>} </h1>
    
                    <div className='flex-row flex justify-center items-center my-1'>
                    <Dangerous/><h2 className='ml-2'  > Επικίνδυνο: {item.Dangerous === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
                    </div>
    
                    <div className='flex-row flex justify-center items-center my-1'>
                    <Fragile/> <h2 className='ml-2'  > Εύθραυστο: {item.Fragile === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white'>Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>Όχι</span>}  </h2>
                    </div>
                    <div className='flex-row flex justify-center items-center my-1 '>
                    <Vulnerable/> <h2 className='mx-[5px] w-[50%]'  > Ευάλωτο:    <span>{item.Vulnerable === 1 ? <span className=' rounded-2xl p-1 bg-green-700 text-white '>   Ναι</span> : <span className=' p-1 rounded-2xl bg-red-700 text-white '>   Όχι</span>}</span>  </h2>
                    </div>
                    
                </div>
            
                <div className='border-2 border-[#FC4C02] flex-col rounded-xl m-auto  w-[30%] min-w-[230px] min-h-[230px]'> 
                    <Request/>
                    <div >
                    <h1 className='pb-1 font-medium'> Μέρος της Παραγγελίας <p>#{item.Request_ID} </p></h1>
                    </div>
                    <h2 className='pb-1'>Αρχικός κόμβος:  <p className='font-medium'><HubName id={item.Order_hub_ID}/> </p></h2>
                    <h2 className='pb-1'>Τελικός κόμβος:  <p className='font-medium'><HubName id={item.Delivery_hub_ID}/> </p></h2>
                    
                    {/* <h2 className=''> <span className=''> Ογκομετρικό βάρος: {item.Volumetric_Weight} </span> </h2> */}
                </div>
            </div>
            </div>
              ))}
            </div>
          ) : (
            <p>Επιλέξτε παραγγελία</p>
          )}
      </div>
    );
}
  
export default RequestItemDetails;
