export  function NoItems(){
    return <img
    src={require('../../assets/not-items-warehouse.png')}
    alt="No Items Warehouse"
    className=" m-auto " // Adjust these values to resize the image
  />
}

export  function Dimentions(){
  return <img
  src={require('../../assets/item-dimentions.png')}
  alt="item-dimentions"
  className=" m-auto p-1 h-12 w-12 " // Adjust these values to resize the image
/>
}

export  function Features(){
  return <img
  src={require('../../assets/features.png')}
  alt="item-dimentions"
  className=" m-auto p-1 h-12 w-12 " // Adjust these values to resize the image
/>
}

export  function Dangerous(){
  return <img
  src={require('../../assets/dangerous.png')}
  alt="item-dimentions"
  className="  h-8 w-8  " // Adjust these values to resize the image
/>
}

export  function Fragile(){
  return <img
  src={require('../../assets/fragile.png')}
  alt="fragile"
  className=" h-8 w-8  " // Adjust these values to resize the image
/>
}

export  function Vulnerable(){
  return <img
  src={require('../../assets/vulnerable.png')}
  alt="fragile"
  className=" h-8 w-8  " // Adjust these values to resize the image
/>
}

export  function Request(){
  return <img
  src={require('../../assets/request.png')}
  alt="fragile"
  className=" m-auto p-1 h-12 w-12  " // Adjust these values to resize the image
/>
}