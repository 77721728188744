import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ItemDetailsShipment from './ShipmentContentDetails';

const ShipmentContents = ({ id, Quantity, Total_Weight }) => {
    const fetchShipmentContents = async () => {
        const response = await axios.get(`http://195.251.111.90/api/tracking/shipment/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });
        
        return response.data;
    };

    const { data: ShipmentContents, isLoading } = useQuery(['ShipmentContents', id, Quantity, Total_Weight], fetchShipmentContents);

    if (isLoading) {
        return <p>Loading...</p>;
    }
console.log(ShipmentContents)
    return(
        <div>
            {ShipmentContents.map((item, index) => (
                <div key={index}>
                    <ItemDetailsShipment  Item_ID={item.Item_ID} Hub_ID={item.Hub_ID} Shipment_ID={item.Shipment_ID} Quantity ={Quantity} Total_Weight ={Total_Weight} status={item.Item_Inclusion_Status} />
                </div>
            ))}
        </div>
    )  
};

export default ShipmentContents;
