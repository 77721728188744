import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Select from 'react-select';
import SpinnerComponent from '../../components/spinnerComponent';
import InsertConnectionModal from '../../components/InsertComponents.js/InsertConnectionModal';

function InsertConnection() {
  const [selectedOption1, setSelectedOption1] = React.useState(null);
  const [selectedOption2, setSelectedOption2] = React.useState(null);
  const [maxCapacityRate, setMaxCapacityRate] = React.useState(null);
  const [maxRate, setMaxRate] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const fetchHubs = async () => {
    const { data } = await axios.get('http://195.251.111.90/api/hub', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'velos123',
      },
    });
    return data;
  };

  const { data, isLoading, isError } = useQuery(['hubs'], fetchHubs);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Open the modal
    setIsModalOpen(true);
  };
  


  const resetForm = () => {
    setSelectedOption1(null);
    setSelectedOption2(null);
    setMaxCapacityRate(null);
    setMaxRate(null);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };
  

  if (isError) {
    return <span>Error: Something went wrong...</span>;
  }

  const options = data?.map(hub => ({ value: hub.Hub_ID, label: hub.Hub_Name })); 

  const options1 = selectedOption2 ? options.filter(option => option.value !== selectedOption2.value) : options;
  const options2 = selectedOption1 ? options.filter(option => option.value !== selectedOption1.value) : options;

  const handleSelect1 = selectedOption => {
    setSelectedOption1(selectedOption);
  };

  const handleSelect2 = selectedOption => {
    setSelectedOption2(selectedOption);
  };


  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center">
        {isLoading ? <SpinnerComponent/>: <>
        <InsertConnectionModal  isOpen={isModalOpen} selectedOption1={selectedOption1} 
        selectedOption2={selectedOption2} maxCapacityRate={maxCapacityRate} maxRate={maxRate} resetForm={resetForm}  onClose={closeModal}/>


        <h1 className="text-3xl font-bold text-gray-800">Εισαγωγή σύνδεσης</h1>
        <div className='flex flex-row justify-around items-center'>
          <div className="mt-6 w-1/5">
            <label htmlFor="hub1" className="block text-sm font-medium text-gray-700">1ο Κέντρο διανομών</label>
            <Select
              id="hub1"
              value={selectedOption1}
              onChange={handleSelect1}
              options={options1}
              className="mt-1"
              isSearchable
            />
          </div>
          <svg className="w-20 h-20 self-center -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FC4C02">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.4} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
          <div className="mt-6 w-1/5 align-middle">
            <label htmlFor="hub2" className="block text-sm font-medium text-gray-700">2ο Κέντρο διανομών</label>
            <Select
              id="hub2"
              value={selectedOption2}
              onChange={handleSelect2}
              options={options2}
              className="mt-1"
              isSearchable
            />
          </div>
        </div>
        
        <div className='flex flex-row justify-between mt-10'>
            <div className="mt-6 relative w-[25%] ml-[7%]">
              <label htmlFor='maxCapacityRate' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                                  maxCapacityRate > 0 ? ' -top-5 left-3' : 'text-base top-3 left-4'
                              } ${maxCapacityRate > 0 && 'text-blue-500'}`}>Μέγιστη χωρητικότητα φορτηγού </label>
              <input 
                id="maxCapacityRate" 
                value={maxCapacityRate}
                type="number"
                required
                onChange={(e) => setMaxCapacityRate(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" 
              />
            </div>
            <div className="mt-6 relative w-[25%]  mr-[7%]">
              <label htmlFor='maxRate' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                                  maxRate > 0 ? ' -top-5 left-3' : 'text-base top-3 left-4'
                              } ${maxRate > 0 && 'text-blue-500'}`}>Μέγιστος αριθμός δρολογίων ανά μέρα</label>
              <input 
                id="maxRate" 
                type="number" 
                required
                value={maxRate}
                onChange={(e) => setMaxRate(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" 
              />
            </div>
        </div>
        {selectedOption1 && selectedOption2 && selectedOption1.value === selectedOption2.value && 
          <div>Δεν επιτρέπεται να βάλετε το ίδιο κέντρο δύο φορές </div>
        }
        <button  className="bg-[#0063BE] rounded-2xl text-white mt-16 p-5 font-semibold" onClick={handleSubmit}>Υποβολή νέου κόμβου!</button>
        </>}
      </div>
      
    </div>
  );
  }

export default InsertConnection;
