import { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('userId'));
  const [userId, setUserId] = useState(Cookies.get('userId'));
  const [intervalTimeout, setIntervalTimeout] = useState(false);
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = Cookies.get('userInfo');
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });
  const [nodes, setNodes] = useState(() => {
    const storedNodes = sessionStorage.getItem('companyNodes');
    return storedNodes ? JSON.parse(storedNodes) : [];
  });

  const handleLogout = (timeout = false) => {
    setIsLoggedIn(false);
    setUserId(null);
    setUserInfo(null);
    setNodes([]);
    Cookies.remove('userId');
    Cookies.remove('userInfo');
    sessionStorage.removeItem('companyNodes');
    setIntervalTimeout(timeout);
  };

  const fetchCompanyNodes = async (companyName) => {
    try {
      const response = await axios.get(
        'http://195.251.111.90/api/hub',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'velos123',
          }
        }
      );
  
      const companyNodes = response.data.filter(node => node.Company_Name === companyName);
  
      setNodes(companyNodes);
  
      // Store the nodes in sessionStorage
      sessionStorage.setItem('companyNodes', JSON.stringify(companyNodes));
    } catch (error) {
      console.log(error);
    }
  };

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    userId,
    setUserId,
    handleLogout,
    intervalTimeout,
    userInfo,
    setUserInfo,
    nodes,
    fetchCompanyNodes,
  };
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
