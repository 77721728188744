import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const RequestDate = ({ Item_ID }) => {
    const fetchRequestDate = async () => {
        const response = await axios.post(`http://195.251.111.90/api/item/fetch_request_by_item_id`, { "Item_ID": Item_ID }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "velos123",
            },
        });

        return response.data;
    };

    const { data: RequestData, isLoading, error } = useQuery(['RequestDate', Item_ID], fetchRequestDate);

    
    if (error) {
        console.log(error)
    }
//console.log(RequestData)
    return (
    <>
    {isLoading ?<span className='p-2' >Loading...</span> : <span>{RequestData[0]?.Delivery_Start_Date}</span>}
    
    </>)
};

export default RequestDate;
