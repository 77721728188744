import { useParams } from 'react-router-dom';
import HubName from '../../components/HubNameComponent';
import { useState } from 'react';
import Select from 'react-select';
import { Checkbox } from 'antd';
import  '../../index.css'
import InsertItemModal from '../../components/InsertComponents.js/InsertItemModal';

function InsertItem() {
  const { insertId, startLocation, endLocation } = useParams();
  const [itemtype, setItemType]= useState(null)
  const [height, setHeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [weight, setWeight] = useState('');
  const [isVulnerable, setIsVulnerable] = useState(false);
  const [isDangerous, setIsDangerous] = useState(false);
  const [isFragile, setIsFragile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let volumetric_weight

  const ItemTypeOptions = [
    { value: 'Parcel', label: 'Φάκελος/Πακέτο' },
    { value: 'Pallet', label: 'Παλέτα' },
  ];

  const handleHeightChange = (e) => {
    if (e.target.value.length <= 4) {
      setHeight(parseInt(e.target.value));
    }
  };
  

  const handleLengthChange = (e) => {
    if (e.target.value.length <= 4) {
      setLength(parseInt(e.target.value));
    }
  };


  const handleWidthChange = (e) => {
    if (e.target.value.length <= 4) {
      setWidth(parseInt(e.target.value));
    }
};


  const handleWeightChange = (e) => {
    if (e.target.value.length <= 8) {
      setWeight(parseFloat(e.target.value));
    }
};

if(height && width && length){
  volumetric_weight = Math.round((height * length * width / 500) * 100) / 100;
}


  const handleSubmit = ( event) => {
    event.preventDefault();
   
    setIsModalOpen(true)
    
  };

  const resetForm = () => {
        setItemType('')
        setHeight('') 
        setLength('')
        setWidth('')
        setWeight('')
        setIsVulnerable(false)
        setIsDangerous(false)
        setIsFragile(false)
        volumetric_weight = 0;
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };



  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow-lg p-8 border-2 border-green-800 text-center my-[9%]">
      <InsertItemModal  
        isOpen={isModalOpen} 
        Request_ID={insertId} 
        Item_Type={itemtype} 
        Height={height} 
        Length={length}
        Width={width}
        Weight={weight}
        isVulnerable = {isVulnerable}
        isDangerous={isDangerous}
        isFragile={isFragile}
        Order_Hub_ID={startLocation}
        Delivery_Hub_ID={endLocation}
        Volumetric_Weight={volumetric_weight}
        resetForm={resetForm}  
        onClose={closeModal}/>

        <div className='flex  justify-center items-center'>
        <div className="rounded-2xl bg-[#4F70FF] w-[45%] text-white p-5">
        <h1 className="text-2xl font-bold ">Εισαγωγή πακέτων για την παραγγελία #{insertId}</h1>
          </div>
          </div>

        <div className='flex m-10 items-center justify-center'> 
        <div className=' flex-col w-[15%]'>
          <h1 className=' font-semibold text-gray-700 text-lg m-3'>Κέντρο Παράδοσης</h1>
            <div className=' border-2 border-[#4F70FF] p-5 rounded-3xl'>
              
              <h1 className=' font-medium'> <HubName id={startLocation}/></h1>
              </div>
              </div>
              <div className="mx-10 flex items-center ">
                  
                  <svg className="w-20 h-20 self-center -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FC4C02">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.4} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
              </div>
            <div className=' flex-col w-[15%]'>
            <h1 className=' font-semibold text-gray-700 text-lg m-3'>Κέντρο Παραλαβής</h1>
              <div className=' border-2 border-[#4F70FF] p-5 rounded-3xl '>
                <h1 className=' font-medium'><HubName id= {endLocation}/></h1>
              </div>
            </div>
      </div>

      <div>
        <div className=' flex flex-row justify-start items-center'>
      <div >
        <h1 className='font-bold text-gray-700 text-2xl m-3'>Νέο τεμαχίου</h1>
      </div>
      <div className=' mr-14'>
              <img
              src={require('../../assets/add-cust.png')}
              alt="not sure"
              className="w-16 h-16 m-5" // Adjust these values to resize the image
              />
          </div>
      </div>

        
      </div>

      <div>
        <div className=' flex flex-row justify-start items-center my-4'>
        <div className=' mr-4'>
              <img
              src={require('../../assets/new-request.png')}
              alt="not sure"
              className="w-16 h-16 m-5" // Adjust these values to resize the image
              />
          </div>
      <div >
        <h1 className='font-bold text-gray-700 text-xl '>Λεπτομέρειες τεμαχίου</h1>
      </div>
      
      </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className=' flex flex-row justify-start items-center '>
          <div >
        <h1 className='font-semibold text-gray-700 text-lg '>Διαστάσεις και τύπος τεμαχίου </h1>
          </div>
        </div>
      

      

        <div className='flex flex-row justify-around items-center mt-10 mb-5'>


        <div className='w-[10%]' >
          <div  className='relative '>
          <label htmlFor='Height' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
              height > 0 ? ' -top-6 left-0.5 text-gray-700' : 'text-base top-3.5 left-4'
          }`}>Ύψος</label>
          <input
              type='number'
              name="Height"
              value={height}
              onChange={(e) => handleHeightChange(e)}
              className={`mt-1 block rounded-md shadow-sm px-3.5 py-2 text-center focus:ring-2 focus:border-blue-500 w-full   ${
                  height > 0
                  ? 'border-2 border-green-500 bg-green-100 bg-opacity-80 ring-2 focus:ring-green-500 focus:ring-opacity-50 '
                  : 'border-2 border-[#0063BE] bg-white '
              }`}
              required
          />
          <div className='flex justify-center items-center m-1'>
          <p className=' text-sm text-gray-500 font-medium'>Σε εκατοστά (cm)</p>
          </div>
          </div>
      </div>

        <div className='w-[10%] '>
          <div className='relative '>
            <label htmlFor='Height' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                  length > 0 ? ' -top-6 left-0.5 text-gray-700' : 'text-base top-3.5 left-4'
              }`}>Μήκος</label>
              <input
                name="Length"
                type='number'
                value={length}
                onChange={(e) => handleLengthChange(e)}
                className={`mt-1 block rounded-md shadow-sm px-3.5 py-2 text-center focus:ring-2 focus:border-blue-500 w-full  ${
                  length > 0
                  ? 'border-2 border-green-500 bg-green-100 bg-opacity-80 ring-2 focus:ring-green-500 focus:ring-opacity-50 '
                  : 'border-2 border-[#0063BE] bg-white '
              }`}
              required
              />
              <div className='flex justify-center items-center m-1'>
                <p className=' text-sm text-gray-500 font-medium'>Σε εκατοστά (cm)</p>
              </div>
          </div>
        </div>

          <div className='w-[10%] '>
          <div className='relative '>
            <label htmlFor='width' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                width > 0 ? ' -top-6 left-0.5 text-gray-700' : 'text-base top-3.5 left-4'
            }`}>Πλάτος</label>
            <input
              name="width"
              type='number'
              value={width}
              onChange={(e) => handleWidthChange(e)}
              className={`mt-1 block rounded-md shadow-sm px-3.5 py-2 text-center focus:ring-2 focus:border-blue-500 w-full  ${
                width > 0
                ? 'border-2 border-green-500 bg-green-100 bg-opacity-80 ring-2 focus:ring-green-500 focus:ring-opacity-50 '
                : 'border-2 border-[#0063BE] bg-white '
            }`}
            required
            />
            <div className='flex justify-center items-center m-1'>
                <p className=' text-sm text-gray-500 font-medium'>Σε εκατοστά (cm)</p>
              </div>
          </div>
          </div>

          <div className='w-[10%] '>
          <div className='relative '>
          <label htmlFor='weight' className={`text-gray-600 font-bold absolute pointer-events-none transition-all duration-200 ${
                weight > 0 ? ' -top-6 left-0.5 text-gray-700' : 'text-base top-3.5 left-4'
            }`}>Βάρος</label>
            <input

              name="weight"
              type='number'
              value={weight}
              onChange={(e) => handleWeightChange(e)}
              className={`mt-1 block rounded-md shadow-sm px-3.5 py-2 text-center focus:ring-2 focus:border-blue-500 w-full  ${
                weight > 0
                ? 'border-2 border-green-500 bg-green-100 bg-opacity-80 ring-2 focus:ring-green-500 focus:ring-opacity-50 '
                : 'border-2 border-[#0063BE] bg-white '
            }`}
            required
            />
            <div className='flex justify-center items-center m-1'>
                <p className=' text-sm text-gray-500 font-medium'>Σε κιλά (kg)</p>
              </div>
          </div>
          </div>

      </div>

      <div className=' flex justify-center '>
              <div className=' flex flex-col  w-[20%]'>
          <h1 className=' text-xl font-semibold text-gray-700 p-3'>Τύπος τεμαχίου</h1>
          <Select
          value={itemtype}
          required
          options={ItemTypeOptions}
          onChange={setItemType}
          styles={{
            control: (base, state) => ({
              ...base,
              borderColor: state.hasValue || state.isFocused ? '#34D399' : '#E5E7EB',
              boxShadow: state.hasValue || state.isFocused ? '0 0 0 3px rgba(50, 211, 150, 0.45)' : '0 1px 2px rgba(0, 0, 0, 0.1)',
              backgroundColor: state.hasValue || state.isFocused ? '#F3FAF9' : '#ffffff',
            }),
          }}
        />
        </div>
        </div>

        <div className=' flex flex-row justify-start items-center my-5 '>
          <div >
        <h1 className='font-semibold text-gray-700 text-lg '>Ιδιότητες πακέτου </h1>
          </div>
        </div>

       

        <div className=' flex flex-row justify-evenly items-center'>


        <div  className={`flex-col flex justify-center items-center p-4 rounded-xl border-2 ease-in-out duration-500 w-[15%] ${
        isVulnerable ? 'border-green-500  hover:ring-2 hover:ring-green-500' : 'border-[#0063BE] hover:ring-2 hover:ring-[#0063BE]'
      }`}
      >
          <div className=' flex-row flex justify-center items-center'>
          <div>
          <img
            src={require('../../assets/vulnerable.png')}
            alt="fragile"
            className=" h-12 w-12  " // Adjust these values to resize the image
          />
          </div>
          <div className='mr-5 '>
          <h1 className=' text-xl text-gray-600 font-semibold'>Ευάλωτο</h1>
          </div>
          </div>
        <Checkbox
        className="big-checkbox"
        checked={isVulnerable}
        onChange={(e) => setIsVulnerable(e.target.checked)}
      />
       </div>
      



       <div  className={`flex-col flex justify-center items-center p-4 rounded-xl border-2 ease-in-out duration-500 w-[15%] ${
        isDangerous ? 'border-green-500  hover:ring-2 hover:ring-green-500' : 'border-[#0063BE] hover:ring-2 hover:ring-[#0063BE]'
      }`}
      >
          <div className=' flex-row flex justify-center items-center mr-1'>
          <div>
          <img
            src={require('../../assets/dangerous.png')}
            alt="fragile"
            className=" h-12 w-12  " // Adjust these values to resize the image
          />
          </div>
          <div className='mr-5 '>
          <h1 className=' text-xl text-gray-600 font-semibold ml-2'>Επικίνδυνο</h1>
          </div>
          </div>
      <Checkbox
        className="big-checkbox"
        checked={isDangerous}
        onChange={(e) => setIsDangerous(e.target.checked)}
      />
      </div>


      <div  className={`flex-col flex justify-center items-center p-4 rounded-xl border-2 ease-in-out duration-500 w-[15%] ${
        isFragile ? 'border-green-500  hover:ring-2 hover:ring-green-500' : 'border-[#0063BE] hover:ring-2 hover:ring-[#0063BE]'
      }`}
      >
          <div className=' flex-row flex justify-center items-center mr-1'>
          <div>
          <img
            src={require('../../assets/fragile.png')}
            alt="fragile"
            className=" h-12 w-12  " // Adjust these values to resize the image
          />
          </div>
          <div className='mr-5 '>
          <h1 className=' text-xl text-gray-600 font-semibold ml-2'>Εύθραυστο</h1>
          </div>
          </div>
      <Checkbox
        className="big-checkbox"
        checked={isFragile}
        onChange={(e) => setIsFragile(e.target.checked)}
        
      />
      </div>
    </div>

    <button type='submit' className="bg-[#0063BE] rounded-2xl text-white p-5 m-10 font-semibold">Υποβολή παραγγελίας και εισαγωγή περιεχομένων! </button>
    </form>
      </div>
    </div>
  );
}
  
export default InsertItem;
