
//import { useAuth } from './../utils/AuthContext';
import React, { useState } from 'react';
import {GoSettings} from "react-icons/go"
import { BsSearch } from 'react-icons/bs';
import LatestItems from '../components/RequestPageLatestComponent';

function Request_List() {
  const [searchTerm, setSearchTerm] = useState('');
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Here you could do something with the search term, like sending it to an API
    console.log('Search term submitted:', searchTerm);
  };

    return (
      <div className=" flex items-center justify-center flex-col ">
        
  
        <div className=" mt-[9%] w-full  bg-white rounded-lg shadow-lg p-[2%] border-2 border-green-800  text-center">

        <form onSubmit={handleSearchSubmit} className="w-full max-w-sm mx-auto">

          
      <div className="flex items-center  border-2 rounded-3xl border-[#05683A] ">

      <BsSearch className=" hover:animate-pulse cursor-pointer   p-2 " size={50} color='#FC4C02' type='submit'/>
        <input
          className="appearance-none bg-transparent border-b border-green-200 h-auto w-4/5  text-gray-700  p-4 leading-tight focus:outline-none"
          type="text"
          placeholder="Αναζήτηση..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* <button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="submit">
          Αναζήτηση
        </button> */}
        <GoSettings className=" cursor-pointer   p-2 " size={50} color='#4f4f4f'/>
      </div>
    </form>
          
          
    <LatestItems />

    </div>

      
      </div>
    );
  }
  
  export default Request_List;
  