import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

function ItemStatistics({ hub_id }) {
const { data, isLoading, isError } = useQuery(
    ["itemStatistics", hub_id],
    async () => {
        const response = await axios.get(
            `http://195.251.111.90/api/item/item_statistics_started_from_hub/${hub_id}`,
            {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'velos123', 
            },
            }
        );
        
    return response.data;
    }
);

if (isLoading) {
    return <div>Loading...</div>;
}

if (isError) {
    return <div>Error loading data</div>;
}

const itemsStarted = data[0]?.Items_Started_From_Hub || 0;

return (
    <div className=" flex items-center flex-row w-[100%]">

            <div className=" flex items-center mr-[8%] ">
        <img
        src={require('../../assets/package-start.png')}
        alt="fragile"
        className=" m-2 " // Adjust these values to resize the image
        />
            </div>

<div className="flex w-full h-full justify-start items-center">
    <div className=" flex ">
    <h1>Αριθμός πακέτων που <b>ξεκίνησαν</b> από τον κόμβο: <span>{itemsStarted}</span></h1>
    </div>
    </div>
    </div>
);
}

export default ItemStatistics;
