import { useNavigate } from 'react-router-dom';

function AlgorithmExecute() {
    const navigate = useNavigate();

  

    return (
      <div className="min-h-screen flex items-center justify-center">
        
  
        <div className=" w-full  bg-white rounded-lg shadow-lg p-8 border-2 border-green-800  text-center flex-col flex justify-center  items-center">
          <h1 className="text-3xl font-bold text-gray-800 p-10">AlgorithmExecute Page</h1>
          
            <div className="p-5 m-5 text-white font-semibold rounded-2xl w-60 h-full bg-[#4F70FF] hover:opacity-50 cursor-pointer " 
            onClick={() => navigate('/algorithm_execution/details')}
            >
                Εκτέλεση Αλγορίθμου
            </div>
        </div>
      </div>
    );
  }
  
  export default AlgorithmExecute;
  